'use client'

import clsx from 'clsx'
import { useCookies } from 'next-client-cookies'
import { FC, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { tokenKey } from '../../../lib/constants'
import { useBookingsQuery } from '../../../lib/graphql/generated/hooks'
import { Layout } from '../../../lib/graphql/generated/types'
import { useTranslation } from '../../../lib/hooks'
import { selectOnlineEvents, selectOperations } from '../../../lib/selectors'
import { EventQueryEvent } from '../../../lib/types'
import { DateFormat, FormattedDate, Icon, Text } from '../../base'
import { Masthead } from '../../common'
import EventActions from '../eventActions'
import styles from './eventMasthead.module.css'
import OnlineEventOverlay from './onlineEventOverlay'

interface Props {
    event: EventQueryEvent
    onEventActionsInViewChange?: (inView: boolean) => void
    openBookings: () => void
}

const EventMasthead: FC<Props> = props => {
    const { t, locale } = useTranslation()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)

    const [bookingsQuery] = useBookingsQuery({
        variables: {
            token,
            locale,
            eventKeyOrId: props.event.key,
        },
    })

    const event = bookingsQuery.data?.viewer?.event
    const bookings = event?.viewerParticipant?.bookings ?? []

    const waitinglistBookable = useMemo(() => {
        const operations = selectOperations(event)

        return operations.some(
            operation => !!operation.viewerBookable?.waitinglist
        )
    }, [event])

    const onlineEvents = useMemo(() => selectOnlineEvents(event), [event])

    const hasInternalOnlineEvent = useMemo(
        () => onlineEvents.some(onlineEvent => !onlineEvent.external),
        [onlineEvents]
    )

    // TODO: Extract to EventMastheadContent component?
    const content = useMemo(() => {
        if (!event) {
            return <Skeleton borderRadius={5} className={styles.skeleton} />
        } else if (bookings.length) {
            return (
                <EventActions
                    className={styles.actions}
                    event={event}
                    loading={bookingsQuery.fetching || bookingsQuery.stale}
                    moreOptionsClassName={styles.moreOptions}
                    onInViewChange={props.onEventActionsInViewChange}
                    openBookings={props.openBookings}
                    viewerAuthenticationMethod={
                        bookingsQuery.data?.viewer?.authenticationMethod
                    }
                />
            )
        } else if (!event.viewerRegistration?.running) {
            return (
                <Text className={styles.information} element="div">
                    <Icon className={styles.icon} name="info" size={2} />{' '}
                    {t('event:notRunning')}
                </Text>
            )
        } else if (
            !event.capacity?.bookable &&
            (!event.capacity?.waitinglist || !waitinglistBookable)
        ) {
            return (
                <Text className={styles.information} element="div">
                    <Icon className={styles.icon} name="info" size={2} />{' '}
                    {t('event:soldOut')}
                </Text>
            )
        }

        return (
            <>
                {props.event.deadline && (
                    <Text
                        align="center"
                        className={styles.information}
                        element="div"
                    >
                        <Icon className={styles.icon} name="info" size={2} />
                        {t('event:deadline')}
                        <FormattedDate
                            format={DateFormat.Medium}
                            startDate={props.event.deadline}
                            timezone={props.event.timezone.name}
                            withTime
                        />
                    </Text>
                )}
                <EventActions
                    className={styles.actions}
                    event={event}
                    loading={bookingsQuery.fetching || bookingsQuery.stale}
                    moreOptionsClassName={styles.moreOptions}
                    onInViewChange={props.onEventActionsInViewChange}
                    openBookings={props.openBookings}
                    viewerAuthenticationMethod={
                        bookingsQuery.data?.viewer?.authenticationMethod
                    }
                />
            </>
        )
    }, [
        bookings.length,
        bookingsQuery.data?.viewer?.authenticationMethod,
        bookingsQuery.fetching,
        bookingsQuery.stale,
        event,
        props.event.deadline,
        props.event.timezone.name,
        props.onEventActionsInViewChange,
        props.openBookings,
        t,
        waitinglistBookable,
    ])

    const image =
        props.event.layout === Layout.Wide
            ? props.event.wideImage?.thumbnail?.resource
            : props.event.image?.thumbnail?.resource

    return (
        <Masthead
            content={content}
            contentClassName={clsx(
                styles.content,
                props.event.layout === Layout.Wide
                    ? styles.wide
                    : styles.default
            )}
            header={
                <Text
                    element="div"
                    mdType="h5"
                    type="h6"
                    ulType="h3"
                    weight="600"
                    xlType="h4"
                >
                    <FormattedDate
                        endDate={props.event.endDate}
                        format={DateFormat.Medium}
                        startDate={props.event.startDate}
                        timezone={props.event.timezone.name}
                    />
                </Text>
            }
            headerClassName={clsx(
                styles.header,
                props.event.layout === Layout.Default && styles.default
            )}
            layout={props.event.layout}
            media={
                <>
                    {image && (
                        <img
                            alt={props.event.title}
                            className={styles.image}
                            src={image}
                        />
                    )}
                    {hasInternalOnlineEvent && (
                        <OnlineEventOverlay
                            eventId={props.event.id}
                            onlineEvents={onlineEvents}
                        />
                    )}
                </>
            }
            mediaClassName={clsx(
                styles.media,
                props.event.layout === Layout.Default && styles.default
            )}
            title={props.event.title}
        />
    )
}

export default EventMasthead
