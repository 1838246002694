/* istanbul ignore file */
'use client'

import { Form, Formik } from 'formik'
import { FC } from 'react'
import { useYup } from '../../lib/hooks'
import { Button, ButtonGroup, Card, InputGroup, Radio } from '../client'
import styles from './examples.module.css'
import useOnSubmit from './useOnSubmit'

const RadioExample: FC = () => {
    const yup = useYup()
    const { onSubmit } = useOnSubmit()

    return (
        <Card title="Radio">
            <Formik
                initialValues={{
                    singleOption: '',
                }}
                onSubmit={onSubmit}
                validationSchema={yup.object().shape({
                    singleOption: yup.string().required(),
                })}
            >
                {formik => (
                    <Form className={styles.flexCol} noValidate>
                        <InputGroup
                            label="Single option"
                            name="singleOption"
                            required
                        >
                            <Radio
                                help="Help text"
                                name="singleOption"
                                value="Option 1"
                            >
                                Option 1
                            </Radio>
                            <Radio
                                help="Help text"
                                name="singleOption"
                                value="Option 2"
                            >
                                Option 2
                            </Radio>
                            <Radio
                                help="Help text"
                                name="singleOption"
                                value="Option 3"
                                disabled
                            >
                                Option 3
                            </Radio>
                        </InputGroup>
                        <ButtonGroup>
                            <Button loading={formik.isSubmitting} type="submit">
                                Submit
                            </Button>
                        </ButtonGroup>
                    </Form>
                )}
            </Formik>
        </Card>
    )
}

export default RadioExample
