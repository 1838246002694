'use client'

import clsx from 'clsx'
import { Form, Formik } from 'formik'
import { FC, useCallback } from 'react'
import { useTranslation, useYup } from '../../../../lib/hooks'
import {
    Button,
    ButtonGroup,
    ButtonTheme,
    Input,
    InputGroup,
    Radio,
} from '../../../base'
import { StepProps } from '../../wizard'
import wizardStyles from '../../wizard.module.css'

export interface Values {
    maxParticipants?: number
    minParticipants?: number
    participantsAreUnlimited: 'unlimited' | 'limited'
}

const ParticipantsStep: FC<StepProps> = props => {
    const { t } = useTranslation()
    const yup = useYup()

    const onSkip = useCallback(() => {
        props.mergeValues.call(undefined, {
            participantsAreUnlimited: 'unlimited',
            minParticipants: undefined,
            maxParticipants: undefined,
        })

        props.nextStep.call(undefined)
    }, [props.mergeValues, props.nextStep])

    const onSubmit = useCallback(
        async (values: Values) => {
            props.mergeValues.call(undefined, values)
            props.nextStep.call(undefined)
        },
        [props.mergeValues, props.nextStep]
    )

    return (
        <Formik<Values>
            initialValues={{
                participantsAreUnlimited:
                    props.values.participantsAreUnlimited ?? 'unlimited',
                minParticipants: props.values.minParticipants,
                maxParticipants: props.values.maxParticipants,
            }}
            onSubmit={onSubmit}
            validationSchema={yup.object().shape({
                participantsAreUnlimited: yup.string().required(),
                minParticipants: yup.number().positive().integer(),
                maxParticipants: yup.number().positive().integer(),
            })}
            enableReinitialize
            validateOnMount
        >
            {formik => (
                <Form className={wizardStyles.form} noValidate>
                    <div className={wizardStyles.fields}>
                        <InputGroup
                            className={clsx(
                                wizardStyles.radioButtons,
                                wizardStyles.colspan
                            )}
                            name="participantsAreUnlimited"
                        >
                            <Radio
                                name="participantsAreUnlimited"
                                value="unlimited"
                            >
                                {t('wizard:form.unlimited')}
                            </Radio>
                            <Radio
                                name="participantsAreUnlimited"
                                value="limited"
                            >
                                {t('wizard:form.limited')}
                            </Radio>
                        </InputGroup>
                        {formik.values.participantsAreUnlimited ===
                            'limited' && (
                            <>
                                <Input
                                    label={t(
                                        'wizard:form.minParticipants.label'
                                    )}
                                    name="minParticipants"
                                    placeholder={t(
                                        'wizard:form.minParticipants.placeholder'
                                    )}
                                    type="number"
                                />
                                <Input
                                    label={t(
                                        'wizard:form.maxParticipants.label'
                                    )}
                                    name="maxParticipants"
                                    placeholder={t(
                                        'wizard:form.maxParticipants.placeholder'
                                    )}
                                    type="number"
                                />
                            </>
                        )}
                    </div>
                    <ButtonGroup className={wizardStyles.actions}>
                        <Button disabled={!formik.isValid} type="submit">
                            {t('common:continue')}
                        </Button>
                        <Button
                            onClick={onSkip}
                            theme={ButtonTheme.Transparent}
                        >
                            {t('wizard:skip')}
                        </Button>
                    </ButtonGroup>
                </Form>
            )}
        </Formik>
    )
}

export default ParticipantsStep
