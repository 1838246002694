/* istanbul ignore file */
import { FC } from 'react'
import { Card, Link } from '../base'
import styles from './examples.module.css'

const LinkExample: FC = () => (
    <Card contentClassName={styles.flexColStart} title="Link">
        <Link href="/components">Link</Link>
        <Link href="/components" iconName="cat">
            Link with icon
        </Link>
        <Link href="/components" underlineOnHover>
            Link with underline on hover
        </Link>
        <Link href="/components" noUnderline>
            Link without underline
        </Link>
    </Card>
)

export default LinkExample
