'use client'

import clsx from 'clsx'
import { FC } from 'react'
import {
    BookingFragment,
    BookingStatus,
    OperationFragment,
} from '../../../../../lib/graphql/generated/types'
import { useTranslation } from '../../../../../lib/hooks'
import {
    DateFormat,
    FormattedCurrency,
    FormattedDate,
    FormattedHtml,
    Text,
} from '../../../../base'
import styles from './bookingOperationDescription.module.css'

interface Props {
    bookings: BookingFragment[]
    currency?: string | null
    fullyBooked?: boolean
    operation: OperationFragment
    timezone?: string
}

const BookingOperationDescription: FC<Props> = props => {
    const { t } = useTranslation()
    const viewerBookable = props.operation.viewerBookable

    if (!viewerBookable) {
        return null
    }

    const capacity = props.operation.capacity?.available
    const waitinglistCapacity = props.operation.waitinglist?.capacity?.available

    let noCapacity = false
    let capacityText

    if (props.fullyBooked) {
        noCapacity = true
        capacityText = t('registration:selection.operation.fullyBooked')
    } else if (viewerBookable.maximum) {
        if (viewerBookable.waitinglist) {
            capacityText = waitinglistCapacity
                ? t('registration:selection.operation.waitinglistCapacity', {
                      count: waitinglistCapacity,
                  })
                : t('registration:selection.operation.waitinglist')
        } else if (capacity) {
            capacityText = t('registration:selection.operation.capacity', {
                count: capacity,
            })
        }
    } else {
        noCapacity = true

        if (viewerBookable.waitinglist) {
            capacityText =
                waitinglistCapacity === 0
                    ? t('registration:selection.operation.fullyBooked')
                    : t('registration:selection.operation.notBookable')
        } else {
            capacityText =
                capacity === 0
                    ? t('registration:selection.operation.fullyBooked')
                    : t('registration:selection.operation.notBookable')
        }
    }

    const alreadyBooked = props.bookings
        .filter(booking => booking.bookingStatus === BookingStatus.Confirmed)
        .some(booking =>
            booking.groupedBookingOperations.some(
                ({ operation: bookedOperation }) =>
                    bookedOperation.id === props.operation.id
            )
        )

    return (
        <div className={styles.root}>
            <Text element="div" type="smaller">
                {props.operation.operationDates?.length > 0 && (
                    <FormattedDate
                        endDate={props.operation.operationDates[0].endDate}
                        format={DateFormat.Long}
                        startDate={props.operation.operationDates[0].startDate}
                        timezone={props.timezone}
                        withTime
                    />
                )}
                {props.operation.venue?.name && (
                    <div>{props.operation.venue.name}</div>
                )}
            </Text>
            {props.operation.description && (
                <Text element="div">
                    <FormattedHtml html={props.operation.description} />
                </Text>
            )}

            {props.operation.marketPrice && props.currency ? (
                <Text element="div" type="smaller">
                    {t('common:marketPrice')}
                    <span>: </span>
                    <FormattedCurrency
                        amount={props.operation.marketPrice}
                        currency={props.currency}
                    />
                </Text>
            ) : null}
            {props.operation.priceBreakdown && (
                <Text element="div" type="smaller">
                    {t('common:priceBreakdown')}
                    <span>:</span>
                    <FormattedHtml html={props.operation.priceBreakdown} />
                </Text>
            )}
            {capacityText && (
                <Text
                    className={clsx(noCapacity && styles.alert)}
                    element="div"
                    type="small"
                >
                    {capacityText}
                </Text>
            )}
            {alreadyBooked && (
                <Text className={styles.alert} element="div" type="small">
                    {t('registration:selection.operation.alreadyBooked')}
                </Text>
            )}
        </div>
    )
}

export default BookingOperationDescription
