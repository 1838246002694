import dayjs from 'dayjs'
import { BookingsQuery } from '../../graphql/generated/types'
import { OnlineEvent, OnlineEventSettings } from '../../types'
import { dayjsFrom } from '../../utils'
import selectOnlineEventSetting from '../selectOnlineEventSetting'
import selectOperations from '../selectOperations'

const onlineEventFrom = (
    onlineEventSettings: OnlineEventSettings | undefined,
    title: string,
    startDate: any,
    endDate: any
): OnlineEvent | undefined => {
    const onlineEventSetting = selectOnlineEventSetting(onlineEventSettings)
    const _startDate = dayjsFrom(startDate)
    const _endDate = dayjsFrom(endDate)

    if (!onlineEventSetting || !_startDate || !_endDate) {
        return
    }

    return {
        id: onlineEventSetting.id,
        title: title,
        description: onlineEventSetting.description ?? undefined,
        timing: onlineEventSetting.timing,
        startDate: _startDate,
        endDate: _endDate,
        url: onlineEventSetting.url,
        fileExtension:
            onlineEventSetting.__typename === 'InternalOnlineEventSetting' &&
            onlineEventSetting.fileExtension
                ? onlineEventSetting.fileExtension
                : undefined,
        external:
            onlineEventSetting.__typename === 'ExternalOnlineEventSetting',
    }
}

export const selectOnlineEvents = (
    event: NonNullable<BookingsQuery['viewer']>['event']
) => {
    const onlineEvents: OnlineEvent[] = []

    if (!event) {
        return onlineEvents
    }

    let onlineEvent = onlineEventFrom(
        event.onlineEventSettings,
        event.title,
        event.startDate,
        event.endDate
    )

    const now = dayjs()

    if (onlineEvent) {
        onlineEvent.live = now.isBetween(
            onlineEvent.startDate,
            onlineEvent.endDate
        )

        onlineEvents.push(onlineEvent)
    }

    event.subEvents?.forEach(subEvent => {
        onlineEvent = onlineEventFrom(
            subEvent.onlineEventSettings,
            subEvent.title,
            subEvent.startDate,
            subEvent.endDate
        )

        if (onlineEvent) {
            onlineEvent.live = now.isBetween(
                onlineEvent.startDate,
                onlineEvent.endDate
            )

            onlineEvents.push(onlineEvent)
        }
    })

    selectOperations(event).forEach(operation => {
        if (operation.operationDates.length) {
            onlineEvent = onlineEventFrom(
                operation.onlineEventSettings,
                operation.title,
                operation.operationDates[0].startDate,
                operation.operationDates[operation.operationDates.length - 1]
                    .endDate
            )

            if (onlineEvent) {
                onlineEvent.live = operation.operationDates.some(
                    ({ startDate, endDate }) =>
                        now.isBetween(dayjsFrom(startDate), dayjsFrom(endDate))
                )

                onlineEvents.push(onlineEvent)
            }
        }
    })

    return onlineEvents
}

export default selectOnlineEvents
