'use client'

import clsx from 'clsx'
import { FC, useCallback, useState } from 'react'
import { EventQueryEvent } from '../../../../lib/types'
import {
    Avatar,
    Badge,
    Card,
    Dialog,
    FormattedDate,
    Icon,
    Text,
} from '../../../base'
import FormattedDescription from '../../formattedDescription'
import styles from './agendaItem.module.css'

export interface Props {
    eventKey: string
    item: NonNullable<EventQueryEvent['agenda']>[number]
    timezone?: string
}

const AgendaItem: FC<Props> = props => {
    const [dialogOpen, setDialogOpen] = useState(false)

    const openDialog = useCallback(() => setDialogOpen(true), [])

    const closeDialog = useCallback(() => setDialogOpen(false), [])

    const image = props.item.image?.thumbnail?.resource

    return (
        <>
            <Card
                bodyClassName={styles.body}
                className={styles.card}
                contentClassName={styles.content}
                header={
                    <>
                        <div className={clsx(styles.row, styles.date)}>
                            <Icon name="calendar-blank" />
                            <FormattedDate
                                endDate={props.item.endDate}
                                startDate={props.item.startDate}
                                timezone={props.timezone}
                                withTime
                            />
                        </div>
                        {props.item.venue?.name && (
                            <div className={styles.row}>
                                <Icon name="map-pin" />
                                <span>{props.item.venue.name}</span>
                            </div>
                        )}
                        {props.item.tags.length > 0 && (
                            <div className={styles.row}>
                                {props.item.tags.map(tag => (
                                    <Badge
                                        key={tag}
                                        className={styles.tagBadge}
                                    >
                                        {tag}
                                    </Badge>
                                ))}
                            </div>
                        )}
                    </>
                }
                headerClassName={styles.header}
                image={image}
                imageClassName={styles.image}
                onClick={openDialog}
                title={props.item.title}
            >
                {props.item.lead && (
                    <Text element="div">{props.item.lead}</Text>
                )}
                {props.item.speakers.length > 0 && (
                    <div className={styles.speakers}>
                        {props.item.speakers.map(speaker => (
                            <Avatar
                                key={speaker.id}
                                firstName={speaker.firstName}
                                image={speaker?.image?.thumbnail?.resource}
                                lastName={speaker.lastName}
                                size={50}
                            />
                        ))}
                    </div>
                )}
            </Card>
            <Dialog
                darkBackground={Boolean(image)}
                image={image}
                onClose={closeDialog}
                open={dialogOpen}
                title={props.item.title}
            >
                <FormattedDescription
                    additionalFields={props.item.additionalFields}
                    description={props.item.description}
                />
            </Dialog>
        </>
    )
}

export default AgendaItem
