'use client'

import { useCookies } from 'next-client-cookies'
import { FC, useCallback, useEffect, useState } from 'react'
import { tokenKey } from '../../../lib/constants'
import {
    useAcceptInviteeMutation,
    useRejectInviteeMutation,
} from '../../../lib/graphql/generated/hooks'
import { InviteeStatus } from '../../../lib/graphql/generated/types'
import { useNotification, useTranslation } from '../../../lib/hooks'
import { EventQueryEvent } from '../../../lib/types'
import { Container, Divider, Link, LoadingSpinner } from '../../base'
import { InviteeActions } from './actions'
import InvitationSummary from './invitationSummary'
import styles from './inviteeResponse.module.css'
import InviteeStatusMessage from './inviteeStatusMessage'
import InviteeSummary from './inviteeSummary'

interface Props {
    event: EventQueryEvent
    invitee: EventQueryEvent['viewerInvitee']
}

const InviteeResponseClientPage: FC<Props> = props => {
    const { t } = useTranslation()
    const notification = useNotification()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [, acceptInvitee] = useAcceptInviteeMutation()
    const [, rejectInvitee] = useRejectInviteeMutation()

    const onAccept = useCallback(async () => {
        setDisabled(true)
        setLoading(true)

        try {
            if (!props.event || !token) {
                throw Error(t('common:error.internal'))
            }

            const { data, error } = await acceptInvitee({
                token,
                eventId: props.event.id,
            })

            if (error) {
                throw error
            }

            const status =
                data?.viewer?.event?.viewerInvitee?.accept?.object
                    ?.inviteeStatus

            if (
                status != InviteeStatus.Accepted &&
                status != InviteeStatus.Confirmed
            ) {
                throw Error(t('common:error.internal'))
            }

            notification.inform(
                t('invitee:response.accept.confirmationMessage')
            )
        } catch (e) {
            notification.alert(e.message)
            setDisabled(false)
        }

        setLoading(false)
    }, [acceptInvitee, notification, props.event, t, token])

    const onReject = useCallback(async () => {
        setDisabled(true)
        setLoading(true)

        try {
            if (!props.event || !token) {
                throw Error(t('common:error.internal'))
            }

            const { data, error } = await rejectInvitee({
                token,
                eventId: props.event.id,
            })

            if (error) {
                throw error
            }

            const status =
                data?.viewer?.event?.viewerInvitee?.reject?.object
                    ?.inviteeStatus

            if (status != InviteeStatus.Rejected) {
                throw Error(t('common:error.internal'))
            }

            notification.inform(
                t('invitee:response.reject.confirmationMessage')
            )
        } catch (e) {
            notification.alert(e.message)
            setDisabled(false)
        }

        setLoading(false)
    }, [rejectInvitee, notification, props.event, t, token])

    useEffect(() => {
        if (!props.invitee?.respondable) {
            if (!disabled) {
                if (
                    props.invitee?.inviteeStatus === InviteeStatus.Accepted ||
                    props.invitee?.inviteeStatus === InviteeStatus.Confirmed
                ) {
                    notification.inform(t('invitee:statusMessage.accepted'), {
                        modal: true,
                    })
                } else if (
                    props.invitee?.inviteeStatus === InviteeStatus.Rejected
                ) {
                    notification.inform(t('invitee:statusMessage.rejected'), {
                        modal: true,
                    })
                } else {
                    notification.alert(t('invitee:statusMessage.expired'), {
                        modal: true,
                    })
                }
            }

            setDisabled(true)
        }
    }, [props.invitee, notification, t, disabled])

    if (loading || !props.invitee) {
        return <LoadingSpinner centered large />
    }

    return (
        <>
            <Container className={styles.container}>
                <Link
                    className={styles.backLink}
                    href={`/event/${props.event.key}`}
                    iconName="arrow-left"
                    underlineOnHover
                >
                    {props.event.title}
                </Link>
                <InviteeStatusMessage
                    complianceFormDownloadUrl={
                        props.invitee.complianceFormDownloadUrl
                    }
                    complianceFormUploadUrl={
                        props.invitee.complianceFormUploadUrl
                    }
                    complianceProcess={props.invitee.complianceProcess}
                    inviteeStatus={props.invitee.inviteeStatus}
                    respondable={props.invitee.respondable}
                />
                <InvitationSummary
                    currency={props.event.payment?.currency.name}
                    operation={props.invitee.operation}
                    quantity={props.invitee.companions.length + 1}
                    timezone={props.event.timezone.name}
                />
                <Divider />
                <InviteeSummary invitee={props.invitee} />
            </Container>
            <InviteeActions
                disabled={disabled}
                loading={false}
                onAccept={onAccept}
                onReject={onReject}
            />
        </>
    )
}

export default InviteeResponseClientPage
