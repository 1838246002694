import withLocale from '../withLocale'

const canonicalUrl = (
    host: string | null,
    pathname: string,
    locale: string
) => {
    if (!host) {
        return
    }

    const protocol = host.startsWith('localhost') ? 'http' : 'https'

    return `${protocol}://${host}${withLocale(pathname, locale)}`
}

export default canonicalUrl
