import { FC } from 'react'
import { BookingFragment } from '../../../lib/graphql/generated/types'
import { BookingsQueryEvent, RegisterQueryEvent } from '../../../lib/types'
import { DateFormat, FormattedDate, Text } from '../../base'
import styles from './bookingSummary.module.css'
import BookingSummaryOperation from './bookingSummaryOperation'
import BookingSummaryPricingTable from './bookingSummaryPricingTable'

interface Props {
    booking: BookingFragment
    event: BookingsQueryEvent | RegisterQueryEvent
    loadingBooking?: boolean
    noTitle?: boolean
    pricingTable?: boolean
}

// TODO: Maybe separate bookings and review booking summary and possibly child
// components?
const BookingSummary: FC<Props> = props => (
    <>
        {!props.noTitle && (
            <>
                <Text className={styles.date} element="div" type="small">
                    <FormattedDate
                        endDate={props.event.endDate}
                        format={DateFormat.Medium}
                        startDate={props.event.startDate}
                        timezone={props.event.timezone.name}
                    />
                </Text>
                <Text className={styles.title} element="h3" type="h4">
                    {props.event.title}
                </Text>
            </>
        )}
        <ul className={styles.operations}>
            {props.booking.groupedBookingOperations.map(
                ({
                    bookingOperations,
                    bookingOperationStatus,
                    count,
                    operation,
                }) => (
                    <BookingSummaryOperation
                        key={operation.id}
                        bookingOperationStatus={bookingOperationStatus}
                        bookingOperations={bookingOperations}
                        bookingStatus={props.booking.bookingStatus}
                        count={count}
                        event={props.event}
                        loading={props.loadingBooking}
                        operation={operation}
                        participantId={props.event.viewerParticipant?.id}
                    />
                )
            )}
        </ul>
        {props.pricingTable && (
            <BookingSummaryPricingTable booking={props.booking} />
        )}
    </>
)

export default BookingSummary
