'use client'

import { Form, Formik } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { useCookies } from 'next-client-cookies'
import { FC, useCallback } from 'react'
import { tokenKey } from '../../../../../lib/constants'
import { useAddGroupBookingParticipantMutation } from '../../../../../lib/graphql/generated/hooks'
import {
    useNotification,
    useTranslation,
    useYup,
} from '../../../../../lib/hooks'
import { Button, ButtonGroup, ButtonTheme, Input } from '../../../../base'
import styles from '../groupBookingUpdateDialog.module.css'

interface Values {
    participant: string
}

export interface Props {
    closeDialog: () => void
    eventId: string
    groupBookingId: string
    invitedParticipants: Array<{ id: string; name: string }>
    loadingBooking?: boolean
}

const InvitedParticipantsForm: FC<Props> = props => {
    const { t } = useTranslation()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)
    const notification = useNotification()
    const yup = useYup()
    const [, addGroupBookingParticipant] =
        useAddGroupBookingParticipantMutation()

    const onSubmit = useCallback(
        async (values: Values, formikHelpers: FormikHelpers<Values>) => {
            if (!token) {
                notification.alert(t('common:error.internal'), { modal: true })
                return
            }

            try {
                const { data, error } = await addGroupBookingParticipant(
                    {
                        token,
                        eventId: props.eventId,
                        groupBookingId: props.groupBookingId,
                        participantUsernameOrEmail: values.participant,
                    },
                    { additionalTypenames: ['GroupBooking'] }
                )

                if (error) {
                    throw error
                }

                if (
                    data?.viewer?.event?.viewerParticipant
                        ?.addGroupBookingParticipant?.inputError
                ) {
                    formikHelpers.setFieldError(
                        'participant',
                        t('groupBooking:invalidUser')
                    )
                } else {
                    formikHelpers.resetForm()
                }
            } catch (e) {
                console.error(e)
                notification.alert(t('common:error.internal'), { modal: true })
            }
        },
        [
            addGroupBookingParticipant,
            notification,
            props.eventId,
            props.groupBookingId,
            t,
            token,
        ]
    )

    return (
        <Formik<Values>
            initialValues={{
                participant: '',
            }}
            onSubmit={onSubmit}
            validationSchema={yup.object().shape({
                participant: yup.string().required(),
            })}
        >
            {formik => (
                <Form className={styles.form} noValidate>
                    {props.invitedParticipants.length > 0 && (
                        <ol className={styles.participants}>
                            {props.invitedParticipants.map(participant => (
                                <li key={participant.id}>{participant.name}</li>
                            ))}
                        </ol>
                    )}
                    <div className={styles.fields}>
                        <Input
                            disabled={
                                props.loadingBooking || formik.isSubmitting
                            }
                            label={t('groupBooking:participant')}
                            name="participant"
                            placeholder={t('groupBooking:usernameOrEmail')}
                            required
                        />
                    </div>
                    <ButtonGroup>
                        <Button
                            loading={
                                props.loadingBooking || formik.isSubmitting
                            }
                            type="submit"
                        >
                            {t('groupBooking:invite')}
                        </Button>
                        <Button
                            disabled={
                                props.loadingBooking || formik.isSubmitting
                            }
                            onClick={props.closeDialog}
                            theme={ButtonTheme.Cancel}
                        >
                            {t('common:cancel')}
                        </Button>
                    </ButtonGroup>
                </Form>
            )}
        </Formik>
    )
}

export default InvitedParticipantsForm
