'use client'

import { useEffect, useState } from 'react'
import { Breakpoint } from '../../types'

/**
 * https://observablehq.com/@werehamster/avoiding-hydration-mismatch-when-using-react-hooks
 */
const useMediaQuery = (query: string | Breakpoint) => {
    const [matches, setMatches] = useState(false)

    useEffect(() => {
        const mediaQueryList = window.matchMedia(query)
        const listener = () => setMatches(mediaQueryList.matches)

        listener()
        mediaQueryList.addEventListener('change', listener)

        return () => mediaQueryList.removeEventListener('change', listener)
    }, [query])

    return matches
}

export default useMediaQuery
