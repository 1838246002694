/* istanbul ignore file */
'use client'

import { FC, useCallback, useState } from 'react'
import { Button, Card, Collapsible, Divider } from '../client'
import styles from './examples.module.css'
import loremIpsum from './loremIpsum'

const CollapsibleExample: FC = () => {
    const [open, setOpen] = useState(true)
    const [reversedOpen, setReversedOpen] = useState(true)

    const toggle = useCallback(() => setOpen(prev => !prev), [])

    const toggleReversed = useCallback(() => setReversedOpen(prev => !prev), [])

    return (
        <Card title="Collapsible">
            <Collapsible
                collapsed={!open}
                containerClassName={styles.collapsible}
                toggle={<Button onClick={toggle}>Toggle</Button>}
            >
                {loremIpsum}
            </Collapsible>
            <Divider />
            <Collapsible
                collapsed={!reversedOpen}
                containerClassName={styles.collapsible}
                toggle={
                    <Button onClick={toggleReversed}>Toggle (reversed)</Button>
                }
                reversed
            >
                {loremIpsum}
            </Collapsible>
        </Card>
    )
}

export default CollapsibleExample
