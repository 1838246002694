/* istanbul ignore file */
'use client'

import { Form, Formik } from 'formik'
import { FC, useCallback } from 'react'
import { useResendBookingConfirmationByParticipantEmailMutation } from '../../../lib/graphql/generated/hooks'
import {
    useNotification,
    useRouter,
    useTranslation,
    useYup,
} from '../../../lib/hooks'
import { EventQueryEvent } from '../../../lib/types'
import {
    Button,
    ButtonGroup,
    ButtonGroupLayout,
    ButtonTheme,
    Input,
} from '../../base'
import styles from './purlForm.module.css'

interface Values {
    email: string
}

interface Props {
    event: EventQueryEvent
}

const PurlClientPage: FC<Props> = props => {
    const { t } = useTranslation()
    const notification = useNotification()
    const router = useRouter()
    const yup = useYup()
    const [, resendBookingConfirmation] =
        useResendBookingConfirmationByParticipantEmailMutation()

    const onSubmit = useCallback(
        async (values: Values) => {
            try {
                const { data, error } = await resendBookingConfirmation({
                    eventId: props.event.id,
                    email: values.email,
                })

                if (error) {
                    throw error
                }

                if (
                    data?.viewer?.event
                        ?.resendBookingConfirmationByParticipantEmail.inputError
                ) {
                    throw Error(
                        data.viewer.event
                            .resendBookingConfirmationByParticipantEmail
                            .inputError.message
                    )
                }

                notification.confirm(t('purl:confirmation'))
            } catch (e) {
                console.error(e)
                notification.alert(t('common:error.internal'))
            }
        },
        [notification, props.event.id, resendBookingConfirmation, t]
    )

    return (
        <Formik<Values>
            initialValues={{ email: '' }}
            onSubmit={onSubmit}
            validationSchema={yup.object().shape({
                email: yup.string().email().required(),
            })}
        >
            {formik => (
                <Form noValidate>
                    <div className={styles.fields}>
                        <Input
                            label={t('purl:email')}
                            name="email"
                            type="email"
                            required
                        />
                    </div>
                    <ButtonGroup layout={ButtonGroupLayout.FullWidth}>
                        <Button loading={formik.isSubmitting} type="submit">
                            {t('purl:submit')}
                        </Button>
                        <Button
                            disabled={formik.isSubmitting}
                            onClick={router.back}
                            theme={ButtonTheme.Secondary}
                        >
                            {t('common:back')}
                        </Button>
                    </ButtonGroup>
                </Form>
            )}
        </Formik>
    )
}

export default PurlClientPage
