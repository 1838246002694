/* istanbul ignore file */
'use client'

import { FC, useMemo } from 'react'
import { Gender } from '../../../lib/graphql/generated/types'
import { useTranslation } from '../../../lib/hooks'
import { Input, Select, SelectOption } from '../../base'
import styles from './profileForm.module.css'

const ProfileForm: FC = () => {
    const { t } = useTranslation()

    const languageOptions = useMemo(
        () =>
            t('user:language', {
                returnObjects: true,
            }),
        [t]
    ) as { [key: string]: string }

    return (
        <div className={styles.root}>
            <Input
                className={styles.colspan}
                label={t('user:form.firstName')}
                name="firstName"
                required
            />
            <Input
                className={styles.colspan}
                label={t('user:form.lastName')}
                name="lastName"
                required
            />
            <Input
                className={styles.colspan}
                label={t('user:form.email')}
                name="email"
                disabled
                required
            />
            <Input
                label={t('user:form.password')}
                name="password"
                type="password"
            />
            <Select
                label={t('user:form.gender')}
                name="gender"
                placeholder={t('common:selectPlaceholder')}
                required
            >
                <SelectOption value={Gender.Male}>
                    {t('user:gender.male')}
                </SelectOption>
                <SelectOption value={Gender.Female}>
                    {t('user:gender.female')}
                </SelectOption>
                <SelectOption value={Gender.Unspecified}>
                    {t('user:gender.unspecified')}
                </SelectOption>
            </Select>
            <Input label={t('user:form.phone')} name="phone" />
            <Select label={t('user:form.language')} name="language" required>
                {Object.keys(languageOptions).map(key => (
                    <SelectOption key={key} value={key}>
                        {languageOptions[key]}
                    </SelectOption>
                ))}
            </Select>
        </div>
    )
}

export default ProfileForm
