/* istanbul ignore file */
import { FC } from 'react'
import { Card, Text } from '../base'

const TextExample: FC = () => (
    <Card title="Text">
        <Text>Standard</Text>
        <Text type="small">Small</Text>
        <Text type="smaller">Smaller</Text>
        <Text type="h1">Heading 1</Text>
        <Text type="h2">Heading 2</Text>
        <Text type="h3">Heading 3</Text>
        <Text type="h4">Heading 4</Text>
        <Text type="h5">Heading 5</Text>
        <Text type="h6">Heading 6</Text>
        <Text type="d1">Display 1</Text>
        <Text type="d2">Display 2</Text>
        <Text type="d3">Display 3</Text>
    </Card>
)

export default TextExample
