/* istanbul ignore file */
'use client'

import { FC, ReactNode } from 'react'
import styles from './inputLabel.module.css'

interface Props {
    children?: ReactNode
}

const InputLabel: FC<Props> = props => (
    <div className={styles.root}>{props.children}</div>
)

export default InputLabel
