'use client'

import i18next from 'i18next'
import { useMemo } from 'react'
import { useTranslation as useI18nextTranslation } from 'react-i18next'
import { createI18nClientInstance } from '../../i18n'
import useRouter from '../useRouter'

if (!i18next.isInitialized && !i18next.isInitializing) {
    createI18nClientInstance().catch(console.error)
}

/**
 * Replacement for the i18next useTranslation hook that sets the translation
 * function to the locale route param and returns it for convenience.
 */
const useTranslation = () => {
    const { params } = useRouter()

    const { t } = useI18nextTranslation(undefined, {
        lng: params.locale,
    })

    return useMemo(
        () => ({
            locale: params.locale,
            t,
        }),
        [params.locale, t]
    )
}

export default useTranslation
