import { FC } from 'react'
import { Card, Flyout, Icon } from '../base'
import styles from './examples.module.css'

const FlyoutExample: FC = () => (
    <Card contentClassName={styles.flexCol} title="Flyout">
        <Flyout
            toggle={
                <>
                    With any content
                    <Icon name="caret-up" />
                </>
            }
        >
            Lorem ipsum
        </Flyout>
        <Flyout
            toggle={
                <>
                    As list
                    <Icon name="caret-down" />
                </>
            }
            list
        >
            <div>Item 1</div>
            <div>Item 2</div>
            <div>Item 3</div>
        </Flyout>
        <Flyout
            toggle={
                <>
                    Open on hover
                    <Icon name="info" />
                </>
            }
            openOnHover
        >
            Lorem ipsum
        </Flyout>
    </Card>
)

export default FlyoutExample
