'use client'

import clsx from 'clsx'
import { FC } from 'react'
import { useTranslation } from '../../../lib/hooks'
import { Button, Text } from '../../base'
import styles from './loadMoreButton.module.css'

interface Props {
    className?: string
    from: number
    loading?: boolean
    onClick: () => void
    to: number
    total: number
}

const LoadMoreButton: FC<Props> = props => {
    const { t } = useTranslation()

    if (props.to === props.total) {
        return
    }

    return (
        <div className={clsx(styles.root, props.className)}>
            <Text element="span" type="small" noWrap>
                {t('common:fromToOfTotal', {
                    from: props.from,
                    to: props.to,
                    total: props.total,
                })}
            </Text>
            <Button
                disabled={props.to === props.total}
                loading={props.loading}
                onClick={props.onClick}
            >
                {t('common:loadMore')}
            </Button>
        </div>
    )
}

export default LoadMoreButton
