'use client'

import dayjs from 'dayjs'
import flatpickr from 'flatpickr'
import { FC, MouseEvent, useCallback, useEffect, useRef } from 'react'
import { flatpickrLocales } from '../../../lib/constants'
import { useTranslation } from '../../../lib/hooks'
import { FormattedDate } from '../formattedDate'
import { Icon } from '../icon'
import TagButton from '../tagButton'

interface Props {
    from?: Date
    // Format: D.M.YYYY
    highlightedDates?: Set<string>
    onChange: (from?: Date, to?: Date) => void
    range?: boolean
    to?: Date
}

const DateFilter: FC<Props> = props => {
    const { t, locale } = useTranslation()
    const root = useRef<HTMLDivElement>(null)
    const datePicker = useRef<flatpickr.Instance>()

    useEffect(() => {
        /* istanbul ignore next */
        if (!root.current) {
            return
        }

        const options: flatpickr.Options.Options = {
            clickOpens: false,
            dateFormat: 'd.m.Y',
            locale: flatpickrLocales[locale as flatpickr.Options.LocaleKey],
            mode: props.range ? 'range' : 'single',
            monthSelectorType: 'static',
            disableMobile: true,
        }

        if (datePicker.current) {
            datePicker.current.set(options)
        } else {
            datePicker.current = flatpickr(root.current, options)
        }
    }, [locale, props.range])

    const onDayCreate = useCallback(
        (_dObj: any, _dStr: any, _fp: any, dayElem: any) => {
            const date = dayjs(dayElem.dateObj).format('D.M.YYYY')

            if (props.highlightedDates?.has(date)) {
                dayElem.classList.add('highlighted')
            }
        },
        [props.highlightedDates]
    )

    useEffect(
        () => datePicker.current?.set('onDayCreate', onDayCreate),
        [onDayCreate]
    )

    const onChange = useCallback(
        (selectedDates: Date[]) => {
            // Wait for range to be selected before updating dates
            if (props.range && selectedDates.length === 1) {
                return
            }

            const [from, to] = selectedDates
            props.onChange.call(undefined, from, to)
        },
        [props.onChange, props.range]
    )

    useEffect(() => datePicker.current?.set('onChange', onChange), [onChange])

    const onClear = useCallback((e: MouseEvent) => {
        e.stopPropagation()
        datePicker.current?.clear()
    }, [])

    const onClick = useCallback(() => {
        /* istanbul ignore next */
        if (!datePicker.current) {
            return
        }

        if (datePicker.current.isOpen) {
            datePicker.current.close()
        } else {
            datePicker.current.open()
        }
    }, [])

    return (
        <div ref={root}>
            <TagButton
                leftNode={
                    props.from && <Icon name="x-circle" onClick={onClear} />
                }
                onClick={onClick}
                rightNode={<Icon name="caret-down" />}
                selected={!!props.from}
            >
                {props.from ? (
                    <FormattedDate endDate={props.to} startDate={props.from} />
                ) : (
                    t('common:date')
                )}
            </TagButton>
        </div>
    )
}

export default DateFilter
