import flatpickr from 'flatpickr'
import { German } from 'flatpickr/dist/l10n/de'
import { French } from 'flatpickr/dist/l10n/fr'
import { Italian } from 'flatpickr/dist/l10n/it'

export const devEnv = process.env.NEXT_PUBLIC_DEV_ENV === 'true'
export const stagingEnv = process.env.NEXT_PUBLIC_STAGING_ENV === 'true'
export const loadNewrelic = process.env.NEXT_PUBLIC_NEW_RELIC === 'true'

// We still revalidate every 5 min to update changes not triggered by the
// backend such as base data (app branding, organizers, etc.)
export const revalidate = 5 * 60
export const tokenKey = 'eventsFrontendToken'

export const flatpickrLocales = {
    ...flatpickr.l10ns,
    de: German,
    fr: French,
    it: Italian,
}

export const passwordRegex = /.*^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/
export const phoneRegex = /^[+0-9\s]+$/i
export const uuidRegex =
    /^[0-9a-f]{8}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{12}$/i

export const maxUploadSize = 10 * 1024 * 1024 // 10 MB
