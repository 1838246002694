'use client'

import dayjs from 'dayjs'
import { Form, Formik } from 'formik'
import { useCookies } from 'next-client-cookies'
import { FC, ReactNode, useCallback, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { tokenKey } from '../../lib/constants'
import { useEventTypeQuery } from '../../lib/graphql/generated/hooks'
import { CategoriesAndEventTypesQuery } from '../../lib/graphql/generated/types'
import { useEventDatesSearch, useRouter, useTranslation } from '../../lib/hooks'
import { Container, DateFormat, Input } from '../base'
import { SearchState } from './search'
import styles from './search.module.css'
import SearchFilters from './searchFilters'
import SearchResults from './searchResults'

const eventTypes: NonNullable<
    CategoriesAndEventTypesQuery['viewer']
>['eventTypes'] = []

interface Values {
    q?: string
}

interface Props {
    categories?: NonNullable<
        CategoriesAndEventTypesQuery['viewer']
    >['categories']
    children?: ReactNode
    eventTypeId: string
    itemsPerPage?: number
}

const EventTypeSearch: FC<Props> = props => {
    const { t, locale } = useTranslation()
    const router = useRouter()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)
    const itemsPerPage = props.itemsPerPage ?? 8

    const state: SearchState = useMemo(() => {
        const categoryNames = router.searchParams.getAll('category')

        const categoryIds = props.categories
            ?.filter(category => categoryNames.includes(category.name))
            .map(category => category.id)

        const filters = {
            q: router.searchParams.get('q') ?? undefined,
            from: router.searchParams.has('from')
                ? dayjs(
                      router.searchParams.get('from'),
                      DateFormat.Short
                  ).toDate()
                : undefined,
            to: router.searchParams.has('to')
                ? dayjs(
                      router.searchParams.get('to'),
                      DateFormat.Short
                  ).toDate()
                : undefined,
            categories: categoryIds?.length ? categoryIds : undefined,
            promoted: router.searchParams.get('promoted') === 'true',
        }

        return {
            ...filters,
            page: router.searchParams.has('page')
                ? Number(router.searchParams.get('page'))
                : undefined,
            hasActiveFilters: Object.values(filters).some(
                value => typeof value !== 'undefined' && value !== false
            ),
        }
    }, [props.categories, router.searchParams])

    const [eventTypeQuery] = useEventTypeQuery({
        variables: {
            token,
            locale,
            eventTypeKeyOrId: props.eventTypeId,
            categoriesFilterActiveOnly: true,
            eventTeasersLength: itemsPerPage,
            eventTeasersOffset: state.page && (state.page - 1) * itemsPerPage,
            eventTeasersSearch: state.q,
            eventTeasersFilterFromDate: state.from
                ? dayjs(state.from).startOf('day').unix()
                : dayjs().startOf('day').unix(),
            eventTeasersFilterToDate:
                state.to && dayjs(state.to).endOf('day').unix(),
            eventTeasersFilterCategoryIds: state.categories,
            eventTeasersFilterPromotedOnly: state.promoted,
        },
    })

    const highlightedDates = useEventDatesSearch({
        search: state.q,
        categoryIds: state.categories,
        eventTypeIds: [props.eventTypeId],
    })

    const onSubmit = useCallback(
        async (values: Values) => {
            const params = new URLSearchParams(router.searchParams)
            params.delete('page')

            if (values.q) {
                params.set('q', values.q)
            } else {
                params.delete('q')
            }

            router.pushSearchParams(params)
        },
        [router]
    )

    const controls = (
        <Container className={styles.container}>
            <Formik<Values> initialValues={{ q: state.q }} onSubmit={onSubmit}>
                <Form noValidate>
                    <Input
                        name="q"
                        placeholder={t('common:searchPlaceholder')}
                        type="search"
                    />
                </Form>
            </Formik>
            <div>
                {props.categories ? (
                    <SearchFilters
                        categories={props.categories}
                        eventTypes={eventTypes}
                        highlightedDates={highlightedDates}
                    />
                ) : (
                    <div className={styles.searchFilters}>
                        <Skeleton
                            className={styles.skeletonTagButton}
                            width={95}
                        />
                        <Skeleton
                            className={styles.skeletonTagButton}
                            width={135}
                        />
                        <Skeleton
                            className={styles.skeletonTagButton}
                            width={95}
                        />
                        <Skeleton
                            className={styles.skeletonTagButton}
                            width={50}
                        />
                        <Skeleton
                            className={styles.skeletonTagButton}
                            width={80}
                        />
                    </div>
                )}
            </div>
        </Container>
    )

    const results = (
        <SearchResults
            items={eventTypeQuery.data?.viewer?.eventType?.eventTeasers?.items}
            itemsPerPage={itemsPerPage}
            page={state.page}
            totalItems={
                eventTypeQuery.data?.viewer?.eventType?.eventTeasers
                    ?.totalCount ?? 0
            }
        />
    )

    return (
        <>
            {controls}
            {!state.hasActiveFilters && !state.page && props.children}
            {results}
        </>
    )
}

export default EventTypeSearch
