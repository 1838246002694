'use client'

import { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { useRouter } from '../../../../lib/hooks'
import { Button, ButtonTheme, Container, Flyout, Link } from '../../../base'
import { NavigationLink } from '../page'
import styles from './primaryNavigation.module.css'

interface Props {
    children?: ReactNode
    highlightActiveLink?: boolean
    links?: NavigationLink[]
}

const PrimaryNavigation: FC<Props> = props => {
    const { pathname } = useRouter()
    const list = useRef<HTMLUListElement>(null)
    const linkWidths = useRef<number[]>([])
    const [listLinks, setListLinks] = useState<NavigationLink[]>(
        props.links ?? []
    )
    const [menuLinks, setMenuLinks] = useState<NavigationLink[]>([])

    useEffect(() => {
        if (list.current) {
            linkWidths.current = Array.from(list.current.children).map(
                link => link.scrollWidth
            )
        }
    }, [])

    useEffect(() => {
        const updateLinks = () => {
            if (!props.links?.length || !list.current?.parentElement) {
                return
            }

            const gap = 48
            const parent = list.current.parentElement
            const parentStyles = window.getComputedStyle(parent)
            const childrenWidth =
                list.current.nextSibling &&
                list.current.nextSibling !== parent.lastChild
                    ? (list.current.nextSibling as HTMLElement).clientWidth +
                      gap
                    : 0
            const buttonsWidth = 2 * (30 + gap)
            const parentWidth =
                list.current.parentElement.clientWidth -
                parseFloat(parentStyles.paddingRight) -
                parseFloat(parentStyles.paddingLeft) -
                childrenWidth -
                buttonsWidth

            const visibleLinks: NavigationLink[] = []
            const overflowingLinks: NavigationLink[] = []
            let totalWidth = 0

            for (let i = 0; i < linkWidths.current.length; i++) {
                const width = linkWidths.current[i]
                totalWidth += width

                if (i < linkWidths.current.length - 1) {
                    totalWidth += gap
                }

                if (totalWidth <= parentWidth) {
                    visibleLinks.push(props.links[i])
                } else {
                    overflowingLinks.push(props.links[i])
                }
            }

            setListLinks(visibleLinks)
            setMenuLinks(overflowingLinks)
        }

        updateLinks()

        window.addEventListener('resize', updateLinks)

        return () => {
            window.removeEventListener('resize', updateLinks)
        }
    }, [props.links])

    if (!props.links?.length && !props.children) {
        return
    }

    return (
        <nav className={styles.root}>
            <Container className={styles.container}>
                <ul ref={list} className={styles.list}>
                    {listLinks.map((link, i) => (
                        <li key={i}>
                            <Link
                                className={styles.link}
                                href={link.href}
                                noUnderline={
                                    !props.highlightActiveLink ||
                                    link.href.toString() !== pathname
                                }
                            >
                                {link.title}
                            </Link>
                        </li>
                    ))}
                </ul>
                {props.children}
                {menuLinks.length > 0 && (
                    <Flyout
                        toggle={
                            <Button
                                className={styles.menuIcon}
                                iconName="list"
                                iconSize={2}
                                theme={ButtonTheme.Transparent}
                            />
                        }
                        list
                    >
                        {menuLinks.map((link, i) => (
                            <Link
                                key={i}
                                href={link.href}
                                noUnderline={link.href.toString() !== pathname}
                            >
                                {link.title}
                            </Link>
                        ))}
                    </Flyout>
                )}
            </Container>
        </nav>
    )
}

export default PrimaryNavigation
