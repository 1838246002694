/* istanbul ignore file */
'use client'

import { FC, useCallback, useState } from 'react'
import { Button, ButtonGroup, ButtonTheme, Card, Dialog, Text } from '../client'
import styles from './examples.module.css'
import loremIpsum from './loremIpsum'

const DialogExample: FC = () => {
    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogWithImageOpen, setDialogWithImageOpen] = useState(false)
    const [smallDialogOpen, setSmallDialogOpen] = useState(false)

    const openDialog = useCallback(() => setDialogOpen(true), [])

    const closeDialog = useCallback(() => setDialogOpen(false), [])

    const openDialogWithImage = useCallback(
        () => setDialogWithImageOpen(true),
        []
    )

    const closeDialogWithImage = useCallback(
        () => setDialogWithImageOpen(false),
        []
    )

    const openSmallDialog = useCallback(() => setSmallDialogOpen(true), [])

    const closeSmallDialog = useCallback(() => setSmallDialogOpen(false), [])

    return (
        <Card contentClassName={styles.flexCol} title="Dialog">
            <Button onClick={openDialog}>Open dialog</Button>
            <Button onClick={openDialogWithImage}>
                Open dialog with image
            </Button>
            <Button onClick={openSmallDialog}>Open small dialog</Button>
            <Dialog onClose={closeDialog} open={dialogOpen} title="Title">
                <Text>{loremIpsum}</Text>
                <ButtonGroup>
                    <Button onClick={closeDialog}>Ok</Button>
                    <Button onClick={closeDialog} theme={ButtonTheme.Cancel}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </Dialog>
            <Dialog
                image="/example.jpg"
                onClose={closeDialogWithImage}
                open={dialogWithImageOpen}
                title={loremIpsum}
                darkBackground
            >
                <Text>{loremIpsum}</Text>
                <ButtonGroup>
                    <Button onClick={closeDialogWithImage}>Ok</Button>
                    <Button
                        onClick={closeDialogWithImage}
                        theme={ButtonTheme.Cancel}
                    >
                        Cancel
                    </Button>
                </ButtonGroup>
            </Dialog>
            <Dialog
                onClose={closeSmallDialog}
                open={smallDialogOpen}
                title="Title"
                small
            >
                <Text>{loremIpsum}</Text>
                <ButtonGroup>
                    <Button onClick={closeSmallDialog}>Ok</Button>
                    <Button
                        onClick={closeSmallDialog}
                        theme={ButtonTheme.Cancel}
                    >
                        Cancel
                    </Button>
                </ButtonGroup>
            </Dialog>
        </Card>
    )
}

export default DialogExample
