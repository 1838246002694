/* istanbul ignore file */
import { FC } from 'react'
import { Card, Text } from '../base'
import loremIpsum from './loremIpsum'

const CardExample: FC = () => (
    <Card header="Header" href="/components" image="/example.jpg" title="Title">
        <Text>{loremIpsum}</Text>
    </Card>
)

export default CardExample
