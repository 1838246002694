/* istanbul ignore file */
import { FC } from 'react'
import { Badge, Card } from '../base'
import styles from './examples.module.css'

const BadgeExample: FC = () => (
    <Card contentClassName={styles.flexWrap} title="Badge">
        <Badge>1</Badge>
        <Badge>Short</Badge>
        <Badge small>Small</Badge>
        <Badge>Loooooooooooooooooooooooooooooooooooooooooong</Badge>
    </Card>
)

export default BadgeExample
