'use client'

import clsx from 'clsx'
import { FC } from 'react'
import { Trans } from 'react-i18next'
import { EventTeasersQuery } from '../../../lib/graphql/generated/types'
import { useTranslation } from '../../../lib/hooks'
import { Badge, Card, FormattedCurrency, FormattedDate, Icon } from '../../base'
import OnlineBadge from '../onlineBadge'
import EventDatesFlyout from './eventDatesFlyout'
import styles from './eventTeaserCard.module.css'

interface Props {
    className?: string
    eventTeaser: NonNullable<
        NonNullable<EventTeasersQuery['viewer']>['eventTeasers']
    >['items'][number]
}

const EventTeaserCard: FC<Props> = props => {
    const { t, locale } = useTranslation()

    let capacity

    if (props.eventTeaser.capacity.available === 0) {
        capacity = props.eventTeaser.capacity.waitinglist
            ? t('common:waitinglist')
            : t('common:soldOut')
    } else if (typeof props.eventTeaser.capacity.maximum === 'number') {
        capacity = `${props.eventTeaser.capacity.count} / ${props.eventTeaser.capacity.maximum}`
    }

    return (
        <Card
            className={clsx(styles.root, props.className)}
            header={
                <>
                    <div className={styles.row}>
                        <Icon name="calendar-blank" />
                        <FormattedDate
                            endDate={props.eventTeaser.endDate}
                            startDate={props.eventTeaser.startDate}
                            timezone={props.eventTeaser.timezone.name}
                        />
                        <EventDatesFlyout
                            endDate={props.eventTeaser.endDate}
                            eventKey={props.eventTeaser.key}
                            operations={props.eventTeaser.operations}
                            startDate={props.eventTeaser.startDate}
                        />
                    </div>
                    {props.eventTeaser.venueName && (
                        <div className={styles.row}>
                            <Icon name="map-pin" />
                            <span className={styles.venue}>
                                {props.eventTeaser.venueName}
                            </span>
                        </div>
                    )}
                </>
            }
            headerClassName={styles.header}
            href={`/event/${props.eventTeaser.key}`}
            image={props.eventTeaser.imageUrl ?? undefined}
            imageOverlay={
                <>
                    <OnlineBadge
                        className={styles.onlineBadge}
                        endDate={props.eventTeaser.endDate}
                        online={props.eventTeaser.online}
                        operations={props.eventTeaser.operations}
                        startDate={props.eventTeaser.startDate}
                    />
                    {capacity && (
                        <Badge className={styles.capacityBadge}>
                            {capacity}
                        </Badge>
                    )}
                    {typeof props.eventTeaser.lowestPrice === 'number' && (
                        <Badge className={styles.priceBadge}>
                            <Trans
                                components={[
                                    <FormattedCurrency
                                        key={0}
                                        amount={props.eventTeaser.lowestPrice}
                                        currency="CHF"
                                    />,
                                ]}
                                i18nKey="common:priceFrom"
                                lang={locale}
                            />
                        </Badge>
                    )}
                </>
            }
            title={props.eventTeaser.title}
            titleClassName={styles.title}
            imagePlaceholder
        />
    )
}

export default EventTeaserCard
