'use client'

import { FC, useCallback } from 'react'
import Skeleton from 'react-loading-skeleton'
import { EventTeasersQuery } from '../../../lib/graphql/generated/types'
import { useRouter, useTranslation } from '../../../lib/hooks'
import { Container, Pagination, Text } from '../../base'
import { EventTeaserCard } from '../../common'
import styles from './searchResults.module.css'

interface Props {
    items?: NonNullable<
        NonNullable<EventTeasersQuery['viewer']>['eventTeasers']
    >['items']
    itemsPerPage: number
    page?: number
    totalItems: number
}

const SearchResults: FC<Props> = props => {
    const { t } = useTranslation()
    const router = useRouter()

    const onPaginationChange = useCallback(
        (page: number) => {
            const params = new URLSearchParams(router.searchParams)

            if (page > 1) {
                params.set('page', String(page))
            } else {
                params.delete('page')
            }

            router.pushSearchParams(params)
        },
        [router]
    )

    const pagination =
        props.itemsPerPage && props.totalItems ? (
            <Pagination
                className={styles.pagination}
                itemsPerPage={props.itemsPerPage}
                onChange={onPaginationChange}
                page={props.page ?? 1}
                totalItems={props.totalItems}
            />
        ) : null

    if (typeof props.items === 'undefined') {
        return (
            <Container>
                <div className={styles.cards}>
                    <Skeleton borderRadius={3} height={400} />
                    <Skeleton borderRadius={3} height={400} />
                    <Skeleton borderRadius={3} height={400} />
                    <Skeleton borderRadius={3} height={400} />
                    <Skeleton borderRadius={3} height={400} />
                    <Skeleton borderRadius={3} height={400} />
                    <Skeleton borderRadius={3} height={400} />
                    <Skeleton borderRadius={3} height={400} />
                </div>
                {pagination}
            </Container>
        )
    }

    if (!props.items.length) {
        return (
            <Container>
                <Text
                    align="center"
                    className={styles.noResults}
                    element="div"
                    type="d3"
                >
                    {t('common:noResults')}
                </Text>
            </Container>
        )
    }

    return (
        <Container>
            <div className={styles.cards}>
                {props.items.map(eventTeaser => (
                    <EventTeaserCard
                        key={eventTeaser?.id}
                        eventTeaser={eventTeaser}
                    />
                ))}
            </div>
            {pagination}
        </Container>
    )
}

export default SearchResults
