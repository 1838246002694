/* istanbul ignore file */
'use server'

import { getCookies } from 'next-client-cookies/server'
import { tokenKey } from '../constants'
import {
    UsernamePasswordLoginMutation,
    UsernamePasswordLoginMutationVariables,
} from '../graphql/generated/types'
import getGraphqlClient from '../graphql/getGraphqlClient'
import usernamePasswordLoginMutation from '../graphql/mutations/usernamePasswordLoginMutation.graphql'
import { getTranslation } from '../i18n'
import { ServerActionResult } from '../types'
import { decodeTokenExpiration } from '../utils'

interface Args extends UsernamePasswordLoginMutationVariables {
    locale: string
}

const authenticateWithUsernamePassword = async ({
    locale,
    ...variables
}: Args): Promise<ServerActionResult> => {
    try {
        const { data, error } = await getGraphqlClient()
            .mutation<
                UsernamePasswordLoginMutation,
                UsernamePasswordLoginMutationVariables
            >(usernamePasswordLoginMutation, variables)
            .toPromise()

        if (error) {
            throw error
        }

        const token = data?.authentication?.createTokenByUsernamePassword?.token
        const expires = decodeTokenExpiration(token)

        if (!token || !expires) {
            throw Error('Authentication failed')
        }

        getCookies().set(tokenKey, token, { expires })
    } catch (e) {
        console.error(e)
        const { t } = await getTranslation(locale)

        return {
            error: t('common:error.login'),
        }
    }
}

export default authenticateWithUsernamePassword
