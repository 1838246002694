'use client'

import dayjs from 'dayjs'
import { Form, Formik } from 'formik'
import { FC, useCallback } from 'react'
import { createEvent } from '../../../../lib/actions'
import { uuidRegex } from '../../../../lib/constants'
import {
    useNotification,
    useRouter,
    useTranslation,
    useYup,
} from '../../../../lib/hooks'
import { Button, ButtonGroup, Textarea } from '../../../base'
import { StepProps } from '../../wizard'
import wizardStyles from '../../wizard.module.css'

export interface Values {
    comment: string
}

const CommentStep: FC<StepProps> = props => {
    const { t, locale } = useTranslation()
    const notification = useNotification()
    const router = useRouter()
    const yup = useYup()

    const onSubmit = useCallback(
        async (commentValues: Values) => {
            const values = {
                ...props.values,
                ...commentValues,
            }

            if (
                !values.startDate ||
                !values.endDate ||
                !values.recurring ||
                !values.registrationDeadline
            ) {
                notification.alert(t('common:error.internal'))
                return
            }

            const dataUrl = await new Promise<string | undefined>(
                (resolve, reject) => {
                    if (!values.image) {
                        resolve(undefined)
                        return
                    }

                    const reader = new FileReader()
                    reader.readAsDataURL(values.image)

                    reader.onload = () => resolve(reader.result as string)
                    reader.onerror = reject
                }
            )

            const image = dataUrl?.split(',')[1]
            const operationDates = []

            if (values.recurring === 'weekly') {
                const startDay = dayjs(values.startDate).startOf('day')
                const startHour = dayjs(values.startDate).hour()
                const startMinute = dayjs(values.startDate).minute()
                const endDay = dayjs(values.endDate).startOf('day')
                const endHour = dayjs(values.endDate).hour()
                const endMinute = dayjs(values.endDate).minute()
                let currentDate = dayjs(startDay)

                while (currentDate.isSameOrBefore(endDay)) {
                    operationDates.push({
                        startDate: currentDate
                            .add(startHour, 'hour')
                            .add(startMinute, 'minute')
                            .unix(),
                        endDate: currentDate
                            .add(endHour, 'hour')
                            .add(endMinute, 'minute')
                            .unix(),
                    })

                    currentDate = currentDate.add(7, 'day')
                }
            } else {
                operationDates.push({
                    startDate: dayjs(values.startDate).unix(),
                    endDate: dayjs(values.endDate).unix(),
                })
            }

            const result = await createEvent({
                locale,
                title: values.title ?? '',
                image,
                eventTypeId: values.eventType ?? '',
                categoryIds: values.categories,
                description: values.description,
                venueId:
                    values.venue && uuidRegex.test(values.venue)
                        ? values.venue
                        : null,
                newVenue:
                    values.venue && !uuidRegex.test(values.venue)
                        ? {
                              name: values.venue,
                              latitude: null,
                              longitude: null,
                          }
                        : null,
                onlineEventSetting:
                    values.isOnlineEvent && values.onlineUrl
                        ? {
                              url: values.onlineUrl,
                              description: values.onlineDescription,
                          }
                        : null,
                operationDates,
                deadline: dayjs(values.registrationDeadline).unix(),
                minParticipant: values.minParticipants,
                maxParticipant: values.maxParticipants,
                remark: values.comment,
            })

            if (result?.error) {
                notification.alert(result.error)
            } else {
                router.replace('/')
                notification.confirm(t('wizard:confirmSubmit'))
            }
        },
        [locale, notification, props.values, router, t]
    )

    return (
        <Formik<Values>
            initialValues={{
                comment: props.values.comment ?? '',
            }}
            onSubmit={onSubmit}
            validationSchema={yup.object().shape({
                comment: yup.string(),
            })}
            enableReinitialize
            validateOnMount
        >
            {formik => (
                <Form className={wizardStyles.form} noValidate>
                    <div className={wizardStyles.fields}>
                        <Textarea
                            className={wizardStyles.colspan}
                            help={t('wizard:form.comment.help')}
                            label={t('wizard:form.comment.label')}
                            name="comment"
                        />
                    </div>
                    <ButtonGroup className={wizardStyles.actions}>
                        <Button
                            disabled={!formik.isValid}
                            loading={formik.isSubmitting}
                            type="submit"
                        >
                            {t('common:send')}
                        </Button>
                    </ButtonGroup>
                </Form>
            )}
        </Formik>
    )
}

export default CommentStep
