/* istanbul ignore file */
import { FC } from 'react'
import { Card, LoadingSpinner } from '../base'
import styles from './examples.module.css'

const LoadingSpinnerExample: FC = () => (
    <Card contentClassName={styles.flexCol} title="LoadingSpinner">
        <LoadingSpinner />
        <LoadingSpinner large />
    </Card>
)

export default LoadingSpinnerExample
