'use client'

import { Form, Formik } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { useCookies } from 'next-client-cookies'
import { FC, useCallback } from 'react'
import { tokenKey } from '../../../../../lib/constants'
import { useRemoveGroupBookingParticipantMutation } from '../../../../../lib/graphql/generated/hooks'
import { useNotification, useTranslation } from '../../../../../lib/hooks'
import { Button, ButtonGroup, ButtonTheme, Text } from '../../../../base'
import styles from '../groupBookingUpdateDialog.module.css'

interface Values {
    participantId: string
}

export interface Props {
    activeParticipants: Array<{ id: string; name: string }>
    closeDialog: () => void
    eventId: string
    groupBookingId: string
    loadingBooking?: boolean
    maxParticipants?: number
    primaryParticipant: string
}

const ActiveParticipantsForm: FC<Props> = props => {
    const { t } = useTranslation()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)
    const notification = useNotification()
    const [, removeGroupBookingParticipant] =
        useRemoveGroupBookingParticipantMutation()

    const onSubmit = useCallback(
        async (values: Values, formikHelpers: FormikHelpers<Values>) => {
            if (!token) {
                notification.alert(t('common:error.internal'), { modal: true })
                return
            }

            try {
                const { data, error } = await removeGroupBookingParticipant(
                    {
                        token,
                        groupBookingId: props.groupBookingId,
                        eventId: props.eventId,
                        participantId: values.participantId,
                    },
                    { additionalTypenames: ['GroupBooking'] }
                )

                if (error) {
                    throw error
                }

                if (
                    data?.viewer?.event?.viewerParticipant
                        ?.removeGroupBookingParticipant.inputError
                ) {
                    throw Error(
                        data.viewer.event.viewerParticipant
                            .removeGroupBookingParticipant.inputError.message
                    )
                }
            } catch (e) {
                console.error(e)
                notification.alert(t('common:error.internal'), { modal: true })
                formikHelpers.resetForm()
            }
        },
        [
            notification,
            props.eventId,
            props.groupBookingId,
            removeGroupBookingParticipant,
            t,
            token,
        ]
    )

    return (
        <Formik<Values>
            initialValues={{
                participantId: '',
            }}
            onSubmit={onSubmit}
        >
            {formik => {
                const loading = props.loadingBooking || formik.isSubmitting

                return (
                    <Form className={styles.form} noValidate>
                        <ol className={styles.participants}>
                            <li className={styles.primaryParticipant}>
                                {props.primaryParticipant}
                                <br />
                                <Text element="span" type="smaller">
                                    {t('groupBooking:primaryParticipant')}
                                </Text>
                            </li>
                            {props.activeParticipants.map(participant => (
                                <li key={participant.id}>
                                    <span className={styles.participant}>
                                        {participant.id ===
                                        formik.values.participantId ? (
                                            <del>{participant.name}</del>
                                        ) : (
                                            <span>{participant.name}</span>
                                        )}
                                        <Button
                                            disabled={loading}
                                            iconName="trash"
                                            iconSize={2}
                                            onClick={() => {
                                                formik.setFieldValue(
                                                    'participantId',
                                                    participant.id
                                                )

                                                requestAnimationFrame(
                                                    formik.submitForm
                                                )
                                            }}
                                            theme={ButtonTheme.Transparent}
                                        />
                                    </span>
                                </li>
                            ))}
                        </ol>
                        <ButtonGroup>
                            <Button
                                disabled={loading}
                                onClick={props.closeDialog}
                                theme={ButtonTheme.Cancel}
                            >
                                {t('common:cancel')}
                            </Button>
                        </ButtonGroup>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default ActiveParticipantsForm
