'use client'

import clsx from 'clsx'
import { useField, useFormikContext } from 'formik'
import { FC, JSX, ReactNode } from 'react'
import inputStyles from '../input/input.module.css'
import InputError from '../inputError'
import InputHelp from '../inputHelp'
import InputLabel from '../inputLabel'
import styles from './textarea.module.css'

interface Props
    extends Pick<
        JSX.IntrinsicElements['textarea'],
        'className' | 'disabled' | 'placeholder' | 'required'
    > {
    help?: ReactNode
    label?: string
    name: string
}

const Textarea: FC<Props> = props => {
    const [field, meta] = useField(props.name)
    const { isSubmitting } = useFormikContext()

    const disabled = props.disabled || isSubmitting
    const invalid = meta.touched && meta.error

    return (
        <div className={clsx(inputStyles.root, props.className)}>
            <label className={inputStyles.label}>
                {props.label && (
                    <InputLabel>
                        {props.label}
                        {props.required && <span> *</span>}
                    </InputLabel>
                )}
                <textarea
                    {...field}
                    className={clsx(
                        inputStyles.input,
                        styles.textarea,
                        invalid && styles.invalid
                    )}
                    disabled={disabled}
                    placeholder={props.placeholder}
                    required={props.required}
                />
            </label>
            {props.help && <InputHelp>{props.help}</InputHelp>}
            <InputError name={field.name} />
        </div>
    )
}

export default Textarea
