import chroma from 'chroma-js'
import { FC } from 'react'

interface Props {
    backgroundColor?: string
    fontFamily?: string | null
    fontFile?: string
    logo?: string
    logoHeight?: number | null
    logoWidth?: number | null
    primaryColor?: string
    secondaryColor?: string
}

const Branding: FC<Props> = props => {
    const factor = 0.5

    let primaryColorDark
    let primaryColorLight
    let primaryColorTransparent

    if (props.primaryColor) {
        const color = chroma(props.primaryColor)
        primaryColorDark = color.darken(factor).hex()
        primaryColorLight = color.brighten(factor).hex()
        primaryColorTransparent = color.alpha(0.15).css()
    }

    let secondaryColorDark
    let secondaryColorLight

    if (props.secondaryColor) {
        const color = chroma(props.secondaryColor)
        secondaryColorDark = color.darken(factor).hex()
        secondaryColorLight = color.brighten(factor).hex()
    }

    return (
        <>
            {props.fontFile && <link href={props.fontFile} rel="stylesheet" />}
            {props.fontFamily && (
                <style
                    dangerouslySetInnerHTML={{
                        __html: `body {
                            --fontFamily: ${props.fontFamily};
                        }`,
                    }}
                />
            )}
            {props.primaryColor && (
                <style
                    dangerouslySetInnerHTML={{
                        __html: `body {
                            --primaryColor: ${props.primaryColor};
                            --primaryColorDark: ${primaryColorDark};
                            --primaryColorLight: ${primaryColorLight};
                            --primaryColorTransparent: ${primaryColorTransparent};
                        }`,
                    }}
                />
            )}
            {props.secondaryColor && (
                <style
                    dangerouslySetInnerHTML={{
                        __html: `body {
                            --secondaryColor: ${props.secondaryColor};
                            --secondaryColorDark: ${secondaryColorDark};
                            --secondaryColorLight: ${secondaryColorLight};
                        }`,
                    }}
                />
            )}
            {props.backgroundColor && (
                <style
                    dangerouslySetInnerHTML={{
                        __html: `body {
                            --backgroundColor: ${props.backgroundColor};
                        }`,
                    }}
                />
            )}
            {props.logo && (
                <style
                    dangerouslySetInnerHTML={{
                        __html: `body {
                            --logo: url(${props.logo});
                            ${props.logoWidth && `--logoWidth: ${props.logoWidth};`}
                            ${props.logoHeight && `--logoHeight: ${props.logoHeight};`}
                        }`,
                    }}
                />
            )}
        </>
    )
}

export default Branding
