/* istanbul ignore file */
import clsx from 'clsx'
import { FC } from 'react'
import styles from './divider.module.css'

interface Props {
    className?: string
}

const Divider: FC<Props> = props => (
    <hr className={clsx(styles.root, props.className)} />
)

export default Divider
