'use client'

import clsx from 'clsx'
import dayjs from 'dayjs'
import { FC, useMemo } from 'react'
import { dayjsFrom } from '../../../lib/utils'
import { Badge } from '../../base'
import styles from './onlineBadge.module.css'

interface Props {
    className?: string
    endDate?: any
    online: boolean
    operations?: Array<{
        online: boolean
        operationDates: Array<{
            endDate: any
            startDate: any
        }>
    }> | null
    startDate?: any
}

const OnlineBadge: FC<Props> = props => {
    const online = useMemo(
        () =>
            props.online ||
            props.operations?.some(operation => operation.online),
        [props.online, props.operations]
    )

    const live = useMemo(() => {
        const now = dayjs()
        const startDate = dayjsFrom(props.startDate)
        const endDate = dayjsFrom(props.endDate)

        if (props.online && now.isBetween(startDate, endDate)) {
            return true
        }

        return (
            props.operations?.some(
                operation =>
                    operation.online &&
                    operation.operationDates.some(operationDate =>
                        now.isBetween(
                            dayjsFrom(operationDate.startDate),
                            dayjsFrom(operationDate.endDate)
                        )
                    )
            ) ?? false
        )
    }, [props.endDate, props.online, props.operations, props.startDate])

    if (!online) {
        return
    }

    return (
        <Badge
            className={clsx(styles.root, live && styles.live, props.className)}
        >
            {live ? 'Live' : 'Online'}
        </Badge>
    )
}

export default OnlineBadge
