/* istanbul ignore file */
'use client'

import { ErrorMessage } from 'formik'
import { FC } from 'react'
import { Icon } from '../../icon'
import styles from './inputError.module.css'

interface Props {
    name: string
}

const InputError: FC<Props> = props => (
    <ErrorMessage name={props.name}>
        {(message: string) => (
            <div className={styles.message}>
                <Icon className={styles.icon} name="warning-circle" />
                {message}
            </div>
        )}
    </ErrorMessage>
)

export default InputError
