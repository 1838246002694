'use client'

import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { dayjsFrom } from '../../../lib/utils'
import styles from './formattedDate.module.css'

export enum DateFormat {
    Long = 'ddd, D. MMMM YYYY',
    Medium = 'D. MMM YYYY',
    Short = 'D.M.YYYY',
    Time = 'HH:mm',
}

interface Props {
    className?: string
    endDate?: any
    format?: DateFormat.Short | DateFormat.Medium | DateFormat.Long
    startDate?: any
    timezone?: string
    withTime?: boolean
}

const FormattedDate: FC<Props> = props => {
    const startDate = useMemo(
        () => dayjsFrom(props.startDate, props.timezone),
        [props.startDate, props.timezone]
    )

    const endDate = useMemo(
        () => dayjsFrom(props.endDate, props.timezone),
        [props.endDate, props.timezone]
    )

    if (!startDate) {
        return null
    }

    const format = props.format ?? DateFormat.Short

    if (!endDate || endDate.diff(startDate, 'minute') === 0) {
        return (
            <span className={clsx(styles.noWrap, props.className)}>
                {startDate.format(format)}
                {props.withTime && <>, {startDate.format(DateFormat.Time)}</>}
            </span>
        )
    }

    if (endDate.diff(startDate, 'day') === 0) {
        return (
            <span className={clsx(styles.noWrap, props.className)}>
                {startDate.format(format)}
                {props.withTime && (
                    <>
                        , {startDate.format(DateFormat.Time)} &ndash;{' '}
                        {endDate.format(DateFormat.Time)}
                    </>
                )}
            </span>
        )
    }

    return (
        <span className={props.className}>
            <span className={styles.noWrap}>
                {startDate.format(format)}
                {props.withTime && (
                    <>, {startDate.format(DateFormat.Time)}</>
                )}{' '}
                &ndash;
            </span>{' '}
            <span className={styles.noWrap}>
                {endDate.format(format)}
                {props.withTime && <>, {endDate.format(DateFormat.Time)}</>}
            </span>
        </span>
    )
}

export default FormattedDate
