'use client'

import {
    FC,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { AppContext } from '../../../lib/providers'
import { HeaderNotification, ModalNotification } from '../../base'
import styles from './notifications.module.css'

interface Props {
    children?: ReactNode
}

const Notifications: FC<Props> = props => {
    const { notifications, setNotifications } = useContext(AppContext)

    const [modalNotificationsOpen, setModalNotificationsOpen] = useState<
        Record<number, boolean>
    >({})

    const removeNotification = useCallback(
        (id: number) => {
            setNotifications(prev => {
                const notification = prev.find(n => n.id === id)

                if (notification?.onClosed) {
                    notification.onClosed()
                }

                return prev.filter(n => n.id !== id)
            })

            setModalNotificationsOpen(prev => {
                const _modalNotificationsOpen = { ...prev }
                delete _modalNotificationsOpen[id]

                return _modalNotificationsOpen
            })
        },
        [setNotifications]
    )

    const onModalNotificationClose = useCallback((id: number) => {
        setModalNotificationsOpen(prev => ({
            ...prev,
            [id]: false,
        }))
    }, [])

    const headerNotifications = useMemo(
        () => notifications.filter(notification => !notification.modal),
        [notifications]
    )

    const modalNotifications = useMemo(
        () => notifications.filter(notification => notification.modal),
        [notifications]
    )

    useEffect(() => {
        const keys = Object.keys(modalNotificationsOpen)

        modalNotifications.forEach(notification => {
            const id = notification.id.toString()

            if (!keys.includes(id)) {
                setModalNotificationsOpen(prev => ({
                    ...prev,
                    [id]: true,
                }))
            }
        })
    }, [modalNotifications, modalNotificationsOpen])

    return (
        <>
            {headerNotifications.length > 0 && (
                <div className={styles.headerNotifications}>
                    {headerNotifications.map(notification => (
                        <HeaderNotification
                            key={notification.id}
                            id={notification.id}
                            onClosed={removeNotification}
                            type={notification.type}
                        >
                            {notification.message}
                        </HeaderNotification>
                    ))}
                </div>
            )}
            {props.children}
            {modalNotifications.map(notification => (
                <ModalNotification
                    key={notification.id}
                    iconName={notification.modalIconName}
                    id={notification.id}
                    onClose={onModalNotificationClose}
                    onClosed={removeNotification}
                    open={modalNotificationsOpen[notification.id]}
                    type={notification.type}
                >
                    {notification.message}
                </ModalNotification>
            ))}
        </>
    )
}

export default Notifications
