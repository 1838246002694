'use client'

import { useCookies } from 'next-client-cookies'
import { FC } from 'react'
import { useInView } from 'react-intersection-observer'
import Skeleton from 'react-loading-skeleton'
import { tokenKey } from '../../../lib/constants'
import { useEventTeasersQuery } from '../../../lib/graphql/generated/hooks'
import { CategoriesAndEventTypesQuery } from '../../../lib/graphql/generated/types'
import { useTranslation } from '../../../lib/hooks'
import { dayjsFrom } from '../../../lib/utils'
import { Button, ButtonTheme, Container, DateFormat, Text } from '../../base'
import { EventTeaserCard } from '../../common'
import styles from './searchSnippet.module.css'

interface Props {
    eventType?: NonNullable<
        NonNullable<CategoriesAndEventTypesQuery['viewer']>['eventTypes']
    >[number]
    filterAppLibraryOnly?: boolean
    filterFromDate?: number
    filterPromotedOnly?: boolean
    title: string
}

const SearchSnippet: FC<Props> = props => {
    const { t, locale } = useTranslation()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)
    const { ref, inView } = useInView({ triggerOnce: true })

    const [eventTeasersQuery] = useEventTeasersQuery({
        variables: {
            token,
            locale,
            length: 4,
            filterFromDate: props.filterFromDate,
            filterEventTypeIds: props.eventType && [props.eventType.id],
            filterAppLibraryOnly: props.filterAppLibraryOnly,
            filterPromotedOnly: props.filterPromotedOnly,
        },
        pause: !inView,
    })

    const total = eventTeasersQuery.data?.viewer?.eventTeasers?.totalCount ?? 0
    const eventTeasers = eventTeasersQuery.data?.viewer?.eventTeasers?.items

    if (eventTeasersQuery.data && !total) {
        return null
    }

    const searchParams = new URLSearchParams()

    if (props.filterFromDate) {
        const from = dayjsFrom(props.filterFromDate)?.format(DateFormat.Short)

        if (from) {
            searchParams.set('from', from)
        }
    }

    if (props.filterPromotedOnly) {
        searchParams.set('promoted', 'true')
    }

    return (
        <Container>
            <div ref={ref} className={styles.header}>
                <Text element="h2" smType="h2" type="h3" noMargin>
                    {props.title}
                </Text>
                {total > 0 && (
                    <div>
                        <Button
                            href={`${props.eventType ? `/type/${props.eventType.key}` : '/'}?${searchParams.toString()}`}
                            theme={ButtonTheme.Transparent}
                        >
                            {t('home:showAll')} ({total})
                        </Button>
                    </div>
                )}
            </div>
            {eventTeasers ? (
                <div className={styles.cards}>
                    {eventTeasers.map(eventTeaser => (
                        <EventTeaserCard
                            key={eventTeaser?.id}
                            eventTeaser={eventTeaser}
                        />
                    ))}
                </div>
            ) : (
                <div className={styles.cards}>
                    <Skeleton borderRadius={5} height={380} />
                    <Skeleton borderRadius={5} height={380} />
                    <Skeleton borderRadius={5} height={380} />
                    <Skeleton borderRadius={5} height={380} />
                </div>
            )}
        </Container>
    )
}

export default SearchSnippet
