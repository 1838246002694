'use client'

import dayjs from 'dayjs'
import { useCookies } from 'next-client-cookies'
import { useMemo } from 'react'
import { tokenKey } from '../../constants'
import { useEventDatesQuery } from '../../graphql/generated/hooks'
import { dayjsFrom } from '../../utils'
import useTranslation from '../useTranslation'

const useEventDatesSearch = (args: {
    categoryIds?: string[]
    eventTypeIds?: string[]
    search?: string
}): Set<string> | undefined => {
    const { locale } = useTranslation()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)

    const [eventDatesQuery] = useEventDatesQuery({
        variables: {
            token,
            locale,
            length: 64,
            search: args.search,
            filterFromDate: dayjs().startOf('day').subtract(1, 'year').unix(),
            filterToDate: dayjs().endOf('day').add(1, 'year').unix(),
            filterCategoryIds: args.categoryIds,
            filterEventTypeIds: args.eventTypeIds,
        },
    })

    return useMemo(() => {
        if (!eventDatesQuery.data?.viewer?.dates?.items) {
            return
        }

        const dates = new Set<string>()

        for (const item of eventDatesQuery.data.viewer.dates.items) {
            if (!item) {
                continue
            }

            for (const operation of item.operations) {
                for (const { startDate } of operation.operationDates) {
                    const date = dayjsFrom(startDate)?.format('D.M.YYYY')

                    if (date) {
                        dates.add(date)
                    }
                }
            }
        }

        return dates
    }, [eventDatesQuery.data?.viewer?.dates?.items])
}

export default useEventDatesSearch
