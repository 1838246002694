/* istanbul ignore file */
'use client'

import { FC, useCallback } from 'react'
import { useNotification } from '../../lib/hooks'
import { Button, Card } from '../client'
import styles from './examples.module.css'

const HeaderNotificationExample: FC = () => {
    const notification = useNotification()

    const showInformation = useCallback(
        () => notification.inform('Information message'),
        [notification]
    )

    const showConfirmation = useCallback(
        () => notification.confirm('Confirmation message'),
        [notification]
    )

    const showAlert = useCallback(
        () => notification.alert('Alert message'),
        [notification]
    )

    return (
        <Card contentClassName={styles.flexCol} title="HeaderNotification">
            <Button onClick={showInformation}>Show information</Button>
            <Button onClick={showConfirmation}>Show confirmation</Button>
            <Button onClick={showAlert}>Show alert</Button>
        </Card>
    )
}

export default HeaderNotificationExample
