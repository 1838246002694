'use client'

import { useCookies } from 'next-client-cookies'
import { FC, useMemo } from 'react'
import { tokenKey } from '../../../lib/constants'
import { useBookingsQuery } from '../../../lib/graphql/generated/hooks'
import { useTranslation } from '../../../lib/hooks'
import { selectOperations } from '../../../lib/selectors'
import {
    BottomBar,
    Container,
    DateFormat,
    FormattedDate,
    Text,
} from '../../base'
import EventActions from '../eventActions'
import styles from './eventBottomBar.module.css'

interface Props {
    eventKey: string
    open?: boolean
    openBookings: () => void
}

const EventBottomBar: FC<Props> = props => {
    const { t, locale } = useTranslation()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)

    const [bookingsQuery] = useBookingsQuery({
        variables: {
            token,
            locale,
            eventKeyOrId: props.eventKey,
        },
    })

    const event = bookingsQuery.data?.viewer?.event

    const waitinglistBookable = useMemo(() => {
        const operations = selectOperations(event)

        return operations.some(
            operation => !!operation.viewerBookable?.waitinglist
        )
    }, [event])

    if (
        !event ||
        !event.viewerRegistration?.running ||
        (!event.capacity?.bookable &&
            (!event.capacity?.waitinglist || !waitinglistBookable))
    ) {
        return
    }

    return (
        <BottomBar className={styles.root} open={props.open}>
            <Container className={styles.container}>
                <div className={styles.information}>
                    <Text element="div" type="h4" weight="600">
                        {event.title}
                    </Text>
                    {Boolean(
                        event.deadline &&
                            !event.viewerParticipant?.bookings?.length
                    ) && (
                        <Text
                            className={styles.deadline}
                            element="div"
                            type="small"
                        >
                            {t('event:deadline')}
                            <FormattedDate
                                format={DateFormat.Medium}
                                startDate={event.deadline}
                                timezone={event.timezone.name}
                                withTime
                            />
                        </Text>
                    )}
                </div>
                <EventActions
                    className={styles.actions}
                    event={event}
                    loading={bookingsQuery.fetching || bookingsQuery.stale}
                    moreOptionsClassName={styles.moreOptions}
                    openBookings={props.openBookings}
                    viewerAuthenticationMethod={
                        bookingsQuery.data?.viewer?.authenticationMethod
                    }
                />
            </Container>
        </BottomBar>
    )
}

export default EventBottomBar
