import clsx from 'clsx'
import { FC, ReactElement, ReactNode } from 'react'
import HeaderNavigation from './headerNavigation'
import { Props as MastheadProps } from './masthead'
import styles from './page.module.css'
import PrimaryNavigation from './primaryNavigation'

export interface NavigationLink {
    href: string
    title: string
}

export interface Props {
    availableLocales?: string[]
    children?: ReactNode
    className?: string
    embedded?: boolean
    eventCreation?: boolean
    highlightActiveNavigationLink?: boolean
    logoHref?: string
    masthead?: ReactElement<MastheadProps>
    navigationLinks?: NavigationLink[]
    navigationNode?: ReactNode
    noHeader?: boolean
    noLogo?: boolean
    noNavigation?: boolean
    nonWhiteBackground?: boolean
}

const Page: FC<Props> = props => (
    <>
        <div className={styles.root}>
            {!props.noHeader && !props.embedded && (
                <header>
                    <HeaderNavigation
                        availableLocales={props.availableLocales}
                        eventCreation={props.eventCreation}
                        logoHref={props.logoHref}
                        noLogo={props.noLogo}
                        noNavigation={props.noNavigation}
                    />
                    {!props.noNavigation && (
                        <PrimaryNavigation
                            highlightActiveLink={
                                props.highlightActiveNavigationLink
                            }
                            links={props.navigationLinks}
                        >
                            {props.navigationNode}
                        </PrimaryNavigation>
                    )}
                </header>
            )}
            {/* TODO: Use <main> and move masthead to <header>? */}
            {props.masthead}
            <div
                className={clsx(
                    styles.main,
                    props.nonWhiteBackground && styles.nonWhiteBg,
                    props.className
                )}
            >
                {props.children}
            </div>
        </div>
    </>
)

export default Page
