'use client'

import { ChangeEvent, FC, useCallback, useRef } from 'react'
import { maxUploadSize } from '../../../../lib/constants'
import { useNotification, useTranslation } from '../../../../lib/hooks'
import {
    Button,
    ButtonGroup,
    ButtonGroupLayout,
    ButtonTheme,
} from '../../../base'
import inputStyles from '../../../base/inputs/input/input.module.css'
import InputHelp from '../../../base/inputs/inputHelp/inputHelp'
import InputLabel from '../../../base/inputs/inputLabel/inputLabel'
import { StepProps } from '../../wizard'
import wizardStyles from '../../wizard.module.css'

export interface Values {
    image: File | null
}

const ImageStep: FC<StepProps> = props => {
    const { t } = useTranslation()
    const notification = useNotification()
    const input = useRef<HTMLInputElement>(null)

    const onClick = useCallback(() => {
        input.current?.click()
    }, [])

    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const files = e.target.files

            if (!files?.length) {
                return
            }

            const [file] = files

            if (!file.type.startsWith('image/')) {
                notification.alert(t('common:error.fileType'))
            } else if (file.size > maxUploadSize) {
                notification.alert(t('common:error.fileSize'))
            } else {
                props.mergeValues.call(undefined, {
                    image: file,
                })
            }
        },
        [notification, props.mergeValues, t]
    )

    const onDelete = useCallback(() => {
        props.mergeValues.call(undefined, { image: null })
    }, [props.mergeValues])

    return (
        <form
            className={wizardStyles.form}
            onSubmit={props.nextStep}
            noValidate
        >
            <div>
                {props.values.image ? (
                    <div className={wizardStyles.filePreview}>
                        <img
                            alt={props.values.image.name}
                            className={wizardStyles.fileThumbnail}
                            src={URL.createObjectURL(props.values.image)}
                        />
                        <span className={wizardStyles.fileName}>
                            {props.values.image.name}
                        </span>
                        <Button
                            className={wizardStyles.fileDeleteButton}
                            iconName="x"
                            iconSize={2}
                            onClick={onDelete}
                            theme={ButtonTheme.Transparent}
                        />
                    </div>
                ) : (
                    <div className={inputStyles.root}>
                        <label className={inputStyles.label}>
                            <InputLabel>
                                {t('wizard:form.image.label')}
                            </InputLabel>
                            <input
                                ref={input}
                                accept=".jpg,.jpeg,.png"
                                className={wizardStyles.fileInput}
                                onChange={onChange}
                                type="file"
                            />
                        </label>
                        <ButtonGroup layout={ButtonGroupLayout.FullWidth}>
                            <Button
                                iconName="plus"
                                onClick={onClick}
                                theme={ButtonTheme.Secondary}
                            >
                                {t('wizard:form.image.button')}
                            </Button>
                        </ButtonGroup>
                        <InputHelp>
                            {t('wizard:form.image.help')} /{' '}
                            {`.jpg, .jpeg, .png, ... / max. ${Math.floor(
                                maxUploadSize / 1024 / 1024
                            )} MB`}
                        </InputHelp>
                    </div>
                )}
            </div>
            <ButtonGroup className={wizardStyles.actions}>
                <Button type="submit">{t('common:continue')}</Button>
                <Button
                    onClick={props.nextStep}
                    theme={ButtonTheme.Transparent}
                >
                    {t('wizard:skip')}
                </Button>
            </ButtonGroup>
        </form>
    )
}

export default ImageStep
