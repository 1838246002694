'use client'

import { Form, Formik } from 'formik'
import { FC, useCallback } from 'react'
import { requestPasswordReset } from '../../../lib/actions'
import {
    useNotification,
    useRouter,
    useTranslation,
    useYup,
} from '../../../lib/hooks'
import {
    Button,
    ButtonGroup,
    ButtonGroupLayout,
    ButtonTheme,
    Input,
    Text,
} from '../../base'
import styles from '../login.module.css'

interface Values {
    usernameOrEmail: string
}

interface Props {
    from?: string
}

const PasswordResetRequestForm: FC<Props> = props => {
    const { t, locale } = useTranslation()
    const notification = useNotification()
    const router = useRouter()
    const yup = useYup()

    const goToLogin = useCallback(
        () =>
            router.replace('/login', {
                searchParams: props.from
                    ? new URLSearchParams({ from: props.from })
                    : undefined,
            }),
        [props.from, router]
    )

    const onSubmit = useCallback(
        async (values: Values) => {
            const result = await requestPasswordReset({
                locale,
                user: values,
            })

            if (result?.error) {
                notification.alert(result.error)
            } else {
                goToLogin()

                notification.confirm(
                    t('login:passwordResetRequest.confirmation')
                )
            }
        },
        [goToLogin, locale, notification, t]
    )

    return (
        <>
            <Text element="h2" type="h2">
                {t('login:passwordResetRequest.title')}
            </Text>
            <Formik<Values>
                initialValues={{ usernameOrEmail: '' }}
                onSubmit={onSubmit}
                validationSchema={yup.object().shape({
                    usernameOrEmail: yup.string().required(),
                })}
            >
                {formik => (
                    <Form noValidate>
                        <div className={styles.fields}>
                            <Input
                                label={t(
                                    'login:passwordResetRequest.usernameOrEmail'
                                )}
                                name="usernameOrEmail"
                                required
                            />
                        </div>
                        <ButtonGroup layout={ButtonGroupLayout.FullWidth}>
                            <Button loading={formik.isSubmitting} type="submit">
                                {t('common:send')}
                            </Button>
                            <Button
                                disabled={formik.isSubmitting}
                                onClick={goToLogin}
                                theme={ButtonTheme.Secondary}
                            >
                                {t('common:cancel')}
                            </Button>
                        </ButtonGroup>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default PasswordResetRequestForm
