'use client'

import {
    createContext,
    Dispatch,
    FC,
    ReactNode,
    SetStateAction,
    useMemo,
    useState,
} from 'react'
import { AuthenticationMethod } from '../../graphql/generated/types'
import { AppNotification } from '../../types'

interface Context {
    authenticationMethods: AuthenticationMethod[]
    eventCreation: boolean
    notifications: AppNotification[]
    setNotifications: Dispatch<SetStateAction<AppNotification[]>>
    userRegistration: boolean
}

export const AppContext = createContext<Context>({
    authenticationMethods: [],
    eventCreation: false,
    notifications: [],
    setNotifications: () => undefined,
    userRegistration: false,
})

interface Props {
    authenticationMethods?: AuthenticationMethod[]
    children: ReactNode
    eventCreation?: boolean
    notifications?: AppNotification[]
    userRegistration?: boolean
}

const AppProvider: FC<Props> = props => {
    const [notifications, setNotifications] = useState<AppNotification[]>(
        props.notifications ?? []
    )

    const value = useMemo(
        () => ({
            authenticationMethods: props.authenticationMethods ?? [],
            eventCreation: props.eventCreation ?? false,
            notifications,
            setNotifications,
            userRegistration: props.userRegistration ?? false,
        }),
        [
            notifications,
            props.authenticationMethods,
            props.eventCreation,
            props.userRegistration,
        ]
    )

    return (
        <AppContext.Provider value={value}>
            {props.children}
        </AppContext.Provider>
    )
}

export default AppProvider
