'use client'

import { useFormikContext } from 'formik'
import { ChangeEvent, FC, useCallback, useMemo, useRef } from 'react'
import { OperationFragment } from '../../../../../lib/graphql/generated/types'
import { useTranslation } from '../../../../../lib/hooks'
import { RegisterQueryEvent } from '../../../../../lib/types'
import {
    Badge,
    Checkbox,
    FormattedCurrency,
    NumericStepper,
    Radio,
} from '../../../../base'
import { OnlineBadge } from '../../../../common'
import InputField from '../../inputField'
import BookingOperationDescription from '../bookingOperationDescription'
import BookingOperationGroupBookings from '../bookingOperationGroupBookings'
import { Values } from '../selection'
import { inputFieldsToValue } from '../utils'
import styles from './bookingOperations.module.css'

interface Props {
    event: RegisterQueryEvent
    multiBookable: boolean
    operation: OperationFragment
    operations: OperationFragment[]
}

const BookingOperations: FC<Props> = props => {
    const { t } = useTranslation()
    const formik = useFormikContext<Values>()
    const bookingOperations = formik.values[props.operation.id]
    const checked = Array.isArray(bookingOperations)
    const checkedRef = useRef(checked)
    checkedRef.current = checked

    // Update Formik values manually because of complex data structure
    const onInputItemChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            checkedRef.current = e.currentTarget.checked

            if (checkedRef.current) {
                if (props.multiBookable) {
                    formik
                        .setFieldValue(props.operation.id, [
                            inputFieldsToValue(props.operation.inputFields),
                        ])
                        .catch(console.error)
                } else {
                    // Remove other mutually exclusive booking operations when
                    // selecting this one
                    const otherIds = props.operations
                        .filter(op => op.id !== props.operation.id)
                        .map(op => op.id)

                    const values = Object.entries(formik.values).reduce(
                        (acc, [id, value]) =>
                            otherIds.includes(id)
                                ? acc
                                : { ...acc, [id]: value },
                        {}
                    )

                    formik
                        .setValues({
                            ...values,
                            [props.operation.id]: [
                                inputFieldsToValue(props.operation.inputFields),
                            ],
                        })
                        .catch(console.error)
                }
            } else {
                const { [props.operation.id]: removed, ...values } =
                    formik.values

                formik.setValues(values).catch(console.error)
            }
        },
        [
            formik,
            props.multiBookable,
            props.operation.id,
            props.operation.inputFields,
            props.operations,
        ]
    )

    const onStepperChange = useCallback(
        (value: number) => {
            if (!checkedRef.current) {
                return
            }

            const _bookingOperations = [...bookingOperations]

            while (value > _bookingOperations.length) {
                _bookingOperations.push(
                    inputFieldsToValue(props.operation.inputFields)
                )
            }

            while (value < _bookingOperations.length) {
                _bookingOperations.pop()
            }

            formik
                .setFieldValue(props.operation.id, _bookingOperations)
                .catch(console.error)
        },
        [
            bookingOperations,
            formik,
            props.operation.id,
            props.operation.inputFields,
        ]
    )

    const publicGroupsFullyBooked = useMemo(
        () =>
            Boolean(
                props.operation.groupBookingDefinition?.viewerGroupBookable
                    ?.maximum === 0 &&
                    (!props.operation.groupBookings?.items.length ||
                        !props.operation.groupBookings.items.some(
                            item =>
                                props.operation.groupBookingDefinition
                                    ?.maximumGroupParticipants &&
                                item?.__typename === 'GroupBooking' &&
                                item.participantCount <
                                    props.operation.groupBookingDefinition
                                        .maximumGroupParticipants
                        ))
            ),
        [
            props.operation.groupBookingDefinition?.maximumGroupParticipants,
            props.operation.groupBookingDefinition?.viewerGroupBookable
                ?.maximum,
            props.operation.groupBookings?.items,
        ]
    )

    const bookableMax = props.operation?.viewerBookable?.maximum ?? 1
    const fullyBooked = bookableMax === 0 || publicGroupsFullyBooked
    const waitinglist =
        !fullyBooked && props.operation.viewerBookable?.waitinglist
    const multipleBookingOperations =
        bookableMax > 1 && !props.operation.groupBookingDefinition
    const bookings = props.event.viewerParticipant?.bookings ?? []

    const CheckboxOrRadio = props.multiBookable ? Checkbox : Radio

    return (
        <div className={styles.root} id={props.operation.id}>
            <div className={styles.header}>
                <CheckboxOrRadio
                    checked={checked}
                    disabled={formik.isSubmitting || fullyBooked}
                    help={
                        <BookingOperationDescription
                            bookings={bookings}
                            currency={props.event.payment?.currency.name}
                            fullyBooked={publicGroupsFullyBooked}
                            operation={props.operation}
                            timezone={props.event.timezone.name}
                        />
                    }
                    name={props.operation.id}
                    onChange={onInputItemChange}
                >
                    <span className={styles.title}>
                        <span>
                            {props.operation.title}
                            {multipleBookingOperations &&
                                ` (max. ${bookableMax})`}
                            {props.operation.price && props.event.payment ? (
                                <>
                                    <span>, </span>
                                    <FormattedCurrency
                                        amount={props.operation.price}
                                        currency={
                                            props.event.payment.currency.name
                                        }
                                    />
                                </>
                            ) : null}
                        </span>
                        <OnlineBadge online={props.operation.online} />
                        {waitinglist && (
                            <Badge>{t('common:waitinglist')}</Badge>
                        )}
                    </span>
                </CheckboxOrRadio>
                {multipleBookingOperations && (
                    <div className={styles.stepper}>
                        <NumericStepper
                            disabled={!checked || formik.isSubmitting}
                            max={bookableMax}
                            min={1}
                            onChange={onStepperChange}
                            value={bookingOperations?.length ?? 1}
                        />
                    </div>
                )}
            </div>
            {checked &&
                bookingOperations.length > 0 &&
                (props.operation.groupBookingDefinition ||
                    props.operation.inputFields.length > 0) && (
                    <div className={styles.bookingOperations}>
                        {props.operation.groupBookingDefinition ? (
                            <BookingOperationGroupBookings
                                groupBookings={
                                    props.operation.groupBookings?.items
                                }
                                maxParticipants={
                                    props.operation.groupBookingDefinition
                                        .maximumGroupParticipants || undefined
                                }
                                newGroupBookingEnabled={
                                    !!props.operation.groupBookingDefinition
                                        .viewerGroupBookable?.maximum
                                }
                                operationId={props.operation.id}
                            />
                        ) : null}
                        {props.operation.inputFields.length > 0 &&
                            bookingOperations.map((_, i) => (
                                <div key={i} className={styles.inputFields}>
                                    {props.operation.inputFields.map(
                                        (inputField, k) => (
                                            <InputField
                                                key={k}
                                                inputField={inputField}
                                                name={`${props.operation.id}[${i}].inputFieldValues.${inputField.identifier}`}
                                            />
                                        )
                                    )}
                                </div>
                            ))}
                    </div>
                )}
        </div>
    )
}

export default BookingOperations
