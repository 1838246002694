'use client'

import * as yup from 'yup'
import useTranslation from '../useTranslation'

const useYup = () => {
    const { t } = useTranslation()

    yup.setLocale({
        mixed: {
            oneOf: t('common:error.required'),
            required: t('common:error.required'),
        },
        string: {
            email: t('common:error.email'),
            matches: t('common:error.string.matches'),
            min: ({ min }) => `${t('common:error.string.min', { min })}`,
            url: t('common:error.url'),
        },
        number: {
            integer: t('common:error.number.integer'),
            positive: t('common:error.number.positive'),
        },
        array: {
            min: t('common:error.required'),
        },
    })

    return yup
}

export default useYup
