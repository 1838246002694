/* istanbul ignore file */
'use client'

import { FC } from 'react'
import { useRouter, useTranslation } from '../../../../lib/hooks'
import {
    Button,
    ButtonGroup,
    ButtonGroupLayout,
    ButtonTheme,
    Container,
    Divider,
    Text,
} from '../../../base'
import Page from '../page'
import styles from './errorPage.module.css'

interface Props {
    homepageButton?: boolean
    loginButton?: boolean
    retryButton?: boolean
    text?: string
    title: string
}

const ErrorPage: FC<Props> = props => {
    const { t } = useTranslation()
    const { pathname, reload } = useRouter()

    return (
        <Page
            className={styles.root}
            logoHref="/"
            noNavigation
            nonWhiteBackground
        >
            <Container className={styles.container}>
                <div className={styles.content}>
                    <Text element="h1" type="h2">
                        {props.title}
                    </Text>
                    {props.text && (
                        <>
                            <Text>{props.text}</Text>
                            <Divider />
                        </>
                    )}
                    <ButtonGroup layout={ButtonGroupLayout.FullWidth}>
                        {props.loginButton && (
                            <Button
                                href={`/login?from=${encodeURIComponent(pathname)}`}
                                theme={ButtonTheme.Primary}
                            >
                                {t('common:errorPage.toLogin')}
                            </Button>
                        )}
                        {props.homepageButton && (
                            <Button
                                href="/"
                                theme={
                                    props.loginButton
                                        ? ButtonTheme.Secondary
                                        : ButtonTheme.Primary
                                }
                            >
                                {t('common:errorPage.toHomepage')}
                            </Button>
                        )}
                        {(props.retryButton ||
                            (!props.loginButton && !props.homepageButton)) && (
                            <Button
                                onClick={reload}
                                theme={
                                    props.loginButton || props.homepageButton
                                        ? ButtonTheme.Secondary
                                        : ButtonTheme.Primary
                                }
                            >
                                {t('common:errorPage.tryAgain')}
                            </Button>
                        )}
                    </ButtonGroup>
                </div>
            </Container>
        </Page>
    )
}

export default ErrorPage
