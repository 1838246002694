'use client'

import { FC, useCallback } from 'react'
import { AuthenticationMethod } from '../../../lib/graphql/generated/types'
import { useTranslation } from '../../../lib/hooks'
import { Button } from '../../base'
import SsoLoginButton from '../ssoLoginButton'

interface Props {
    authenticationMethod: AuthenticationMethod
    from: string
    setLoginFormMethod: (authenticationMethod: AuthenticationMethod) => void
}

const LoginButton: FC<Props> = props => {
    const { t } = useTranslation()

    const showAccessCodeForm = useCallback(
        () => props.setLoginFormMethod(AuthenticationMethod.AccessCode),
        [props]
    )

    const showUsernamePasswordForm = useCallback(
        () => props.setLoginFormMethod(AuthenticationMethod.UsernamePassword),
        [props]
    )

    switch (props.authenticationMethod) {
        case AuthenticationMethod.AccessCode:
            return (
                <Button onClick={showAccessCodeForm}>
                    {t('login:accessCode.button')}
                </Button>
            )
        case AuthenticationMethod.UsernamePassword:
            return (
                <Button onClick={showUsernamePasswordForm}>
                    {t('login:usernamePassword.button')}
                </Button>
            )
        case AuthenticationMethod.Sso:
            return <SsoLoginButton from={props.from} />
        default:
            return null
    }
}

export default LoginButton
