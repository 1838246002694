import * as cheerio from 'cheerio'

const formatMetaDescription = (description?: string | null) => {
    if (!description) {
        return
    }

    const text = cheerio.load(description).root().text()
    const maxLength = 160

    return text.length > maxLength
        ? text.slice(0, maxLength - 4) + ' ...'
        : text
}

export default formatMetaDescription
