'use client'

import clsx from 'clsx'
import {
    ComponentPropsWithoutRef,
    forwardRef,
    MouseEvent,
    useCallback,
} from 'react'
import { useRouter } from '../../../lib/hooks'
import { Icon, IconProps } from '../icon'
import LoadingSpinner from '../loadingSpinner'
import Text from '../text'
import styles from './button.module.css'

export enum ButtonTheme {
    Alert = 'alert',
    Cancel = 'cancel',
    Confirm = 'confirm',
    Primary = 'primary',
    Secondary = 'secondary',
    Transparent = 'transparent',
}

export interface Props
    extends Pick<
        ComponentPropsWithoutRef<'button'>,
        'children' | 'className' | 'disabled' | 'onClick' | 'type'
    > {
    darkBackground?: boolean
    href?: string
    iconName?: IconProps['name']
    iconSize?: IconProps['size']
    loading?: boolean
    theme?: ButtonTheme
}

// TODO: Use anchor tag when a link instead of click event
const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const router = useRouter()

    const onClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.currentTarget.blur()

            if (props.onClick) {
                props.onClick.call(undefined, e)
            }

            if (props.href?.startsWith('http')) {
                window.location.href = props.href
            } else if (props.href) {
                router.push(props.href)
            }
        },
        [props.href, props.onClick, router]
    )

    return (
        <button
            ref={ref}
            className={clsx(
                styles.root,
                props.theme ? styles[props.theme] : styles.primary,
                props.darkBackground && styles.darkBackground,
                props.loading && styles.loading,
                props.className
            )}
            disabled={props.disabled || props.loading}
            onClick={onClick}
            type={props.type ?? 'button'}
        >
            {props.iconName && (
                <Icon
                    className={styles.icon}
                    name={props.iconName}
                    size={props.iconSize}
                />
            )}
            <Text element="span" type="standard" weight="400" noWrap>
                {props.children}
            </Text>
            {props.loading && props.theme !== ButtonTheme.Transparent && (
                <LoadingSpinner className={styles.spinner} centered inherit />
            )}
        </button>
    )
})

export default Button
