'use client'

import { useCookies } from 'next-client-cookies'
import { FC, useCallback, useState } from 'react'
import { tokenKey } from '../../../../lib/constants'
import { useCreateCancellationMutation } from '../../../../lib/graphql/generated/hooks'
import { useNotification, useTranslation } from '../../../../lib/hooks'
import { Button, ButtonGroup, ButtonTheme, Dialog, Text } from '../../../base'
import styles from './deregisterButton.module.css'

interface Props {
    eventId: string
}

const DeregisterButton: FC<Props> = props => {
    const { t } = useTranslation()
    const notification = useNotification()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)
    const [, createCancellation] = useCreateCancellationMutation()
    const [dialogOpen, setDialogOpen] = useState(false)
    const [submitting, setSubmitting] = useState(false)

    const openDialog = useCallback(() => setDialogOpen(true), [])

    const closeDialog = useCallback(() => setDialogOpen(false), [])

    const optOut = useCallback(async () => {
        setSubmitting(true)

        try {
            const { data, error } = await createCancellation({
                token,
                eventId: props.eventId,
            })

            if (error) {
                throw error
            }

            if (data?.viewer?.event?.createCancellation?.inputError) {
                notification.alert(
                    data.viewer.event.createCancellation.inputError.message
                )
            } else {
                notification.confirm(t('event:deregister.confirmation'))
            }
        } catch (e) {
            console.error(e)
            notification.alert(t('common:error.internal'))
        }

        setSubmitting(false)
        closeDialog()
    }, [closeDialog, createCancellation, notification, props.eventId, t, token])

    return (
        <>
            <Button onClick={openDialog} theme={ButtonTheme.Secondary}>
                {t('event:deregister.title')}
            </Button>
            <Dialog
                onClose={closeDialog}
                open={dialogOpen}
                title={t('event:deregister.title')}
            >
                <Text>{t('event:deregister.prompt')}</Text>
                <ButtonGroup className={styles.actions}>
                    <Button loading={submitting} onClick={optOut}>
                        {t('event:deregister.title')}
                    </Button>
                    <Button
                        disabled={submitting}
                        onClick={closeDialog}
                        theme={ButtonTheme.Cancel}
                    >
                        {t('common:cancel')}
                    </Button>
                </ButtonGroup>
            </Dialog>
        </>
    )
}

export default DeregisterButton
