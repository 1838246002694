'use client'

import { Dayjs } from 'dayjs'
import { FC, useMemo } from 'react'
import { useTranslation } from '../../../lib/hooks'
import { EventQueryEvent } from '../../../lib/types'
import { dayjsFrom } from '../../../lib/utils'
import { Tab, Tabs } from '../../base'
import { PageSection } from '../../common'
import styles from './agenda.module.css'
import AgendaTimeline from './agendaTimeline'

interface Props {
    agenda: NonNullable<EventQueryEvent['agenda']>
    eventKey: string
    timezone?: string
}

const Agenda: FC<Props> = props => {
    const { t } = useTranslation()

    const days = useMemo(() => {
        const _days: Dayjs[] = []

        props.agenda.forEach(item => {
            const startOfDay = dayjsFrom(item.startDate)?.startOf('day')

            if (startOfDay && !_days.some(day => day.isSame(startOfDay))) {
                _days.push(startOfDay)
            }
        })

        return _days
    }, [props.agenda])

    return (
        <PageSection
            contentClassName={styles.root}
            id="agenda"
            title={t('event:agenda')}
        >
            {days.length > 1 ? (
                <Tabs>
                    <Tab label={t('event:dateFilter.allDays')}>
                        <AgendaTimeline
                            agenda={props.agenda}
                            eventKey={props.eventKey}
                            timezone={props.timezone}
                        />
                    </Tab>
                    {days.map(day => {
                        const date = day.tz(props.timezone).format('DD.MM.')

                        return (
                            <Tab key={date} label={date}>
                                <AgendaTimeline
                                    agenda={props.agenda}
                                    day={day}
                                    eventKey={props.eventKey}
                                    timezone={props.timezone}
                                />
                            </Tab>
                        )
                    })}
                </Tabs>
            ) : (
                <AgendaTimeline
                    agenda={props.agenda}
                    eventKey={props.eventKey}
                    timezone={props.timezone}
                />
            )}
        </PageSection>
    )
}

export default Agenda
