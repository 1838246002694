'use client'

import { FC, useCallback, useState } from 'react'
import { authenticateWithSso } from '../../../lib/actions'
import { useNotification, useTranslation } from '../../../lib/hooks'
import { withLocale } from '../../../lib/utils'
import { Button } from '../../base'

interface Props {
    from: string
}

const SsoLoginButton: FC<Props> = props => {
    const { t, locale } = useTranslation()
    const notification = useNotification()
    const [loading, setLoading] = useState(false)

    const onClick = useCallback(async () => {
        setLoading(true)

        const url = new URL(window.location.href)
        url.pathname = withLocale(props.from, locale)
        url.search = ''

        const result = await authenticateWithSso({
            locale,
            successUrl: url.href,
        })

        if (result?.error) {
            notification.alert(result.error)
        }

        if (result?.data) {
            window.location.href = result.data
        }

        setLoading(false)
    }, [locale, notification, props.from])

    return (
        <Button loading={loading} onClick={onClick}>
            {t('login:sso.button')}
        </Button>
    )
}

export default SsoLoginButton
