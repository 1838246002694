/* istanbul ignore file */
import { Dayjs } from 'dayjs'
import { IconName } from '../components/client'
import {
    BookingsQuery,
    EventQuery,
    EventTypeQuery,
    ExternalOnlineEventSetting,
    InternalOnlineEventSetting,
    OnlineEventTiming,
    RegisterQuery,
} from './graphql/generated/types'

export type EventQueryEvent = NonNullable<
    NonNullable<EventQuery['viewer']>['event']
>

export type RegisterQueryEvent = NonNullable<
    NonNullable<RegisterQuery['viewer']>['event']
>

export type BookingsQueryEvent = NonNullable<
    NonNullable<BookingsQuery['viewer']>['event']
>

export type EventType = NonNullable<
    NonNullable<EventTypeQuery['viewer']>['eventType']
>

export type OnlineEventSettings = Array<
    InternalOnlineEventSetting | ExternalOnlineEventSetting
> | null

export type ServerActionResult = {
    data?: any
    error?: any
} | void

export enum RedirectStatus {
    BookingSuccessful = 'bookingSuccessful',
    PaymentCanceled = 'paymentCanceled',
    PaymentFailed = 'paymentFailed',
    PaymentSuccessful = 'paymentSuccessful',
}

export enum AppNotificationType {
    Information,
    Confirmation,
    Alert,
}

export interface AppNotification {
    id: number
    message: string
    modal?: boolean
    modalButtonText?: string
    modalIconName?: IconName
    onClosed?: () => void
    type: AppNotificationType
}

export enum Breakpoint {
    LgDown = '(max-width: 1023px)',
    LgUp = '(min-width: 1024px)',
    MdDown = '(max-width: 767px)',
    MdUp = '(min-width: 768px)',
    SmDown = '(max-width: 479px)',
    SmUp = '(min-width: 480px)',
    UlDown = '(max-width: 1439px)',
    UlUp = '(min-width: 1440px)',
    XlDown = '(max-width: 1279px)',
    XlUp = '(min-width: 1280px)',
}
export interface OnlineEvent {
    description?: string
    endDate: Dayjs
    external: boolean
    fileExtension?: string
    id: string
    live?: boolean
    startDate: Dayjs
    timing: OnlineEventTiming
    title: string
    url: string
}
