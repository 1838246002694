/* istanbul ignore file */
'use client'

import { Form, Formik } from 'formik'
import { FC } from 'react'
import { Card, Checkbox, DateFilter } from '../client'
import styles from './examples.module.css'
import useOnSubmit from './useOnSubmit'

const DateFilterExample: FC = () => {
    const { onSubmitDummy } = useOnSubmit()

    return (
        <Card title="DateFilter">
            <Formik
                initialValues={{
                    from: undefined,
                    to: undefined,
                    range: false,
                }}
                onSubmit={onSubmitDummy}
            >
                {formik => (
                    <Form className={styles.flexCol} noValidate>
                        <DateFilter
                            from={formik.values.from}
                            onChange={(from, to) => {
                                Promise.all([
                                    formik.setFieldValue('from', from),
                                    formik.setFieldValue('to', to),
                                ]).catch(console.error)
                            }}
                            range={formik.values.range}
                            to={formik.values.to}
                        />
                        <Checkbox
                            name="range"
                            onChange={e => {
                                Promise.all([
                                    formik.setFieldValue('from', undefined),
                                    formik.setFieldValue('to', undefined),
                                ]).catch(console.error)

                                return formik.handleChange(e)
                            }}
                        >
                            Range
                        </Checkbox>
                    </Form>
                )}
            </Formik>
        </Card>
    )
}

export default DateFilterExample
