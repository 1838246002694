'use client'

import clsx from 'clsx'
import {
    FC,
    MouseEvent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
} from 'react'
import Portal from '../portal'
import styles from './flyout.module.css'

interface Props {
    children: ReactNode | ((close: () => void) => ReactNode)
    className?: string
    list?: boolean
    openOnHover?: boolean
    toggle: ReactNode
    toggleClassName?: string
}

const Flyout: FC<Props> = props => {
    const toggle = useRef<HTMLDivElement>(null)
    const content = useRef<HTMLDivElement>(null)
    const closeTimeout = useRef<NodeJS.Timeout>()

    const positionVertically = useCallback(() => {
        /* istanbul ignore next */
        if (!toggle.current || !content.current) {
            return
        }

        const toggleRect = toggle.current.getBoundingClientRect()
        const contentRect = content.current.getBoundingClientRect()
        const viewportHeight = document.documentElement.clientHeight

        const hasBottomSpace =
            toggleRect.bottom + contentRect.height <= viewportHeight
        const hasTopSpace = toggleRect.top - contentRect.height >= 0

        if (hasBottomSpace || !hasTopSpace) {
            content.current.style.top = `${
                window.scrollY + toggleRect.bottom
            }px`

            content.current.classList.add(styles.fromTop)
        } else {
            content.current.style.top = `${
                window.scrollY + toggleRect.top - contentRect.height
            }px`

            content.current.classList.add(styles.fromBottom)
        }
    }, [])

    const positionHorizontally = useCallback(() => {
        /* istanbul ignore next */
        if (!toggle.current || !content.current) {
            return
        }

        const toggleRect = toggle.current.getBoundingClientRect()
        const contentRect = content.current.getBoundingClientRect()
        const viewportWidth = document.documentElement.clientWidth
        let arrowOffset = toggleRect.width / 2 - 24 - 7

        const hasRightSpace =
            toggleRect.left + arrowOffset + contentRect.width <= viewportWidth
        const hasLeftSpace =
            toggleRect.right - arrowOffset - contentRect.width >= 0

        if (!hasRightSpace && !hasLeftSpace) {
            const left = viewportWidth / 2 - contentRect.width / 2
            content.current.style.left = `${left}px`

            arrowOffset = toggleRect.width / 2 - 7

            content.current.style.setProperty(
                '--arrowLeft',
                `${toggleRect.left - left + arrowOffset}px`
            )
        } else if (hasRightSpace || !hasLeftSpace) {
            content.current.style.left = `${toggleRect.left + arrowOffset}px`
            content.current.style.setProperty('--arrowLeft', '24px')
        } else {
            content.current.style.left = `${
                toggleRect.right - arrowOffset - contentRect.width
            }px`
            content.current.style.setProperty('--arrowRight', '24px')
        }
    }, [])

    const resetPosition = useCallback(() => {
        /* istanbul ignore next */
        if (!content.current) {
            return
        }

        content.current.style.top = '-999px'
        content.current.style.left = '-999px'

        content.current.classList.remove(styles.fromTop, styles.fromBottom)
        content.current.style.setProperty('--arrowLeft', 'auto')
        content.current.style.setProperty('--arrowRight', 'auto')
    }, [])

    const open = useCallback(() => {
        if (
            !content.current ||
            content.current.classList.contains(styles.visible)
        ) {
            return
        }

        positionHorizontally()
        positionVertically()

        content.current.classList.add(styles.visible)
    }, [positionHorizontally, positionVertically])

    const close = useCallback(() => {
        if (!content.current?.classList.contains(styles.visible)) {
            return
        }

        content.current.classList.remove(styles.visible)

        setTimeout(resetPosition, 300)
    }, [resetPosition])

    useEffect(() => {
        window.addEventListener('click', close)
        window.addEventListener('resize', close)

        return () => {
            window.removeEventListener('click', close)
            window.removeEventListener('resize', close)
        }
    }, [close])

    const onToggleClick = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation()

            if (content.current?.classList.contains(styles.visible)) {
                close()
            } else {
                open()
            }
        },
        [close, open]
    )

    const onToggleMouseOver = useCallback(() => {
        if (props.openOnHover) {
            open()
        }
    }, [open, props.openOnHover])

    const onMouseEnter = useCallback(() => {
        if (closeTimeout.current) {
            clearTimeout(closeTimeout.current)
            closeTimeout.current = undefined
        }
    }, [])

    const onMouseLeave = useCallback(() => {
        if (props.openOnHover) {
            closeTimeout.current = setTimeout(close, 300)
        }
    }, [close, props.openOnHover])

    const onContentClick = useCallback(
        (e: MouseEvent) => {
            if (props.list) {
                close()
            } else {
                e.stopPropagation()
            }
        },
        [close, props.list]
    )

    return (
        <div
            className={props.className}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div
                ref={toggle}
                className={clsx(
                    styles.toggle,
                    props.openOnHover && styles.openOnHover,
                    props.toggleClassName
                )}
                onClick={onToggleClick}
                onMouseOver={onToggleMouseOver}
            >
                {props.toggle}
            </div>
            <Portal>
                <div
                    ref={content}
                    className={clsx(styles.content, props.list && styles.list)}
                    onClick={onContentClick}
                >
                    {typeof props.children === 'function'
                        ? props.children(close)
                        : props.children}
                </div>
            </Portal>
        </div>
    )
}

export default Flyout
