/* istanbul ignore file */
'use client'

import { FC, useCallback, useState } from 'react'
import {
    Button,
    ButtonGroup,
    ButtonGroupLayout,
    ButtonTheme,
    Card,
    ProgressBar,
} from '../client'

const ProgressBarExample: FC = () => {
    const [step, setStep] = useState(1)

    const steps = [
        'Step 1',
        'Step 2',
        'Step 3',
        'Step 4',
        'Step 5',
        'Step 6',
        'Step 7',
    ]

    const nextStep = useCallback(
        () => setStep(_step => (_step < steps.length ? _step + 1 : _step)),
        [steps.length]
    )

    const previousStep = useCallback(
        () => setStep(_step => (_step > 1 ? _step - 1 : _step)),
        []
    )

    return (
        <Card title="ProgressBar">
            <ProgressBar onClick={setStep} step={step} steps={steps} />
            <ButtonGroup layout={ButtonGroupLayout.Fill}>
                <Button disabled={step === steps.length} onClick={nextStep}>
                    Next
                </Button>
                <Button
                    disabled={step === 1}
                    onClick={previousStep}
                    theme={ButtonTheme.Secondary}
                >
                    Previous
                </Button>
            </ButtonGroup>
        </Card>
    )
}

export default ProgressBarExample
