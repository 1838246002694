/* istanbul ignore file */
'use client'

import { FC, ReactNode } from 'react'

export interface Props {
    children?: ReactNode
    label: string
}

const Tab: FC<Props> = props => <>{props.children}</>

export default Tab
