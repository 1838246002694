'use client'

import { FC } from 'react'
import { InputFragment } from '../../../../lib/graphql/generated/types'
import {
    Checkbox,
    FileUpload,
    ImageUpload,
    Input,
    InputGroup,
    Radio,
    Select,
    SelectOption,
    Text,
    Textarea,
} from '../../../base'
import styles from './inputField.module.css'

interface Props {
    inputField: InputFragment
    name?: string
}

const InputField: FC<Props> = props => {
    const name = props.name ?? props.inputField.identifier
    const label = props.inputField.label

    switch (props.inputField.__typename) {
        case 'StaticTextField':
            return (
                <>
                    {props.inputField.label && (
                        <Text className={styles.label} element="h2" type="h3">
                            {props.inputField.label}
                        </Text>
                    )}
                    {props.inputField.text && (
                        <Text>{props.inputField.text}</Text>
                    )}
                </>
            )
        case 'SingleLineTextField':
            return (
                <Input
                    label={label}
                    name={name}
                    placeholder={props.inputField.placeholder}
                    required={props.inputField.required}
                />
            )
        case 'MultiLineTextField':
            return (
                <Textarea
                    label={label}
                    name={name}
                    placeholder={props.inputField.placeholder}
                    required={props.inputField.required}
                />
            )
        case 'SingleSelectRadiobuttonsField':
            return (
                <InputGroup
                    label={label}
                    name={name}
                    required={props.inputField.required}
                >
                    {props.inputField.options.map((option, i) => (
                        <Radio key={i} name={name} value={option.value}>
                            {option.label}
                        </Radio>
                    ))}
                </InputGroup>
            )
        case 'CheckboxField':
            return (
                <Checkbox name={name} required={props.inputField.required}>
                    {label}
                </Checkbox>
            )
        case 'MultiSelectCheckboxesField':
            return props.inputField.options.length > 5 ? (
                <Select
                    label={label}
                    name={name}
                    required={props.inputField.required}
                >
                    {props.inputField.options.map((option, i) => (
                        <SelectOption key={i} value={option.value}>
                            {option.label}
                        </SelectOption>
                    ))}
                </Select>
            ) : (
                <InputGroup
                    label={label}
                    name={name}
                    required={props.inputField.required}
                >
                    {props.inputField.options.map((option, i) => (
                        <Checkbox key={i} name={name} value={option.value}>
                            {option.label}
                        </Checkbox>
                    ))}
                </InputGroup>
            )
        case 'SingleSelectDropdownField':
            return (
                <Select
                    label={label}
                    name={name}
                    required={props.inputField.required}
                >
                    {props.inputField.options.map((option, i) => (
                        <SelectOption key={i} value={option.value}>
                            {option.label}
                        </SelectOption>
                    ))}
                </Select>
            )
        case 'DatePickerField':
            return (
                <Input
                    label={label}
                    name={name}
                    required={props.inputField.required}
                    type="date"
                />
            )
        case 'FileUploadField':
            return (
                <FileUpload
                    label={label}
                    name={name}
                    required={props.inputField.required}
                />
            )
        case 'ImageUploadField':
            return (
                <ImageUpload
                    label={label}
                    name={name}
                    required={props.inputField.required}
                />
            )
        default:
            return (
                <Text>
                    Unhandled input field type: {props.inputField.__typename}
                </Text>
            )
    }
}

export default InputField
