'use client'

import clsx from 'clsx'
import { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { AppNotificationType } from '../../../../lib/types'
import { Button, ButtonTheme } from '../../button'
import styles from './headerNotification.module.css'

interface Props {
    children: ReactNode
    id: number
    onClosed: (id: number) => void
    type: AppNotificationType
}

const HeaderNotification: FC<Props> = props => {
    const [closed, setClosed] = useState(false)
    const { id, onClosed } = props

    const closeNotification = useCallback(() => {
        setClosed(true)
        setTimeout(() => onClosed(id), 400)
    }, [id, onClosed])

    useEffect(() => {
        const timeoutId = setTimeout(closeNotification, 5000)
        return () => clearTimeout(timeoutId)
    }, [id, closeNotification])

    return (
        <div
            className={clsx(
                styles.root,
                props.type === AppNotificationType.Confirmation &&
                    styles.confirmation,
                props.type === AppNotificationType.Alert && styles.alert,
                closed && styles.closed
            )}
        >
            <div className={styles.content}>
                <div className={styles.body}>{props.children}</div>
                <Button
                    iconName="x"
                    iconSize={3}
                    onClick={closeNotification}
                    theme={ButtonTheme.Transparent}
                    darkBackground
                />
            </div>
        </div>
    )
}

export default HeaderNotification
