/* istanbul ignore file */
'use client'

import { FC, useCallback, useState } from 'react'
import {
    AuthenticationMethod,
    LoginEventTypesQuery,
} from '../../lib/graphql/generated/types'
import { useTranslation } from '../../lib/hooks'
import { ButtonGroup, ButtonGroupLayout, Text } from '../base'
import LoginButton from './loginButton'
import LoginForm from './loginForm'

interface Props {
    eventId?: string
    from: string
    loginEventTypes?: NonNullable<
        NonNullable<LoginEventTypesQuery>['viewer']
    >['eventTypes']
    userRegistration?: boolean
    visibleMethods: AuthenticationMethod[]
}

const Login: FC<Props> = props => {
    const { t } = useTranslation()

    const [loginFormMethod, setLoginFormMethod] = useState<
        AuthenticationMethod | undefined
    >(
        // Show login form right away if only one non-SSO authentication method
        props.visibleMethods.length === 1 &&
            props.visibleMethods[0] !== AuthenticationMethod.Sso
            ? props.visibleMethods[0]
            : undefined
    )

    const cancelLogin = useCallback(() => setLoginFormMethod(undefined), [])

    return (
        <>
            {loginFormMethod && (
                <LoginForm
                    authenticationMethod={loginFormMethod}
                    eventId={props.eventId}
                    from={props.from}
                    onCancel={cancelLogin}
                    userRegistration={props.userRegistration}
                    visibleMethods={props.visibleMethods}
                />
            )}
            {!props.visibleMethods.length && (
                <Text>{t('login:noAuthenticationMethods')}</Text>
            )}
            {!loginFormMethod && (
                <ButtonGroup layout={ButtonGroupLayout.FullWidth}>
                    {props.visibleMethods.map(method => (
                        <LoginButton
                            key={method}
                            authenticationMethod={method}
                            from={props.from}
                            setLoginFormMethod={setLoginFormMethod}
                        />
                    ))}
                </ButtonGroup>
            )}
        </>
    )
}

export default Login
