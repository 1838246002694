/* istanbul ignore file */
'use client'

import { FC, useCallback, useState } from 'react'
import { Button, Card, SidePanel, Text } from '../client'
import styles from './examples.module.css'

const SidePanelExample: FC = () => {
    const [leftSidePanelOpen, setLeftSidePanelOpen] = useState(false)
    const [rightSidePanelOpen, setRightSidePanelOpen] = useState(false)

    const openLeftSidePanel = useCallback(() => setLeftSidePanelOpen(true), [])

    const closeLeftSidePanel = useCallback(
        () => setLeftSidePanelOpen(false),
        []
    )

    const openRightSidePanel = useCallback(
        () => setRightSidePanelOpen(true),
        []
    )

    const closeRightSidePanel = useCallback(
        () => setRightSidePanelOpen(false),
        []
    )

    return (
        <Card contentClassName={styles.flexCol} title="SidePanel">
            <Button onClick={openLeftSidePanel}>Open from left</Button>
            <Button onClick={openRightSidePanel}>Open from right</Button>
            <SidePanel
                className={styles.sidePanel}
                onClose={closeLeftSidePanel}
                open={leftSidePanelOpen}
            >
                <Text noMargin noWrap>
                    Left side panel
                </Text>
            </SidePanel>
            <SidePanel
                className={styles.sidePanel}
                onClose={closeRightSidePanel}
                open={rightSidePanelOpen}
                right
            >
                <Text noMargin noWrap>
                    Right side panel
                </Text>
            </SidePanel>
        </Card>
    )
}

export default SidePanelExample
