'use client'

import { Form, Formik } from 'formik'
import { FC, useCallback } from 'react'
import { authenticateWithAccessCode } from '../../../lib/actions'
import { useNotification, useTranslation, useYup } from '../../../lib/hooks'
import { withLocale } from '../../../lib/utils'
import {
    Button,
    ButtonGroup,
    ButtonGroupLayout,
    ButtonTheme,
    Input,
} from '../../base'
import styles from '../login.module.css'

interface Values {
    accessCode: string
}

interface Props {
    eventId: string
    from: string
    onCancel?: () => void
}

const AccessCodeForm: FC<Props> = props => {
    const { t, locale } = useTranslation()
    const notification = useNotification()
    const yup = useYup()

    const onSubmit = useCallback(
        async (values: Values) => {
            const result = await authenticateWithAccessCode({
                eventId: props.eventId,
                locale,
                ...values,
            })

            if (result?.error) {
                notification.alert(result.error)
            } else {
                const url = new URL(window.location.href)
                url.pathname = withLocale(props.from, locale)
                url.search = ''

                window.location.href = url.href
            }
        },
        [locale, notification, props.eventId, props.from]
    )

    return (
        <Formik<Values>
            initialValues={{ accessCode: '' }}
            onSubmit={onSubmit}
            validationSchema={yup.object().shape({
                accessCode: yup.string().required(),
            })}
        >
            {formik => (
                <Form noValidate>
                    <div className={styles.fields}>
                        <Input
                            label={t('login:accessCode.label')}
                            name="accessCode"
                            required
                        />
                    </div>
                    <ButtonGroup layout={ButtonGroupLayout.FullWidth}>
                        <Button loading={formik.isSubmitting} type="submit">
                            {t('common:logIn')}
                        </Button>
                        {props.onCancel && (
                            <Button
                                disabled={formik.isSubmitting}
                                onClick={props.onCancel}
                                theme={ButtonTheme.Secondary}
                            >
                                {t('common:cancel')}
                            </Button>
                        )}
                    </ButtonGroup>
                </Form>
            )}
        </Formik>
    )
}

export default AccessCodeForm
