'use client'

import { FC } from 'react'
import { LoginEventTypesQuery } from '../../../lib/graphql/generated/types'
import { Container, Link, Text } from '../../base'
import styles from './loginLinks.module.css'

interface Props {
    loginEventTypes:
        | NonNullable<LoginEventTypesQuery['viewer']>['eventTypes']
        | null
}

const LoginLinks: FC<Props> = ({ loginEventTypes }) => {
    if (!loginEventTypes?.length) {
        return null
    }

    return (
        <Container className={styles.root}>
            {loginEventTypes.map(
                eventType =>
                    eventType.url && (
                        <Link
                            key={eventType.id}
                            className={styles.link}
                            href={eventType.url}
                            locale={false}
                            noUnderline
                        >
                            {eventType.image?.thumbnail?.resource && (
                                <img
                                    alt={eventType.name}
                                    className={styles.image}
                                    src={eventType.image.thumbnail.resource}
                                />
                            )}
                            <div className={styles.content}>
                                <Text element="h4" type="h4" noMargin noWrap>
                                    {eventType.name}
                                </Text>
                            </div>
                        </Link>
                    )
            )}
        </Container>
    )
}

export default LoginLinks
