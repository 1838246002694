/* istanbul ignore file */
'use client'

import dayjs from 'dayjs'
import { FC } from 'react'
import { Comment } from './comment'

interface Props {
    eventId: string
}

const MockComments: FC<Props> = props => {
    const now = dayjs()

    return (
        <>
            <Comment
                comment={{
                    id: '1',
                    firstName: 'Hans',
                    lastName: 'Peter',
                    text:
                        'Maecenas posuere euismod maximus. Maecenas ut ' +
                        'semper enim.',
                    deletable: false,
                    creationDate: now,
                }}
                eventId={props.eventId}
            />
            <Comment
                comment={{
                    id: '2',
                    firstName: 'Ueli',
                    lastName: 'Meier',
                    text:
                        'Duis eget augue eu metus condimentum consectetur ' +
                        'nec sed metus. Mauris ultrices velit sit amet leo ' +
                        'dictum placerat. In neque tortor, molestie vitae ' +
                        'aliquam non, ullamcorper vitae ipsum.',
                    deletable: false,
                    creationDate: now.subtract(55, 'seconds'),
                }}
                eventId={props.eventId}
            />
            <Comment
                comment={{
                    id: '3',
                    firstName: 'Tom',
                    lastName: 'Anderson',
                    text: 'Sed sit amet pellentesque augue, eu cursus ligula.',
                    deletable: false,
                    creationDate: now.subtract(3, 'minutes'),
                }}
                eventId={props.eventId}
            />
            <Comment
                comment={{
                    id: '4',
                    firstName: 'Giovanni',
                    lastName: 'Pronto',
                    text:
                        'Vestibulum ante ipsum primis in faucibus orci ' +
                        'luctus et ultrices posuere cubilia curae; Proin ' +
                        'nec aliquet nulla. Maecenas viverra tincidunt elit, ' +
                        'blandit ultrices neque commodo sed.',
                    deletable: false,
                    creationDate: now.subtract(5, 'hours'),
                }}
                eventId={props.eventId}
            />
            <Comment
                comment={{
                    id: '5',
                    firstName: 'Jean',
                    lastName: 'Gérard',
                    text:
                        'Ut sit amet eros nulla. Quisque eu nisl lacinia, ' +
                        'finibus lacus in, posuere magna. Sed dignissim ' +
                        'tincidunt augue in vehicula.',
                    deletable: false,
                    creationDate: now.subtract(2, 'days'),
                }}
                eventId={props.eventId}
            />
        </>
    )
}

export default MockComments
