/* istanbul ignore file */
import { FC } from 'react'
import { Card, Icon } from '../base'
import styles from './examples.module.css'

const IconExample: FC = () => (
    <Card contentClassName={styles.icons} title="Icon">
        <Icon name="cat" size={1} />
        <Icon className={styles.navy} name="cat" size={2} />
        <Icon className={styles.red} name="cat" size={3} />
        <Icon className={styles.turquoise} name="cat" size={4} />
        <Icon className={styles.iris} name="cat" size={5} />
        <Icon className={styles.pink} name="cat" size={6} />
    </Card>
)

export default IconExample
