/* istanbul ignore file */
'use client'

import { FC, useCallback, useState } from 'react'
import { BottomBar, Button, Card, Text } from '../client'
import styles from './examples.module.css'

const BottomBarExample: FC = () => {
    const [open, setOpen] = useState(false)

    const toggle = useCallback(() => setOpen(prev => !prev), [])

    return (
        <Card contentClassName={styles.flexCol} title="BottomBar">
            <Button onClick={toggle}>Toggle</Button>
            <BottomBar className={styles.bottomBar} open={open}>
                <Text noMargin noWrap>
                    Bottom bar
                </Text>
            </BottomBar>
        </Card>
    )
}

export default BottomBarExample
