/* istanbul ignore file */
'use client'

import clsx from 'clsx'
import { Form, Formik } from 'formik'
import { ChangeEvent, FC, useCallback, useState } from 'react'
import { Layout } from '../../lib/graphql/generated/types'
import { InputGroup, Masthead, Radio } from '../client'
import styles from './examples.module.css'
import useOnSubmit from './useOnSubmit'

const MastheadExample: FC = () => {
    const { onSubmitDummy } = useOnSubmit()
    const [layout, setLayout] = useState(Layout.Default)

    const onLayoutChange = useCallback((e: ChangeEvent<HTMLFormElement>) => {
        setLayout(e.target.value)
    }, [])

    return (
        <Masthead
            content="Content"
            header={
                <Formik
                    initialValues={{
                        layout: Layout.Default,
                    }}
                    onSubmit={onSubmitDummy}
                >
                    <Form
                        className={styles.flexWrap}
                        onChange={onLayoutChange}
                        noValidate
                    >
                        <InputGroup name="layout" inline>
                            <Radio name="layout" value={Layout.Default}>
                                Default
                            </Radio>
                            <Radio name="layout" value={Layout.Wide}>
                                Wide
                            </Radio>
                        </InputGroup>
                    </Form>
                </Formik>
            }
            layout={layout}
            media={<img alt="Media" src="/example.jpg" />}
            mediaClassName={clsx(
                styles.mastheadMedia,
                layout === Layout.Default && styles.default
            )}
            title="Masthead"
        />
    )
}

export default MastheadExample
