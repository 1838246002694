'use client'

import { useCookies } from 'next-client-cookies'
import { tokenKey } from '../../constants'
import { useViewerUserQuery } from '../../graphql/generated/hooks'
import useTranslation from '../useTranslation'

/**
 * The returned viewer user is <code>undefined</code> until loaded and
 * <code>null</code> if not authenticated.
 */
const useViewerUser = () => {
    const { locale } = useTranslation()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)

    const [viewerUserQuery] = useViewerUserQuery({
        variables: {
            token,
            locale,
        },
    })

    return viewerUserQuery.data?.viewer?.viewerUser
}

export default useViewerUser
