'use client'

import clsx from 'clsx'
import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import { CategoriesAndEventTypesQuery } from '../../../lib/graphql/generated/types'
import { useTranslation } from '../../../lib/hooks'
import { Button, Container, Text } from '../../base'
import styles from './categoriesSnippet.module.css'

interface Props {
    categories: NonNullable<
        CategoriesAndEventTypesQuery['viewer']
    >['categories']
}

const CategoriesSnippet: FC<Props> = props => {
    const { t } = useTranslation()

    if (!props.categories) {
        return (
            <Container className={styles.root}>
                <Skeleton borderRadius={3} height={300} />
                <Skeleton borderRadius={3} height={300} />
                <Skeleton borderRadius={3} height={300} />
            </Container>
        )
    }

    const categories = props.categories.slice(
        0,
        Math.min(props.categories.length, 3)
    )

    return (
        <Container className={styles.root}>
            {categories.map(category => {
                const image = category.image?.thumbnail?.resource

                return (
                    <div
                        key={category.id}
                        className={clsx(styles.category, image && styles.image)}
                        style={{
                            backgroundImage: image
                                ? `url(${image})`
                                : undefined,
                        }}
                    >
                        <Text element="h3" type="h4">
                            {category.name}
                        </Text>
                        <Button href={`/?category=${category.name}`}>
                            {t('home:showAll')}
                        </Button>
                    </div>
                )
            })}
        </Container>
    )
}

export default CategoriesSnippet
