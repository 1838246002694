import { Venue } from '../../graphql/generated/types'

const venueGoogleMapsUrl = (
    venue: Pick<Venue, 'name' | 'latitude' | 'longitude'>
): string => {
    const baseUrl = 'https://www.google.com/maps/search/?api=1&query='

    const query =
        venue.latitude && venue.longitude
            ? `${venue.latitude},${venue.longitude}`
            : encodeURIComponent(venue.name)

    return baseUrl + query
}

export default venueGoogleMapsUrl
