'use client'

import { FC } from 'react'
import { useTranslation } from '../../../../lib/hooks'
import { EventQueryEvent } from '../../../../lib/types'
import { Text } from '../../../base'
import OrganizerOrganization from './organizerOrganization'
import styles from './organizers.module.css'
import OrganizerUser from './organizerUser'

export interface Props {
    eventId: string
    organizations: EventQueryEvent['organizerOrganizations']
    users: EventQueryEvent['organizerUsers']
    viewerParticipant: EventQueryEvent['viewerParticipant']
}

const Organizers: FC<Props> = props => {
    const { t } = useTranslation()

    if (!props.users?.length && !props.organizations?.length) {
        return
    }

    return (
        <div className={styles.root}>
            <Text className={styles.title} element="h3" weight="600" noMargin>
                {t('event:information.organizers')}
            </Text>
            {props.users?.map(organizer => (
                <OrganizerUser
                    key={organizer.id}
                    eventId={props.eventId}
                    organizer={organizer}
                    viewerParticipant={props.viewerParticipant}
                />
            ))}
            {props.organizations?.map(organizer => (
                <OrganizerOrganization
                    key={organizer.id}
                    eventId={props.eventId}
                    organizer={organizer}
                    viewerParticipant={props.viewerParticipant}
                />
            ))}
        </div>
    )
}

export default Organizers
