'use client'

import { FC, useCallback, useMemo, useState } from 'react'
import { useRouter, useTranslation } from '../../../../lib/hooks'
import { selectOnlineEvents } from '../../../../lib/selectors'
import { BookingsQueryEvent, OnlineEvent } from '../../../../lib/types'
import { Button, Dialog } from '../../../base'
import styles from './onlineEventButton.module.css'
import OnlineEventItem from './onlineEventItem'

interface Props {
    event: BookingsQueryEvent
}

const OnlineEventButton: FC<Props> = props => {
    const { t } = useTranslation()
    const router = useRouter()
    const [dialogOpen, setDialogOpen] = useState(false)

    const onlineEvents = useMemo(
        () => selectOnlineEvents(props.event),
        [props.event]
    )

    const live = onlineEvents.some(onlineEvent => onlineEvent.live)

    const openDialog = useCallback(() => setDialogOpen(true), [])

    const closeDialog = useCallback(() => setDialogOpen(false), [])

    const onOnlineEventClick = useCallback(
        (onlineEvent: OnlineEvent) => {
            closeDialog()

            if (onlineEvent.external) {
                window.open(onlineEvent.url, '_blank')
            } else {
                const params = new URLSearchParams(router.searchParams)
                params.set('onlineEventId', onlineEvent.id)

                router.replaceSearchParams(params)
                window.scrollTo({ top: 0, behavior: 'smooth' })
            }
        },
        [closeDialog, router]
    )

    if (!onlineEvents.length) {
        return
    }

    return (
        <>
            <Button
                iconName={live ? 'video-camera' : 'globe'}
                onClick={openDialog}
            >
                {t('event:onlineEvent.button')}
            </Button>
            <Dialog
                className={styles.dialog}
                onClose={closeDialog}
                open={dialogOpen}
                title={t('event:onlineEvent.button')}
                small
            >
                {onlineEvents.map(onlineEvent => (
                    <OnlineEventItem
                        key={onlineEvent.id}
                        onClick={onOnlineEventClick}
                        onlineEvent={onlineEvent}
                    />
                ))}
            </Dialog>
        </>
    )
}

export default OnlineEventButton
