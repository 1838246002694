'use client'

import { FC, ReactNode } from 'react'
import { Button, ButtonTheme, Text } from '../../../../base'
import styles from './reviewSection.module.css'

interface Props {
    children?: ReactNode
    onEdit?: () => void
    title: string
}

const ReviewSection: FC<Props> = props => (
    <div>
        {props.onEdit && (
            <Button
                className={styles.editButton}
                iconName="pencil"
                iconSize={2}
                onClick={props.onEdit}
                theme={ButtonTheme.Transparent}
            />
        )}
        <Text className={styles.title} element="h2" type="h3">
            {props.title}
        </Text>
        {props.children}
    </div>
)

export default ReviewSection
