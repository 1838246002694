'use client'

import clsx from 'clsx'
import { useField, useFormikContext } from 'formik'
import {
    Children,
    FC,
    ReactElement,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import ReactSelect from 'react-select'
import ReactCreatableSelect from 'react-select/creatable'
import { useId, useTranslation } from '../../../../lib/hooks'
import inputStyles from '../input/input.module.css'
import InputError from '../inputError'
import InputHelp from '../inputHelp'
import InputLabel from '../inputLabel'
import styles from './select.module.css'
import { Props as SelectOptionProps } from './selectOption'

interface Option {
    isDisabled?: boolean
    label: string
    value: string
}

type Selection = Option | readonly Option[] | null

export interface Props {
    children?:
        | ReactElement<SelectOptionProps>
        | ReactElement<SelectOptionProps>[]
    className?: string
    creatable?: boolean
    disabled?: boolean
    help?: ReactNode
    label?: string
    loading?: boolean
    name: string
    onInputChange?: (value: string) => void
    placeholder?: string
    required?: boolean
}

const Select: FC<Props> = props => {
    const { t } = useTranslation()
    const [field, meta, helpers] = useField(props.name)
    const { isSubmitting } = useFormikContext()
    const instanceId = useId()
    const root = useRef<HTMLDivElement>(null)
    const [menuOpen, setMenuOpen] = useState(false)
    const [createdOptions, setCreatedOptions] = useState<Option[]>([])

    const options = useMemo(
        () =>
            Children.map(props.children, child => ({
                value: child?.props.value ?? child?.props.children,
                label: child?.props.children,
                isDisabled: child?.props.disabled,
            }))?.concat(
                createdOptions.map(option => ({
                    ...option,
                    isDisabled: false,
                }))
            ),
        [createdOptions, props.children]
    ) as Option[] | undefined

    const selection: Selection = useMemo(() => {
        if (!options) {
            return null
        }

        if (Array.isArray(field.value)) {
            const _selection = []

            for (const value of field.value) {
                const option = options.find(o => o.value === value)

                if (option) {
                    _selection.push(option)
                }
            }

            return _selection
        } else if (field.value) {
            const option = options.find(o => o.value === field.value)

            if (option) {
                return option
            }
        }

        return null
    }, [field.value, options])

    const loadingMessage = useCallback(() => t('common:loading'), [t])

    const noOptionsMessage = useCallback(() => t('common:noOptions'), [t])

    const formatCreateLabel = useCallback(
        (value: string) => t('common:addOption', { option: value }),
        [t]
    )

    const onMenuOpen = useCallback(() => setMenuOpen(true), [])

    useEffect(() => {
        if (!menuOpen || !root.current) {
            return
        }

        const control =
            root.current.querySelector<HTMLElement>('.select__control')
        const menu = root.current.querySelector<HTMLElement>('.select__menu')

        if (!control || !menu) {
            return
        }

        control.classList.add(styles.open)
        menu.classList.add(styles.open)
    }, [menuOpen])

    const onMenuClose = useCallback(() => {
        if (!root.current) {
            return
        }

        const control =
            root.current.querySelector<HTMLElement>('.select__control')
        const menu = root.current.querySelector<HTMLElement>('.select__menu')

        if (!control || !menu) {
            return
        }

        // Prevents class manipulation conflict with react-select
        requestAnimationFrame(() => {
            control.classList.remove(styles.open)
            menu.classList.remove(styles.open)
        })

        setTimeout(() => setMenuOpen(false), 200)
    }, [])

    const onChange = useCallback(
        (_selection: Selection) => {
            const value =
                _selection instanceof Array
                    ? _selection.map(option => option.value)
                    : (_selection?.value ?? '')

            helpers.setValue(value)
            helpers.setTouched(true, false)
        },
        [helpers]
    )

    const onCreateOption = useCallback(
        (value: string) => {
            setCreatedOptions(prev => [...prev, { label: value, value }])

            helpers.setValue(
                Array.isArray(field.value) ? [...field.value, value] : value
            )

            helpers.setTouched(true, false)
        },
        [field.value, helpers]
    )

    const reactSelectProps = {
        className: clsx(
            styles.select,
            meta.touched && meta.error && styles.invalid
        ),
        classNamePrefix: 'select',
        instanceId,
        isDisabled: props.disabled || isSubmitting,
        isLoading: props.loading,
        isMulti: Array.isArray(field.value),
        loadingMessage,
        menuIsOpen: menuOpen,
        name: props.name,
        noOptionsMessage,
        onBlur: field.onBlur,
        onChange,
        onInputChange: props.onInputChange,
        onMenuClose,
        onMenuOpen,
        options,
        placeholder: props.placeholder?.length
            ? props.placeholder
            : t('common:selectPlaceholder'),
        value: selection,
        isClearable: true,
    }

    return (
        <div ref={root} className={clsx(inputStyles.root, props.className)}>
            <label className={inputStyles.label}>
                {props.label && (
                    <InputLabel>
                        {props.label}
                        {props.required && <span> *</span>}
                    </InputLabel>
                )}
                {props.creatable ? (
                    <ReactCreatableSelect<Option, boolean>
                        {...reactSelectProps}
                        formatCreateLabel={formatCreateLabel}
                        onCreateOption={onCreateOption}
                    />
                ) : (
                    <ReactSelect<Option, boolean> {...reactSelectProps} />
                )}
            </label>
            {props.help && <InputHelp>{props.help}</InputHelp>}
            <InputError name={field.name} />
        </div>
    )
}
export default Select
