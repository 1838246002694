import clsx from 'clsx'
import { FC, ReactElement } from 'react'
import { Props as ButtonProps } from '../button/button'
import styles from './buttonGroup.module.css'

export enum ButtonGroupLayout {
    Responsive,
    Fixed,
    FullWidth,
    Fill,
}

interface Props {
    children:
        | ReactElement<ButtonProps>
        | Array<ReactElement<ButtonProps> | false | null | undefined>
    className?: string
    layout?: ButtonGroupLayout
}

// TODO: Remove this component as it doesn't cover all use cases and use CSS
// flex or grid layouts directly instead
const ButtonGroup: FC<Props> = ({ children, className, layout, ...props }) => {
    return (
        <div
            {...props}
            className={clsx(
                styles.root,
                layout === ButtonGroupLayout.Fixed && styles.fixed,
                layout === ButtonGroupLayout.FullWidth && styles.fullWidth,
                layout === ButtonGroupLayout.Fill && styles.fill,
                className
            )}
        >
            {children}
        </div>
    )
}

export default ButtonGroup
