'use client'

import React, { FC } from 'react'
import { BookingFragment } from '../../../lib/graphql/generated/types'
import { useTranslation } from '../../../lib/hooks'
import { FormattedDate, Icon } from '../../base'
import styles from './formattedInputFieldValue.module.css'

interface Props {
    className?: string
    inputFieldValue: BookingFragment['participant']['inputFieldValues'][number]
}

const FormattedInputFieldValue: FC<Props> = props => {
    const { t } = useTranslation()
    let formattedValue

    switch (props.inputFieldValue.__typename) {
        case 'StringInputFieldValue':
            formattedValue = props.inputFieldValue.value
            break
        case 'ListStringInputFieldValue':
            formattedValue = props.inputFieldValue.values.join(', ')
            break
        case 'BooleanInputFieldValue':
            formattedValue = props.inputFieldValue.selected
                ? t('common:yes')
                : t('common:no')

            break
        case 'DateInputFieldValue':
            formattedValue = (
                <FormattedDate startDate={props.inputFieldValue.date} />
            )

            break
        case 'FileInputFieldValue':
            if (!props.inputFieldValue.file) {
                return null
            }

            formattedValue = (
                <>
                    <Icon name="file-pdf" size={2} />
                    <span>{props.inputFieldValue.file.title}</span>
                </>
            )

            break
        case 'ImageInputFieldValue':
            const name = props.inputFieldValue.image?.title
            const url = props.inputFieldValue.image?.thumbnail?.resource

            if (!name || !url) {
                return null
            }

            formattedValue = (
                <>
                    {url.endsWith('.jpg') ||
                    url.endsWith('.jpeg') ||
                    url.endsWith('.png') ? (
                        <img
                            alt={name}
                            className={styles.thumbnail}
                            src={url}
                        />
                    ) : (
                        <Icon name="image" size={2} />
                    )}
                    <span>{name}</span>
                </>
            )

            break
        default:
            console.warn(
                'Unsupported input field value type:',
                props.inputFieldValue.__typename
            )

            return null
    }

    return (
        <div className={props.className}>
            <strong>{props.inputFieldValue.inputField.label}</strong>
            <div className={styles.wrapper}>{formattedValue}</div>
        </div>
    )
}

export default FormattedInputFieldValue
