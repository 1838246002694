'use client'

import { Form, Formik } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { FC, ReactNode, useCallback, useState } from 'react'
import { useTranslation, useYup } from '../../../../lib/hooks'
import {
    Button,
    ButtonGroup,
    ButtonTheme,
    Dialog,
    Icon,
    Input,
    Switch,
    Textarea,
} from '../../../base'
import styles from './groupBookingCreateDialog.module.css'

export interface Values {
    description: string
    name: string
    public: boolean
}

interface Props {
    children: (open: () => void) => ReactNode
    onSubmit: (
        values: Values,
        formikHelpers: FormikHelpers<Values>
    ) => Promise<void>
}

const GroupBookingCreateDialog: FC<Props> = props => {
    const { t } = useTranslation()
    const yup = useYup()
    const [dialogOpen, setDialogOpen] = useState(false)

    const openDialog = useCallback(() => setDialogOpen(true), [])

    const closeDialog = useCallback(() => setDialogOpen(false), [])

    const onSubmit = useCallback(
        async (values: Values, formikHelpers: FormikHelpers<Values>) => {
            try {
                await props.onSubmit.call(undefined, values, formikHelpers)
                closeDialog()
            } catch (e) {
                /* istanbul ignore next */
                console.error(e)
            }
        },
        [closeDialog, props.onSubmit]
    )

    return (
        <>
            {props.children(openDialog)}
            <Formik<Values>
                initialValues={{
                    name: '',
                    description: '',
                    public: false,
                }}
                onSubmit={onSubmit}
                validationSchema={yup.object().shape({
                    name: yup.string().required(),
                })}
            >
                {formik => (
                    <Dialog
                        onClose={closeDialog}
                        onClosed={formik.resetForm}
                        open={dialogOpen}
                        title={t('groupBooking:create')}
                        small
                    >
                        <Form className={styles.form} noValidate>
                            <div className={styles.fields}>
                                <Input
                                    label={t('groupBooking:name')}
                                    name="name"
                                    required
                                />
                                <Textarea
                                    label={t('groupBooking:description')}
                                    name="description"
                                />
                                <Switch
                                    help={t('groupBooking:public.help')}
                                    name="public"
                                >
                                    {t('groupBooking:public.label')}
                                </Switch>
                            </div>
                            <div className={styles.info}>
                                <Icon name="info" size={3} />
                                <div>{t('groupBooking:participantsInfo')}</div>
                            </div>
                            <ButtonGroup>
                                <Button
                                    loading={formik.isSubmitting}
                                    type="submit"
                                >
                                    {t('common:save')}
                                </Button>
                                <Button
                                    disabled={formik.isSubmitting}
                                    onClick={closeDialog}
                                    theme={ButtonTheme.Cancel}
                                >
                                    {t('common:cancel')}
                                </Button>
                            </ButtonGroup>
                        </Form>
                    </Dialog>
                )}
            </Formik>
        </>
    )
}

export default GroupBookingCreateDialog
