'use client'

import { FC } from 'react'
import { useTranslation } from '../../../../lib/hooks'
import { EventQueryEvent } from '../../../../lib/types'
import {
    DateFormat,
    FormattedCurrency,
    FormattedDate,
    FormattedHtml,
    Text,
} from '../../../base'
import styles from './invitationSummary.module.css'

interface Props {
    currency?: string | null
    operation: NonNullable<EventQueryEvent['viewerInvitee']>['operation']
    quantity: number
    timezone?: string
}

const InvitationSummary: FC<Props> = props => {
    const { t } = useTranslation()

    return (
        <div className={styles.root}>
            <Text element="h3">
                {props.operation.title && (
                    <>
                        {props.quantity > 1 && `${props.quantity} × `}
                        {props.operation.title}
                    </>
                )}
            </Text>
            {(props.operation.operationDates.length > 0 ||
                props.operation.venue?.name) && (
                <Text element="div" type="small">
                    {props.operation.operationDates.map(
                        ({ startDate, endDate }, i) => (
                            <FormattedDate
                                key={i}
                                className={styles.date}
                                endDate={endDate}
                                format={DateFormat.Long}
                                startDate={startDate}
                                timezone={props.timezone}
                                withTime
                            />
                        )
                    )}
                    {props.operation.venue?.name && (
                        <div>{props.operation.venue.name}</div>
                    )}
                </Text>
            )}
            {props.operation.marketPrice && props.currency && (
                <Text element="div" type="small">
                    {t('invitee:price')}
                    <span>: </span>
                    <FormattedCurrency
                        amount={props.operation.marketPrice * props.quantity}
                        currency={props.currency}
                    />
                </Text>
            )}
            {props.operation.priceBreakdown && (
                <Text element="div" type="small">
                    {t('common:priceBreakdown')}
                    <span>:</span>
                    <FormattedHtml html={props.operation.priceBreakdown} />
                </Text>
            )}
        </div>
    )
}

export default InvitationSummary
