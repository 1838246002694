'use client'

import { Form, Formik } from 'formik'
import { useCookies } from 'next-client-cookies'
import { FC, ReactNode, useCallback, useState } from 'react'
import { tokenKey } from '../../../lib/constants'
import {
    useSendEmailMutation,
    useSendGlobalEmailMutation,
} from '../../../lib/graphql/generated/hooks'
import {
    RecipientType,
    SendGlobalEmailMutationVariables,
} from '../../../lib/graphql/generated/types'
import { useNotification, useTranslation, useYup } from '../../../lib/hooks'
import {
    Button,
    ButtonGroup,
    ButtonTheme,
    Dialog,
    Input,
    Textarea,
} from '../../base'
import styles from './contactForm.module.css'

interface Values {
    email: string
    firstName: string
    lastName: string
    message: string
}

interface Props {
    children: (open: () => void) => ReactNode
    email?: string
    eventId?: string
    firstName?: string
    lastName?: string
    recipientId?: string
    recipientType: RecipientType
}

const ContactForm: FC<Props> = props => {
    const { t } = useTranslation()
    const notification = useNotification()
    const yup = useYup()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)
    const [, sendEmail] = useSendEmailMutation()
    const [, sendGlobalEmail] = useSendGlobalEmailMutation()
    const [dialogOpen, setDialogOpen] = useState(false)

    const openDialog = useCallback(() => setDialogOpen(true), [])

    const closeDialog = useCallback(() => setDialogOpen(false), [])

    const onSubmit = useCallback(
        async (values: Values) => {
            const variables: SendGlobalEmailMutationVariables = {
                token,
                message: {
                    recipientId: props.recipientId,
                    recipientType: props.recipientType,
                    text: values.message,
                },
                sender: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                },
            }

            try {
                if (props.eventId) {
                    const { data, error } = await sendEmail({
                        eventId: props.eventId,
                        ...variables,
                    })

                    if (error) {
                        throw error
                    }

                    if (data?.viewer?.event?.sendEmailMessage?.inputError) {
                        throw Error(
                            data.viewer.event.sendEmailMessage.inputError
                                .message
                        )
                    }

                    if (!data?.viewer?.event?.sendEmailMessage?.success) {
                        throw Error(t('common:error.internal'))
                    }
                } else {
                    const { data, error } = await sendGlobalEmail(variables)

                    if (error) {
                        throw error
                    }

                    if (data?.viewer?.sendEmailMessage?.inputError) {
                        throw Error(
                            data.viewer.sendEmailMessage.inputError.message
                        )
                    }

                    if (!data?.viewer?.sendEmailMessage?.success) {
                        throw Error(t('common:error.internal'))
                    }
                }

                notification.confirm(t('common:contactForm.confirmation'))
                closeDialog()
            } catch (e) {
                console.error(e)
                notification.alert(t('common:error.internal'))
            }
        },
        [
            closeDialog,
            notification,
            props.eventId,
            props.recipientId,
            props.recipientType,
            sendEmail,
            sendGlobalEmail,
            t,
            token,
        ]
    )

    return (
        <>
            {props.children(openDialog)}
            <Formik
                initialValues={{
                    firstName: props.firstName ?? '',
                    lastName: props.lastName ?? '',
                    email: props.email ?? '',
                    message: '',
                }}
                onSubmit={onSubmit}
                validationSchema={yup.object().shape({
                    firstName: yup.string().required(),
                    lastName: yup.string().required(),
                    email: yup.string().email().required(),
                    message: yup.string().required(),
                })}
                enableReinitialize
            >
                {formik => (
                    <Dialog
                        onClose={closeDialog}
                        onClosed={formik.resetForm}
                        open={dialogOpen}
                        title={t('common:contactForm.title')}
                        small
                    >
                        <Form noValidate>
                            <div className={styles.fields}>
                                <Input
                                    disabled={!!props.firstName}
                                    label={t('common:contactForm.firstName')}
                                    name="firstName"
                                    required
                                />
                                <Input
                                    disabled={!!props.lastName}
                                    label={t('common:contactForm.lastName')}
                                    name="lastName"
                                    required
                                />
                                <Input
                                    disabled={!!props.email}
                                    label={t('common:contactForm.email')}
                                    name="email"
                                    type="email"
                                    required
                                />
                                <Textarea
                                    label={t('common:contactForm.message')}
                                    name="message"
                                    required
                                />
                            </div>
                            <ButtonGroup>
                                <Button
                                    loading={formik.isSubmitting}
                                    type="submit"
                                >
                                    {t('common:send')}
                                </Button>
                                <Button
                                    disabled={formik.isSubmitting}
                                    onClick={closeDialog}
                                    theme={ButtonTheme.Cancel}
                                >
                                    {t('common:cancel')}
                                </Button>
                            </ButtonGroup>
                        </Form>
                    </Dialog>
                )}
            </Formik>
        </>
    )
}

export default ContactForm
