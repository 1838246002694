/* istanbul ignore file FIXME */
'use client'

import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { OnlineEventTiming } from '../../../lib/graphql/generated/types'
import { useTranslation } from '../../../lib/hooks'
import { OnlineEvent } from '../../../lib/types'
import { Text } from '../../base'
import styles from './videoPlayer.module.css'

enum VideoPlayerError {
    STALLED = 230001,
    STALLED_AFTER_OFFLINE = 230002,
    OFFLINE = 232002,
    NOT_FOUND = 232404,
}

interface VideoPlayerMessage {
    text?: string
    title: string
}

export interface Props {
    onlineEvent: OnlineEvent
}

const VideoPlayer: FC<Props> = props => {
    const { t } = useTranslation()
    const element = useRef<HTMLDivElement>(null)
    const player = useRef<jwplayer.JWPlayer>()
    const onlineEventId = useRef<string>()
    const [scriptLoaded, setScriptLoaded] = useState(false)
    const [message, setMessage] = useState<VideoPlayerMessage>()

    const source = useMemo(
        () => ({
            file: props.onlineEvent.url,
            type: props.onlineEvent.fileExtension,
        }),
        [props.onlineEvent.fileExtension, props.onlineEvent.url]
    )

    const onError = useCallback(
        (error: jwplayer.ErrorParam) => {
            switch (error.code) {
                case VideoPlayerError.STALLED:
                case VideoPlayerError.NOT_FOUND:
                    if (props.onlineEvent.timing === OnlineEventTiming.Live) {
                        const ended = props.onlineEvent.endDate.isAfter()
                        const messageKey = ended ? 'ended' : 'notStarted'

                        setMessage({
                            title: t(
                                `event:onlineEvent.liveStream.${messageKey}.title`
                            ),
                            text: t(
                                `event:onlineEvent.liveStream.${messageKey}.text`
                            ),
                        })
                    } else {
                        setMessage({
                            title: error.message,
                            text: `#${error.code}`,
                        })
                    }

                    break
                case VideoPlayerError.STALLED_AFTER_OFFLINE:
                case VideoPlayerError.OFFLINE:
                    setMessage({
                        title: t(
                            'event:onlineEvent.liveStream.badConnection.title'
                        ),
                        text: t(
                            'event:onlineEvent.liveStream.badConnection.text'
                        ),
                    })

                    break
                default:
                    setMessage({
                        title: error.message,
                        text: `#${error.code}`,
                    })

                    break
            }
        },
        [props.onlineEvent.endDate, props.onlineEvent.timing, t]
    )

    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://cdn.jwplayer.com/libraries/xRuQP8Ok.js'
        script.onload = () => setScriptLoaded(true)
        document.head.appendChild(script)

        return () => {
            player.current?.remove()
            document.head.removeChild(script)
        }
    }, [])

    useEffect(() => {
        if (!scriptLoaded) {
            return
        }

        if (!jwplayer || !element.current) {
            setMessage({
                title: t('common:VideoPlayerError.internal'),
            })

            return
        }

        // Only reinitialize if the online event changed
        if (props.onlineEvent.id === onlineEventId.current) {
            return
        }

        onlineEventId.current = props.onlineEvent.id

        player.current = jwplayer(element.current).setup({
            ...source,
            aspectratio: undefined,
            height: '100%',
            autostart: true,
            mute: true,
            skin: { name: 'zoon' },
        })

        player.current.on('error', onError)

        if (
            props.onlineEvent.timing === OnlineEventTiming.Live &&
            props.onlineEvent.startDate.isAfter()
        ) {
            setMessage({
                title: t('event:onlineEvent.liveStream.notStarted.title'),
                text: t('event:onlineEvent.liveStream.notStarted.text'),
            })
        } else {
            setMessage(undefined)
        }
    }, [
        onError,
        props.onlineEvent.id,
        props.onlineEvent.startDate,
        props.onlineEvent.timing,
        scriptLoaded,
        source,
        t,
    ])

    useEffect(() => {
        // Periodically check the state and update / reconnect
        const intervalId = setInterval(() => {
            // @ts-expect-error "error" state still exists
            if (player.current?.getState() === 'error') {
                setMessage(undefined)
                // @ts-expect-error Loading like this still works
                player.current?.load([source])
            } else if (props.onlineEvent.startDate.isBefore()) {
                setMessage(undefined)
            }
        }, 20000)

        return () => clearInterval(intervalId)
    }, [props.onlineEvent.startDate, source])

    return (
        <div className={styles.root}>
            <div className={styles.player}>
                <div ref={element} />
            </div>
            {message && (
                <div className={styles.messageOverlay}>
                    <Text
                        className={styles.messageText}
                        element="div"
                        mdType="h3"
                        smType="h4"
                        type="h5"
                    >
                        {message.title}
                    </Text>
                    {message.text && (
                        <>
                            <Text
                                className={styles.messageText}
                                element="div"
                                mdType="h4"
                                smType="h5"
                                type="h6"
                            >
                                {message.text}
                            </Text>
                        </>
                    )}
                </div>
            )}
        </div>
    )
}

export default VideoPlayer
