/* istanbul ignore file */
'use server'

import {
    SsoLoginMutation,
    SsoLoginMutationVariables,
} from '../graphql/generated/types'
import getGraphqlClient from '../graphql/getGraphqlClient'
import ssoLoginMutation from '../graphql/mutations/ssoLoginMutation.graphql'
import { getTranslation } from '../i18n'
import { ServerActionResult } from '../types'

interface Args {
    locale: string
    successUrl: string
}

const authenticateWithSso = async (args: Args): Promise<ServerActionResult> => {
    let ssoUrl

    try {
        const { data, error } = await getGraphqlClient()
            .mutation<
                SsoLoginMutation,
                SsoLoginMutationVariables
            >(ssoLoginMutation, { callbackUri: args.successUrl })
            .toPromise()

        if (error) {
            throw error
        }

        ssoUrl = data?.authentication?.ssoUri

        if (!ssoUrl) {
            throw Error('Missing SSO URL')
        }
    } catch (e) {
        console.error(e)
        const { t } = await getTranslation(args.locale)

        return {
            error: t('common:error.internal'),
        }
    }

    return { data: ssoUrl }
}

export default authenticateWithSso
