/* istanbul ignore file */
'use client'

import { useCallback } from 'react'
import { useNotification } from '../../lib/hooks'

const useOnSubmit = () => {
    const notification = useNotification()

    const onSubmit = useCallback(
        async (values: any) => {
            await new Promise(resolve => setTimeout(resolve, 2000))
            notification.inform(JSON.stringify(values))
        },
        [notification]
    )

    const onSubmitDummy = useCallback(async () => {}, [])

    return {
        onSubmit,
        onSubmitDummy,
    }
}

export default useOnSubmit
