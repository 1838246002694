import { InputFragment } from '../../../../../../lib/graphql/generated/types'
import { decodeValueFromInputField } from '../../../utils'
import { Value } from '../../selection'

export const inputFieldsToValue = (inputFields: InputFragment[]): Value => ({
    inputFieldValues: inputFields.reduce(
        (acc, inputField) => ({
            ...acc,
            [inputField.identifier]: decodeValueFromInputField(inputField),
        }),
        {}
    ),
})

export default inputFieldsToValue
