'use client'

import { FC } from 'react'

interface Props {
    canonicalUrl?: string
    description?: string
    image?: string
    isoEndDate?: string
    isoStartDate?: string
    locale?: string
    title: string
    venueName?: string
}

/**
 * Workaround for generateMetadata blocking the loading.tsx UI when fetching:
 * https://github.com/vercel/next.js/issues/55524
 *
 * Renders document title and certain metadata as client component which Next.js
 * picks up and moves to the document head. Not working as server component.
 */
const Metadata: FC<Props> = props => (
    <>
        <title>{props.title}</title>
        <meta content={props.title} property="og:title" />
        <meta content={props.title} name="twitter:title" />
        {props.description && (
            <>
                <meta content={props.description} name="description" />
                <meta content={props.description} property="og:description" />
                <meta content={props.description} name="twitter:description" />
            </>
        )}
        {props.image && (
            <>
                <meta content={props.image} property="og:image" />
                <meta content={props.image} name="twitter:image" />
                <meta content="summary_large_image" name="twitter:card" />
            </>
        )}
        {props.canonicalUrl?.startsWith('http') && (
            <>
                <link href={props.canonicalUrl} rel="canonical" />
                <meta content={props.canonicalUrl} property="og:url" />
            </>
        )}
        {props.locale && <meta content={props.locale} property="og:locale" />}
        <meta content="website" property="og:type" />
        {props.isoStartDate && props.venueName && (
            <script
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify({
                        '@context': 'https://schema.org',
                        '@type': 'Event',
                        name: props.title,
                        startDate: props.isoStartDate,
                        endDate: props.isoEndDate,
                        location: {
                            '@type': 'Place',
                            name: props.venueName,
                        },
                        description: props.description,
                        image: props.image && [props.image],
                    }),
                }}
                type="application/ld+json"
            />
        )}
    </>
)

export default Metadata
