'use client'

import { Form, Formik } from 'formik'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { useTranslation, useYup } from '../../../../lib/hooks'
import { Button, ButtonGroup, TagButton, Text } from '../../../base'
import { StepProps } from '../../wizard'
import wizardStyles from '../../wizard.module.css'
import styles from './tagsStep.module.css'

export interface Values {
    categories: string[]
    eventType: string
}

const TagsStep: FC<StepProps> = props => {
    const { t } = useTranslation()
    const yup = useYup()

    const selectedEventType = props.values.eventType

    const selectedCategories = useMemo(
        () => props.values.categories ?? [],
        [props.values.categories]
    )

    const eventTypes = useMemo(
        () => props.categoriesAndEventTypes?.eventTypes ?? [],
        [props.categoriesAndEventTypes?.eventTypes]
    )

    const categories = useMemo(() => {
        const _categories = [
            ...(props.categoriesAndEventTypes?.categories ?? []),
        ]

        eventTypes
            .find(eventType => eventType.id === selectedEventType)
            ?.categories?.forEach(eventTypeCategory => {
                if (
                    !_categories.some(
                        category => category.id === eventTypeCategory.id
                    )
                ) {
                    _categories.push(eventTypeCategory)
                }
            })

        return _categories
    }, [
        eventTypes,
        props.categoriesAndEventTypes?.categories,
        selectedEventType,
    ])

    useEffect(() => {
        if (eventTypes.length === 1 && !selectedEventType) {
            props.mergeValues.call(undefined, { eventType: eventTypes[0].id })
        }
    }, [eventTypes, props.mergeValues, selectedEventType])

    const onSubmit = useCallback(
        async (values: Values) => {
            props.mergeValues.call(undefined, values)
            props.nextStep.call(undefined)
        },
        [props.mergeValues, props.nextStep]
    )

    return (
        <Formik<Values>
            initialValues={{
                eventType: props.values.eventType ?? '',
                categories: props.values.categories ?? [],
            }}
            onSubmit={onSubmit}
            validationSchema={yup.object().shape({
                eventType: yup.string().required(),
                categories: yup.array().of(yup.string()),
            })}
            enableReinitialize
            validateOnMount
        >
            {formik => {
                const onEventTypeClick = (id: string) => {
                    props.mergeValues({
                        eventType: id,
                        categories: [],
                    })
                }

                const onCategoryClick = (id: string) => {
                    if (selectedCategories.indexOf(id) >= 0) {
                        props.mergeValues({
                            categories: selectedCategories.filter(
                                tag => tag !== id
                            ),
                        })
                    } else {
                        props.mergeValues({
                            categories: [...selectedCategories, id],
                        })
                    }
                }

                return (
                    <Form className={wizardStyles.form} noValidate>
                        <div className={wizardStyles.fields}>
                            <div className={wizardStyles.colspan}>
                                <Text
                                    className={styles.label}
                                    element="div"
                                    type="small"
                                >
                                    {t('wizard:form.eventType')} *
                                </Text>
                                <div className={styles.tagButtons}>
                                    {eventTypes.map(eventType => (
                                        <TagButton
                                            key={eventType.id}
                                            className={styles.tagButton}
                                            data={eventType.id}
                                            onClick={onEventTypeClick}
                                            selected={
                                                selectedEventType ===
                                                eventType.id
                                            }
                                        >
                                            {eventType.name}
                                        </TagButton>
                                    ))}
                                </div>
                            </div>
                            <div className={wizardStyles.colspan}>
                                <Text
                                    className={styles.label}
                                    element="div"
                                    type="small"
                                >
                                    {t('wizard:form.categories')}
                                </Text>
                                <div className={styles.tagButtons}>
                                    {selectedEventType &&
                                        categories.map(category => (
                                            <TagButton
                                                key={category.id}
                                                data={category.id}
                                                onClick={onCategoryClick}
                                                selected={selectedCategories.includes(
                                                    category.id
                                                )}
                                            >
                                                {category.name}
                                            </TagButton>
                                        ))}
                                </div>
                            </div>
                        </div>
                        <ButtonGroup className={wizardStyles.actions}>
                            <Button disabled={!formik.isValid} type="submit">
                                {t('common:continue')}
                            </Button>
                        </ButtonGroup>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default TagsStep
