/* istanbul ignore file */
'use server'

import {
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables,
} from '../graphql/generated/types'
import getGraphqlClient from '../graphql/getGraphqlClient'
import requestPasswordResetMutation from '../graphql/mutations/requestPasswordResetMutation.graphql'
import { getTranslation } from '../i18n'
import { ServerActionResult } from '../types'

interface Args extends RequestPasswordResetMutationVariables {
    locale: string
}

const requestPasswordReset = async ({
    locale,
    ...variables
}: Args): Promise<ServerActionResult> => {
    try {
        const { error } = await getGraphqlClient()
            .mutation<
                RequestPasswordResetMutation,
                RequestPasswordResetMutationVariables
            >(requestPasswordResetMutation, variables)
            .toPromise()

        if (error) {
            throw error
        }
    } catch (e) {
        console.error(e)
        const { t } = await getTranslation(locale)

        return {
            error: t('common:error.internal'),
        }
    }
}

export default requestPasswordReset
