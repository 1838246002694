'use client'

import { useFormikContext } from 'formik'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { GroupBookingFragment } from '../../../../../lib/graphql/generated/types'
import { useTranslation, useViewerUser } from '../../../../../lib/hooks'
import { Button, ButtonTheme, InputGroup, Radio } from '../../../../base'
import {
    GroupBookingCreateDialog,
    GroupBookingCreateDialogValues,
} from '../../../../common'
import { Values } from '../selection'
import styles from './bookingOperationGroupBookings.module.css'

interface Props {
    groupBookings?: GroupBookingFragment[]
    maxParticipants?: number
    newGroupBookingEnabled?: boolean
    operationId: string
}

const BookingOperationGroupBookings: FC<Props> = props => {
    const { t } = useTranslation()
    const viewerUser = useViewerUser()
    const formik = useFormikContext<Values>()
    const bookingOperation = formik.values[props.operationId][0]

    // Filter out new group booking shown separately
    const groupBookings = useMemo(
        () =>
            props.groupBookings?.filter(
                groupBooking =>
                    groupBooking?.primaryParticipant.user?.id !== viewerUser?.id
            ),
        [props.groupBookings, viewerUser?.id]
    )

    const removeGroup = useCallback(() => {
        formik.setFieldValue
            .call(undefined, props.operationId, [
                {
                    ...bookingOperation,
                    groupBookingId: undefined,
                    newGroupBooking: undefined,
                },
            ])
            .catch(console.error)
    }, [bookingOperation, formik.setFieldValue, props.operationId])

    const onGroupBookingCreateDialogSubmit = useCallback(
        async (values: GroupBookingCreateDialogValues) => {
            formik.setFieldValue
                .call(undefined, props.operationId, [
                    {
                        ...bookingOperation,
                        groupBookingId: undefined,
                        newGroupBooking: values,
                    },
                ])
                .catch(console.error)
        },
        [bookingOperation, formik.setFieldValue, props.operationId]
    )

    const name = `${props.operationId}[0].groupBookingId`

    useEffect(() => {
        // FIXME: Have Formik automatically touch radios on submit
        if (
            formik.submitCount &&
            !formik.getFieldMeta.call(undefined, name).touched
        ) {
            formik.setFieldTouched
                .call(undefined, name, true)
                .catch(console.error)
        }
    }, [name, formik.getFieldMeta, formik.setFieldTouched, formik.submitCount])

    return (
        <div className={styles.root}>
            <InputGroup label={t('groupBooking:group')} name={name} required>
                {groupBookings?.map(groupBooking => (
                    <Radio
                        key={groupBooking?.id}
                        disabled={Boolean(
                            props.maxParticipants &&
                                groupBooking?.participantCount ===
                                    props.maxParticipants
                        )}
                        help={groupBooking?.description}
                        name={name}
                        value={groupBooking?.id}
                    >
                        <div className={styles.label}>
                            <span>{groupBooking?.name}</span>
                            <span>
                                (
                                {props.maxParticipants
                                    ? `${groupBooking?.participantCount}/${props.maxParticipants}`
                                    : groupBooking?.participantCount}
                                )
                            </span>
                        </div>
                    </Radio>
                ))}
                {bookingOperation.newGroupBooking && (
                    <Radio
                        help={
                            bookingOperation.newGroupBooking.description ||
                            undefined
                        }
                        name={name}
                        checked
                    >
                        <div className={styles.label}>
                            <span>{bookingOperation.newGroupBooking.name}</span>
                            <span>
                                (
                                {props.maxParticipants
                                    ? `0/${props.maxParticipants}`
                                    : 0}
                                )
                            </span>
                        </div>
                    </Radio>
                )}
            </InputGroup>
            {props.newGroupBookingEnabled && (
                <>
                    {bookingOperation.newGroupBooking ? (
                        <Button
                            className={styles.button}
                            onClick={removeGroup}
                            theme={ButtonTheme.Transparent}
                        >
                            - {t('groupBooking:remove')}
                        </Button>
                    ) : (
                        <GroupBookingCreateDialog
                            onSubmit={onGroupBookingCreateDialogSubmit}
                        >
                            {open => (
                                <Button
                                    className={styles.button}
                                    onClick={open}
                                    theme={ButtonTheme.Transparent}
                                >
                                    + {t('groupBooking:create')}
                                </Button>
                            )}
                        </GroupBookingCreateDialog>
                    )}
                </>
            )}
        </div>
    )
}

export default BookingOperationGroupBookings
