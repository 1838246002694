/* istanbul ignore file */
'use client'

import { FC, useState } from 'react'
import { Card, NumericStepper } from '../client'
import styles from './examples.module.css'

const NumericStepperExample: FC = () => {
    const [value, setvalue] = useState(5)

    return (
        <Card contentClassName={styles.flexCol} title="NumericStepper">
            <NumericStepper
                help="Min. 0, max. 10"
                label="Label"
                max={10}
                min={0}
                onChange={setvalue}
                value={value}
            />
        </Card>
    )
}

export default NumericStepperExample
