'use client'

import clsx from 'clsx'
import { FC, ReactNode } from 'react'
import { Layout } from '../../../../lib/graphql/generated/types'
import { Container, Text } from '../../../base'
import styles from './masthead.module.css'

export interface Props {
    content?: ReactNode
    contentClassName?: string
    header?: ReactNode
    headerClassName?: string
    layout?: Layout
    media?: ReactNode
    mediaClassName?: string
    title?: string
}

const Masthead: FC<Props> = props => (
    <Container
        className={clsx(
            styles.root,
            props.layout === Layout.Wide ? styles.wide : styles.default
        )}
    >
        <div className={styles.wrapper}>
            {props.header && (
                <div className={clsx(styles.header, props.headerClassName)}>
                    {props.header}
                </div>
            )}
            {props.title && (
                <div className={styles.title}>
                    <Text
                        className={clsx(
                            styles.heading,
                            props.title.length > 20 && styles.above20Chars,
                            props.title.length > 30 && styles.above30Chars,
                            props.title.length > 40 && styles.above40Chars
                        )}
                        element="h1"
                        lgType="d3"
                        mdType="d2"
                        type="d3"
                        ulType="d1"
                        weight="600"
                        xlType="d2"
                        noMargin
                    >
                        {props.title}
                    </Text>
                </div>
            )}
            {props.content && (
                <div className={clsx(styles.content, props.contentClassName)}>
                    {props.content}
                </div>
            )}
            {props.media && (
                <div className={clsx(styles.media, props.mediaClassName)}>
                    {props.media}
                </div>
            )}
        </div>
    </Container>
)

export default Masthead
