/* istanbul ignore file */
'use server'

import {
    CreateUserMutation,
    CreateUserMutationVariables,
} from '../graphql/generated/types'
import getGraphqlClient from '../graphql/getGraphqlClient'
import createUserMutation from '../graphql/mutations/createUserMutation.graphql'
import { getTranslation } from '../i18n'
import { ServerActionResult } from '../types'

interface Args {
    locale: string
    user: CreateUserMutationVariables['user']
}

const createUser = async ({
    locale,
    ...variables
}: Args): Promise<ServerActionResult> => {
    try {
        const { data, error } = await getGraphqlClient()
            .mutation<
                CreateUserMutation,
                CreateUserMutationVariables
            >(createUserMutation, variables)
            .toPromise()

        if (error) {
            throw error
        } else if (!data?.viewer?.user?.createUser?.success) {
            throw Error(data?.viewer?.user?.createUser?.inputError?.message)
        }
    } catch (e) {
        console.error(e)
        const { t } = await getTranslation(locale)

        return {
            error: t('common:error.internal'),
        }
    }
}

export default createUser
