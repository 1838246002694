'use client'

import clsx from 'clsx'
import { useField, useFormikContext } from 'formik'
import { FC, JSX, ReactNode } from 'react'
import InputHelp from '../inputHelp'
import styles from './radio.module.css'

interface Props
    extends Pick<
        JSX.IntrinsicElements['input'],
        'checked' | 'className' | 'disabled' | 'onChange'
    > {
    children?: ReactNode
    help?: ReactNode
    name: string
    value?: string
}

const Radio: FC<Props> = props => {
    const [field, meta] = useField({
        name: props.name,
        type: 'radio',
        value: props.value,
    })
    const { isSubmitting } = useFormikContext()

    const disabled = props.disabled || isSubmitting
    const invalid = meta.touched && meta.error

    return (
        <div
            className={clsx(
                styles.root,
                disabled && styles.disabled,
                props.className
            )}
        >
            <label className={styles.label}>
                <input
                    {...field}
                    checked={props.checked ?? field.checked}
                    className={styles.input}
                    disabled={disabled}
                    onChange={props.onChange ?? field.onChange}
                    type="radio"
                />
                <div
                    className={clsx(styles.radio, invalid && styles.invalid)}
                />
                {props.children}
            </label>
            {props.help && <InputHelp>{props.help}</InputHelp>}
        </div>
    )
}

export default Radio
