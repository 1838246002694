import { OnlineEventTiming } from '../../graphql/generated/types'
import { OnlineEventSettings } from '../../types'

export const selectOnlineEventSetting = (
    onlineEventSettings?: OnlineEventSettings
) => {
    if (onlineEventSettings?.length) {
        return onlineEventSettings.reduce((acc, curr) =>
            curr.timing === OnlineEventTiming.Replay ? curr : acc
        )
    }
}

export default selectOnlineEventSetting
