'use client'

import clsx from 'clsx'
import { useField, useFormikContext } from 'formik'
import { FC, JSX, ReactNode } from 'react'
import { Icon } from '../../icon'
import InputError from '../inputError'
import InputHelp from '../inputHelp'
import styles from './checkbox.module.css'

interface Props
    extends Pick<
        JSX.IntrinsicElements['input'],
        'checked' | 'className' | 'disabled' | 'onChange' | 'required'
    > {
    children?: ReactNode
    help?: ReactNode
    name: string
    value?: string
}

const Checkbox: FC<Props> = props => {
    const [field, meta] = useField({
        name: props.name,
        type: 'checkbox',
        value: props.value,
    })
    const { isSubmitting } = useFormikContext()

    const standalone = typeof meta.value === 'boolean'
    const disabled = props.disabled || isSubmitting
    const invalid = meta.touched && meta.error

    return (
        <div
            className={clsx(
                styles.root,
                disabled && styles.disabled,
                props.className
            )}
        >
            <label className={styles.label}>
                <input
                    {...field}
                    checked={props.checked ?? field.checked}
                    className={styles.input}
                    disabled={disabled}
                    onChange={props.onChange ?? field.onChange}
                    required={props.required}
                    type="checkbox"
                />
                <div
                    className={clsx(styles.checkbox, invalid && styles.invalid)}
                >
                    <Icon className={styles.icon} name="check" />
                </div>
                {props.children}
                {standalone && props.required && <span> *</span>}
            </label>
            {props.help && <InputHelp>{props.help}</InputHelp>}
            {standalone && <InputError name={field.name} />}
        </div>
    )
}

export default Checkbox
