'use client'

import clsx from 'clsx'
import { FC, ReactNode } from 'react'
import InputError from '../inputError'
import InputHelp from '../inputHelp'
import InputLabel from '../inputLabel'
import styles from './inputGroup.module.css'

interface Props {
    children?: ReactNode
    className?: string
    help?: ReactNode
    inline?: boolean
    label?: string
    name: string
    required?: boolean
}

const InputGroup: FC<Props> = props => (
    <div className={clsx(styles.root, props.className)}>
        {props.label && (
            <InputLabel>
                {props.label}
                {props.required && <span> *</span>}
            </InputLabel>
        )}
        <div className={clsx(styles.inputs, props.inline && styles.inline)}>
            {props.children}
        </div>
        {props.help && <InputHelp>{props.help}</InputHelp>}
        <InputError name={props.name} />
    </div>
)

export default InputGroup
