/* istanbul ignore file */
'use server'

import { getCookies } from 'next-client-cookies/server'
import { tokenKey } from '../constants'
import {
    UpdateUserMutation,
    UpdateUserMutationVariables,
} from '../graphql/generated/types'
import getGraphqlClient from '../graphql/getGraphqlClient'
import updateUserMutation from '../graphql/mutations/updateUserMutation.graphql'
import { getTranslation } from '../i18n'
import { ServerActionResult } from '../types'

interface Args extends Omit<UpdateUserMutationVariables, 'token'> {
    locale: string
}

const updateUser = async ({
    locale,
    ...variables
}: Args): Promise<ServerActionResult> => {
    try {
        const token = getCookies().get(tokenKey)

        if (!token) {
            throw Error('Unauthorized')
        }

        const { data, error } = await getGraphqlClient()
            .mutation<UpdateUserMutation, UpdateUserMutationVariables>(
                updateUserMutation,
                {
                    token,
                    ...variables,
                }
            )
            .toPromise()

        if (error) {
            throw error
        } else if (data?.viewer?.updateUser?.inputError) {
            throw Error(data.viewer.updateUser.inputError.message)
        }
    } catch (e) {
        console.error(e)
        const { t } = await getTranslation(locale)

        return {
            error: t('common:error.internal'),
        }
    }
}

export default updateUser
