'use client'

import clsx from 'clsx'
import { useField, useFormikContext } from 'formik'
import { FC, JSX, ReactNode } from 'react'
import InputHelp from '../inputHelp'
import styles from './switch.module.css'

interface Props
    extends Pick<JSX.IntrinsicElements['input'], 'className' | 'disabled'> {
    children?: ReactNode
    help?: ReactNode
    name: string
}

const Switch: FC<Props> = props => {
    const [field] = useField({
        name: props.name,
        type: 'checkbox',
    })
    const { isSubmitting } = useFormikContext()

    const disabled = props.disabled || isSubmitting

    return (
        <div
            className={clsx(
                styles.root,
                disabled && styles.disabled,
                props.className
            )}
        >
            <label className={styles.label}>
                <input
                    {...field}
                    className={styles.input}
                    disabled={disabled}
                    type="checkbox"
                />
                <div className={styles.switch} />
                {props.children}
            </label>
            {props.help && <InputHelp>{props.help}</InputHelp>}
        </div>
    )
}

export default Switch
