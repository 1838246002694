'use client'

import { FC, useMemo } from 'react'
import { useRouter, useTranslation } from '../../../../lib/hooks'
import { locales as i18nLocales } from '../../../../lib/i18n'
import { Flyout, Icon, Link } from '../../../base'
import styles from './languageSwitcher.module.css'

interface Props {
    availableLocales?: string[]
}

const LanguageSwitcher: FC<Props> = props => {
    const { locale: currentLocale } = useTranslation()
    const { pathname } = useRouter()

    const locales = useMemo(
        () =>
            i18nLocales.filter(
                locale =>
                    locale === currentLocale ||
                    (props.availableLocales
                        ? props.availableLocales.includes(locale)
                        : true)
            ),
        [currentLocale, props.availableLocales]
    )

    if (locales.length === 1) {
        return (
            <div className={styles.singleLocale}>
                {currentLocale.toUpperCase()}
            </div>
        )
    }

    return (
        <Flyout
            toggle={
                <>
                    {currentLocale.toUpperCase()}
                    <Icon name="caret-down" />
                </>
            }
            toggleClassName={styles.toggle}
            list
        >
            {locales
                .filter(otherLocale => otherLocale !== currentLocale)
                .map(otherLocale => (
                    <Link
                        key={otherLocale}
                        href={pathname}
                        locale={otherLocale}
                        noUnderline
                    >
                        {otherLocale.toUpperCase()}
                    </Link>
                ))}
        </Flyout>
    )
}

export default LanguageSwitcher
