'use client'

import clsx from 'clsx'
import { throttle } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from '../../../lib/hooks'
import { Icon } from '../icon'
import styles from './progressBar.module.css'
import ProgressBarStep from './progressBarStep'

const minWidth = 80
const extraWidth = 20

interface Props {
    className?: string
    onClick: (step: number) => void
    step: number
    steps: string[]
}

const ProgressBar: FC<Props> = props => {
    const { t } = useTranslation()
    const scrollLeftButton = useRef<HTMLDivElement>(null)
    const scrollRightButton = useRef<HTMLDivElement>(null)
    const steps = useRef<HTMLDivElement>(null)
    const [allStepsVisible, setAllStepsVisible] = useState(true)

    const resize = useCallback(() => {
        /* istanbul ignore next */
        if (!steps.current) {
            return
        }

        const visibleSteps = Math.max(
            Math.floor(steps.current.offsetWidth / (minWidth + extraWidth)),
            3
        )

        setAllStepsVisible(visibleSteps >= props.steps.length)

        const width = Math.max(
            steps.current.offsetWidth / visibleSteps,
            minWidth
        )

        for (const child of steps.current.children) {
            const step = child as HTMLElement
            step.style.minWidth = `${width}px`
        }
    }, [props.steps.length])

    const toggleScrollButtons = useMemo(
        () =>
            throttle(() => {
                /* istanbul ignore next */
                if (
                    !steps.current ||
                    !scrollLeftButton.current ||
                    !scrollRightButton.current
                ) {
                    return
                }

                if (steps.current.scrollLeft > 0) {
                    scrollLeftButton.current.classList.add(styles.visible)
                } else {
                    scrollLeftButton.current.classList.remove(styles.visible)
                }

                if (
                    steps.current.scrollLeft +
                        steps.current.offsetWidth +
                        extraWidth <
                    steps.current.scrollWidth
                ) {
                    scrollRightButton.current.classList.add(styles.visible)
                } else {
                    scrollRightButton.current.classList.remove(styles.visible)
                }
            }, 200),
        []
    )

    useEffect(() => {
        resize()
        toggleScrollButtons()
    }, [resize, toggleScrollButtons])

    useEffect(() => {
        window.addEventListener('resize', resize)
        window.addEventListener('resize', toggleScrollButtons)

        return () => {
            window.removeEventListener('resize', resize)
            window.removeEventListener('resize', toggleScrollButtons)
        }
    }, [resize, toggleScrollButtons])

    useEffect(() => {
        const currentSteps = steps.current

        /* istanbul ignore next */
        if (!currentSteps) {
            return
        }

        currentSteps.addEventListener('scroll', toggleScrollButtons)

        return () => {
            currentSteps.removeEventListener('scroll', toggleScrollButtons)
        }
    }, [toggleScrollButtons])

    const centerStep = useCallback((step: HTMLElement) => {
        /* istanbul ignore next */
        if (!steps.current) {
            return
        }

        const scrollLeft =
            step.offsetLeft -
            steps.current.offsetWidth / 2 +
            step.offsetWidth / 2

        steps.current.scroll({
            left: scrollLeft,
            behavior: 'smooth',
        })
    }, [])

    useEffect(() => {
        if (steps.current && props.step <= props.steps.length) {
            centerStep(steps.current.children[props.step - 1] as HTMLElement)
        }
    }, [centerStep, props.step, props.steps.length])

    const scrollLeft = useCallback(() => {
        /* istanbul ignore next */
        if (!steps.current) {
            return
        }

        const center = steps.current.scrollLeft + steps.current.offsetWidth / 2

        const stepLeftFromCenter = Array.from(steps.current.children)
            .reverse()
            .find(child => {
                const element = child as HTMLElement
                return element.offsetLeft + element.offsetWidth < center
            })

        if (stepLeftFromCenter) {
            centerStep(stepLeftFromCenter as HTMLElement)
        }
    }, [centerStep])

    const scrollRight = useCallback(() => {
        /* istanbul ignore next */
        if (!steps.current) {
            return
        }

        const center = steps.current.scrollLeft + steps.current.offsetWidth / 2

        const stepRightFromCenter = Array.from(steps.current.children).find(
            child => {
                const element = child as HTMLElement
                return element.offsetLeft > center
            }
        )

        if (stepRightFromCenter) {
            centerStep(stepRightFromCenter as HTMLElement)
        }
    }, [centerStep])

    return (
        <div className={clsx(styles.root, props.className)}>
            {!allStepsVisible && (
                <div className={styles.header}>
                    {t('common:steps', { count: props.steps.length })}
                </div>
            )}
            <div className={styles.body}>
                {!allStepsVisible && (
                    <>
                        <div
                            ref={scrollLeftButton}
                            className={clsx(styles.scrollButton, styles.left)}
                            onClick={scrollLeft}
                        >
                            <Icon
                                className={styles.arrow}
                                name="caret-left"
                                size={3}
                            />
                        </div>
                        <div
                            ref={scrollRightButton}
                            className={clsx(styles.scrollButton, styles.right)}
                            onClick={scrollRight}
                        >
                            <Icon
                                className={styles.arrow}
                                name="caret-right"
                                size={3}
                            />
                        </div>
                    </>
                )}
                <div ref={steps} className={styles.steps}>
                    {props.steps.map((label, i) => {
                        const step = i + 1

                        return (
                            <ProgressBarStep
                                key={step}
                                active={step === props.step}
                                completed={step < props.step}
                                label={label}
                                onClick={props.onClick}
                                step={step}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default ProgressBar
