/* istanbul ignore file */
import { FC } from 'react'
import { Card, Divider } from '../base'

const DividerExample: FC = () => (
    <Card title="Divider">
        Above
        <Divider />
        Below
    </Card>
)

export default DividerExample
