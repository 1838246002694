'use client'

import { FC } from 'react'
import { useTranslation } from '../../../lib/hooks'

interface Props {
    amount: number
    className?: string
    currency: string
}

const FormattedCurrency: FC<Props> = props => {
    const { locale } = useTranslation()

    return (
        <span className={props.className}>
            {new Intl.NumberFormat(`${locale}-CH`, {
                style: 'currency',
                currency: props.currency.toUpperCase(),
            }).format(props.amount)}
        </span>
    )
}

export default FormattedCurrency
