/* istanbul ignore file */
import { FC } from 'react'
import { Avatar, Card } from '../base'
import styles from './examples.module.css'

const AvatarExample: FC = () => (
    <Card contentClassName={styles.twoCol} title="Avatar">
        <Avatar firstName="First" image="/example.jpg" lastName="Last" />
        <Avatar firstName="First" lastName="Last" />
    </Card>
)

export default AvatarExample
