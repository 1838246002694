'use client'

import clsx from 'clsx'
import { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styles from './bottomBar.module.css'

export interface Props {
    children?: ReactNode
    className?: string
    open?: boolean
}

const BottomBar: FC<Props> = props => {
    const dialog = useRef<HTMLDialogElement>(null)
    const bottomSpacer = useRef<HTMLDivElement>(null)
    const [mounted, setMounted] = useState(false)

    useEffect(() => {
        if (props.open) {
            setMounted(true)

            // Start CSS opening animations after mounting
            setTimeout(() => {
                /* istanbul ignore next */
                if (dialog.current && bottomSpacer.current) {
                    dialog.current.inert = true // Prevents autofocus
                    dialog.current.show()
                    dialog.current.inert = false

                    const container = dialog.current.firstElementChild

                    if (container) {
                        bottomSpacer.current.style.height = `${container.clientHeight}px`
                    }
                }
            }, 50)
        } else if (dialog.current?.open && bottomSpacer.current) {
            dialog.current?.close()
            bottomSpacer.current.style.height = ''

            // Unmount after CSS closing animations
            setTimeout(() => setMounted(false), 350)
        }
    }, [props.open])

    if (!mounted) {
        return
    }

    return createPortal(
        <>
            <dialog ref={dialog} className={styles.root}>
                <div
                    className={clsx(styles.container, props.className)}
                    autoFocus
                >
                    {props.children}
                </div>
            </dialog>
            <div ref={bottomSpacer} className={styles.bottomSpacer} />
        </>,
        document.body
    )
}

export default BottomBar
