'use client'

import { FC } from 'react'
import { ParticipantsQuery } from '../../../../lib/graphql/generated/types'
import { useViewerUser } from '../../../../lib/hooks'
import { Avatar, Text } from '../../../base'
import styles from './participant.module.css'

interface Props {
    participant: NonNullable<
        NonNullable<
            NonNullable<ParticipantsQuery['viewer']>['event']
        >['participants']
    >['items'][number]
}

const Participant: FC<Props> = props => {
    const viewerUser = useViewerUser()

    return (
        <div className={styles.root}>
            <Avatar
                className={styles.avatar}
                firstName={props.participant.firstName}
                href={
                    viewerUser && props.participant.user
                        ? `/profile/${props.participant.user.id}`
                        : undefined
                }
                image={props.participant.thumbnail?.thumbnail?.resource}
                lastName={props.participant.lastName}
                size={100}
            />
            <Text className={styles.name} element="div" weight="500">
                {props.participant.firstName} {props.participant.lastName}
            </Text>
        </div>
    )
}

export default Participant
