'use client'

import { useCookies } from 'next-client-cookies'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { tokenKey } from '../../../lib/constants'
import { useCommentsQuery } from '../../../lib/graphql/generated/hooks'
import { CommentsQuery } from '../../../lib/graphql/generated/types'
import { useMediaQuery, useTranslation } from '../../../lib/hooks'
import { Breakpoint } from '../../../lib/types'
import { Badge, Button, ButtonTheme, SidePanel, Text } from '../../base'
import { LoadMoreButton } from '../../common'
import { Comment } from './comment'
import CommentForm from './commentForm'
import styles from './comments.module.css'
import MockComments from './mockComments'

const batchSize = 10

type Comment = NonNullable<
    NonNullable<NonNullable<CommentsQuery['viewer']>['event']>['comments']
>['items'][number]

interface Props {
    eventId: string
    viewable?: boolean
}

const Comments: FC<Props> = props => {
    const { t, locale } = useTranslation()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)
    const mdUp = useMediaQuery(Breakpoint.MdUp)
    const lgUp = useMediaQuery(Breakpoint.LgUp)
    const body = useRef<HTMLDivElement>(null)
    const previousComments = useRef<Comment[]>()
    const [sidePanelOpen, setSidePanelOpen] = useState(false)
    const [length, setLength] = useState(batchSize)

    const [commentsQuery, executeCommentsQuery] = useCommentsQuery({
        variables: {
            token,
            locale,
            eventKeyOrId: props.eventId,
            length,
            offset: 0,
        },
        pause: !props.viewable,
    })

    const openSidePanel = useCallback(() => setSidePanelOpen(true), [])

    const closeSidePanel = useCallback(() => setSidePanelOpen(false), [])

    const total = commentsQuery.data?.viewer?.event?.comments?.totalCount ?? 0
    const comments = commentsQuery.data?.viewer?.event?.comments?.items

    useEffect(() => {
        if (!previousComments.current) {
            previousComments.current = comments
        }
    }, [comments])

    useEffect(() => {
        if (!comments || !previousComments.current || !body.current) {
            return
        }

        if (
            previousComments.current.length &&
            comments.length &&
            comments[0].id !== previousComments.current[0].id
        ) {
            body.current.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        } else if (comments.length > previousComments.current.length) {
            body.current.scrollTo({
                top: body.current.scrollHeight,
                behavior: 'smooth',
            })
        }

        previousComments.current = comments
    }, [comments])

    const onLoadMore = useCallback(() => {
        setLength(prev => prev + batchSize)
    }, [])

    return (
        <>
            <Button
                className={styles.button}
                disabled={!comments && props.viewable}
                iconName="chat"
                iconSize={lgUp ? undefined : 2}
                onClick={openSidePanel}
                theme={lgUp ? ButtonTheme.Secondary : ButtonTheme.Transparent}
            >
                {lgUp && t('event:comments.title')}
                {Boolean(props.viewable && total) && (
                    <Badge className={styles.badge} small={!lgUp}>
                        {total}
                    </Badge>
                )}
            </Button>
            {Boolean(comments || !props.viewable) && (
                <SidePanel
                    className={styles.sidePanel}
                    onClose={closeSidePanel}
                    open={sidePanelOpen}
                    right
                >
                    <header className={styles.header}>
                        <div className={styles.group}>
                            <Button
                                iconName="x"
                                iconSize={3}
                                onClick={closeSidePanel}
                                theme={ButtonTheme.Transparent}
                            />
                            <Text
                                className={styles.title}
                                element="h1"
                                type="h4"
                                weight="600"
                                noMargin
                                noWrap
                                notReponsive
                            >
                                {t('event:comments.title')}
                            </Text>
                        </div>
                        {comments && (
                            <Button
                                iconName="arrows-clockwise"
                                iconSize={mdUp ? undefined : 2}
                                loading={commentsQuery.fetching}
                                onClick={executeCommentsQuery}
                                theme={ButtonTheme.Transparent}
                            >
                                {mdUp && t('common:refresh')}
                            </Button>
                        )}
                    </header>
                    <div ref={body} className={styles.body}>
                        <div className={styles.comments}>
                            {comments ? (
                                comments.map(comment => (
                                    <Comment
                                        key={comment.id}
                                        comment={comment}
                                        eventId={props.eventId}
                                    />
                                ))
                            ) : (
                                <MockComments eventId={props.eventId} />
                            )}
                        </div>
                        {comments && (
                            <LoadMoreButton
                                className={styles.loadMoreButton}
                                from={1}
                                loading={commentsQuery.fetching}
                                onClick={onLoadMore}
                                to={comments.length}
                                total={total}
                            />
                        )}
                        {!props.viewable && (
                            <div className={styles.overlay}>
                                <Text
                                    align="center"
                                    element="div"
                                    type="h4"
                                    weight="600"
                                >
                                    {t('event:comments.overlay')}
                                </Text>
                            </div>
                        )}
                    </div>
                    {comments && (
                        <footer className={styles.footer}>
                            <CommentForm eventId={props.eventId} />
                        </footer>
                    )}
                </SidePanel>
            )}
        </>
    )
}

export default Comments
