'use client'

import { FC, ReactNode, ReactPortal, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

interface Props {
    children: ReactNode
    onCreated?: () => void
}

const Portal: FC<Props> = props => {
    const [portal, setPortal] = useState<ReactPortal | null>(null)

    useEffect(() => {
        setPortal(createPortal(props.children, document.body))
    }, [props.children])

    useEffect(() => {
        if (portal && props.onCreated) {
            props.onCreated()
        }
    }, [portal, props])

    return portal
}

export default Portal
