/* istanbul ignore file */
'use client'

import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { AuthenticationMethod } from '../../../lib/graphql/generated/types'
import { useRouter, useTranslation } from '../../../lib/hooks'
import { selectOperations } from '../../../lib/selectors'
import { BookingsQueryEvent } from '../../../lib/types'
import { Button, Flyout, Icon, Link } from '../../base'
import BookingsButton from '../bookingsButton'
import DeregisterButton from './deregisterButton'
import styles from './eventActions.module.css'
import OnlineEventButton from './onlineEventButton'

interface Props {
    className?: string
    event: BookingsQueryEvent
    loading?: boolean
    moreOptionsClassName?: string
    onInViewChange?: (inView: boolean) => void
    openBookings: () => void
    viewerAuthenticationMethod?: AuthenticationMethod
}

const EventActions: FC<Props> = props => {
    const { t } = useTranslation()
    const router = useRouter()

    const { ref } = useInView({
        skip: !props.onInViewChange,
        onChange: props.onInViewChange,
    })

    const bookings = props.event.viewerParticipant?.bookings ?? []

    const cancellation =
        props.event.viewerRegistration?.cancellation &&
        !props.event.viewerParticipant?.cancellation?.id

    const singleWaitinglistOperation = useMemo(() => {
        const operations = selectOperations(props.event)

        return operations.length === 1
            ? operations[0].viewerBookable?.waitinglist
            : false
    }, [props.event])

    const moreLinks = useMemo(() => {
        const links = []

        if (
            props.event.authenticationMethods.includes(
                AuthenticationMethod.PublicAccess
            ) &&
            props.event.authenticationMethods.includes(
                AuthenticationMethod.PersonalUrl
            )
        ) {
            links.push({
                href: `/event/${props.event.key}/purl`,
                title: t('event:alreadyRegistered'),
            })
        }

        if (
            props.viewerAuthenticationMethod !==
                AuthenticationMethod.AccessCode &&
            props.event.authenticationMethods.includes(
                AuthenticationMethod.AccessCode
            )
        ) {
            links.push({
                href: `/login?from=${router.pathname}`,
                title: t('event:enterAccessCode'),
            })
        }

        return links
    }, [
        props.event.authenticationMethods,
        props.event.key,
        props.viewerAuthenticationMethod,
        router.pathname,
        t,
    ])

    if (bookings.length) {
        return (
            <div ref={ref} className={clsx(styles.root, props.className)}>
                <BookingsButton
                    bookings={bookings}
                    openBookings={props.openBookings}
                />
                <OnlineEventButton event={props.event} />
            </div>
        )
    }

    return (
        <div ref={ref} className={clsx(styles.root, props.className)}>
            <Button href={`/event/${props.event.key}/register`}>
                {singleWaitinglistOperation
                    ? t('event:putOnWaitinglist')
                    : t('event:register')}
            </Button>
            {cancellation && <DeregisterButton eventId={props.event.id} />}
            {moreLinks.length === 1 && (
                <Link
                    className={clsx(styles.link, props.moreOptionsClassName)}
                    href={moreLinks[0].href}
                    iconName="caret-right"
                    iconSide="right"
                    underline
                >
                    {moreLinks[0].title}
                </Link>
            )}
            {moreLinks.length > 1 && (
                <Flyout
                    className={props.moreOptionsClassName}
                    toggle={
                        <>
                            <span className={styles.moreOptions}>
                                {t('event:moreOptions')}
                            </span>
                            <Icon name="caret-down" />
                        </>
                    }
                    toggleClassName={styles.toggle}
                    list
                >
                    {moreLinks.map((link, i) => (
                        <Link key={i} href={link.href} noUnderline>
                            {link.title}
                        </Link>
                    ))}
                </Flyout>
            )}
        </div>
    )
}

export default EventActions
