'use client'

import { useCallback, useContext, useMemo } from 'react'
import { IconName } from '../../../components/client'
import { AppContext } from '../../providers'
import { AppNotificationType } from '../../types'

interface Options {
    modal?: boolean
    modalButtonText?: string
    modalIconName?: IconName
    onClosed?: () => void
}

const useNotification = () => {
    const { setNotifications } = useContext(AppContext)

    const addNotification = useCallback(
        (message: string, type: AppNotificationType, options?: Options) => {
            setNotifications(prev => {
                const nextId = prev.length ? prev[prev.length - 1].id + 1 : 1

                return [
                    ...prev,
                    {
                        id: nextId,
                        message,
                        type,
                        ...options,
                    },
                ]
            })
        },
        [setNotifications]
    )

    const inform = useCallback(
        (message: string, options?: Options) => {
            addNotification(message, AppNotificationType.Information, options)
        },
        [addNotification]
    )

    const confirm = useCallback(
        (message: string, options?: Options) => {
            addNotification(message, AppNotificationType.Confirmation, options)
        },
        [addNotification]
    )

    const alert = useCallback(
        (message: string, options?: Options) => {
            addNotification(message, AppNotificationType.Alert, options)
        },
        [addNotification]
    )

    return useMemo(
        () => ({
            inform,
            confirm,
            alert,
        }),
        [alert, confirm, inform]
    )
}

export default useNotification
