/* istanbul ignore file */
'use client'

import { FC } from 'react'
import Participant from './participant'

const MockParticipants: FC = () => (
    <>
        <Participant
            participant={{
                id: '1',
                firstName: 'Hans',
                lastName: 'Peter',
            }}
        />
        <Participant
            participant={{
                id: '2',
                firstName: 'Ueli',
                lastName: 'Meier',
            }}
        />
        <Participant
            participant={{
                id: '3',
                firstName: 'Tom',
                lastName: 'Anderson',
            }}
        />
        <Participant
            participant={{
                id: '4',
                firstName: 'Giovanni',
                lastName: 'Pronto',
            }}
        />
    </>
)

export default MockParticipants
