import { defaultLocale, locales } from '../../i18n'

const extractLocale = (pathname: string) => {
    const segments = pathname.replace(/^\/+/, '').split('/')
    const [segment] = segments

    if (segments.length === 1) {
        if (locales.includes(segment)) {
            return {
                locale: segment,
                pathname: '/',
            }
        } else {
            return {
                locale: defaultLocale,
                pathname: `/${segment}`,
            }
        }
    }

    if (locales.includes(segment)) {
        return {
            locale: segment,
            pathname: `/${segments.slice(1).join('/')}`,
        }
    } else {
        return {
            locale: defaultLocale,
            pathname: `/${segments.join('/')}`,
        }
    }
}

export default extractLocale
