'use client'

import { PhosphorIcon } from '@phosphor-icons/core'
import clsx from 'clsx'
import { FC, MouseEvent } from 'react'
import styles from './icon.module.css'

export type IconName = PhosphorIcon['name']

export interface Props {
    className?: string
    name: IconName
    onClick?: (e: MouseEvent) => void
    size?: 1 | 2 | 3 | 4 | 5 | 6
}

const Icon: FC<Props> = props => (
    <i
        className={clsx(
            'ph',
            `ph-${props.name}`,
            styles.icon,
            props.size && styles[`s${props.size}`],
            props.className
        )}
        onClick={props.onClick}
    />
)

export default Icon
