/* istanbul ignore file */
import { FC } from 'react'
import { Card, Tab, Tabs } from '../base'

const TabsExample: FC = () => (
    <Card title="Tabs">
        <Tabs>
            <Tab label="Tab 1">Tab 1 content</Tab>
            <Tab label="Tab 2">Tab 2 content</Tab>
            <Tab label="Tab 3">Tab 3 content</Tab>
            <Tab label="Tab 4">Tab 4 content</Tab>
            <Tab label="Tab 5">Tab 5 content</Tab>
        </Tabs>
    </Card>
)

export default TabsExample
