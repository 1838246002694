'use client'

import dayjs from 'dayjs'
import { FC, useCallback } from 'react'
import { CategoriesAndEventTypesQuery } from '../../../lib/graphql/generated/types'
import { useRouter, useTranslation } from '../../../lib/hooks'
import {
    Button,
    ButtonTheme,
    DateFilter,
    DateFormat,
    TagButton,
} from '../../base'
import styles from './searchFilters.module.css'

interface Props {
    categories: NonNullable<
        CategoriesAndEventTypesQuery['viewer']
    >['categories']
    eventTypes: NonNullable<
        CategoriesAndEventTypesQuery['viewer']
    >['eventTypes']
    // Format: D.M.YYYY
    highlightedDates?: Set<string>
}

const SearchFilters: FC<Props> = props => {
    const { t } = useTranslation()
    const router = useRouter()

    const reset = useCallback(() => {
        const params = new URLSearchParams(router.searchParams)
        params.delete('q')
        params.delete('from')
        params.delete('to')
        params.delete('category')
        params.delete('type')
        params.delete('promoted')
        params.delete('page')

        router.pushSearchParams(params)
    }, [router])

    const filterSelected = useCallback(
        (key: string, value: string) => {
            const filter = router.searchParams.getAll(key)

            if (!filter?.length) {
                return false
            } else if (filter.length === 1) {
                return filter[0] === value
            } else {
                return filter.includes(value)
            }
        },
        [router.searchParams]
    )

    const onDateChange = useCallback(
        (from?: Date, to?: Date) => {
            const params = new URLSearchParams(router.searchParams)
            params.delete('page')

            if (from) {
                params.set('from', dayjs(from).format(DateFormat.Short))
            } else {
                params.delete('from')
            }

            if (to) {
                params.set('to', dayjs(to).format(DateFormat.Short))
            } else {
                params.delete('to')
            }

            router.pushSearchParams(params)
        },
        [router]
    )

    const onFilterClick = useCallback(
        (data?: any) => {
            const [[key, value]] = Object.entries<string>(data)

            const params = new URLSearchParams(router.searchParams)
            params.delete('page')
            params.delete(key)

            const filters = router.searchParams.getAll(key)

            if (!filters?.length) {
                params.append(key, value)
            } else if (filters.length === 1) {
                const [filter] = filters

                if (filter !== value) {
                    params.append(key, filter)
                    params.append(key, value)
                }
            } else {
                if (filters.includes(value)) {
                    const filtersWithoutValue = filters.filter(v => v !== value)

                    if (filtersWithoutValue.length === 1) {
                        params.set(key, filtersWithoutValue[0])
                    } else {
                        filtersWithoutValue.forEach(filter =>
                            params.append(key, filter)
                        )
                    }
                } else {
                    filters.forEach(filter => params.append(key, filter))
                    params.append(key, value)
                }
            }

            router.pushSearchParams(params)
        },
        [router]
    )

    const fromDate = router.searchParams.has('from')
        ? dayjs(router.searchParams.get('from'), DateFormat.Short).toDate()
        : undefined

    const toDate = router.searchParams.has('to')
        ? dayjs(router.searchParams.get('to'), DateFormat.Short).toDate()
        : undefined

    const filtersSelected =
        router.searchParams.has('from') ||
        router.searchParams.has('to') ||
        router.searchParams.has('category') ||
        router.searchParams.has('type') ||
        router.searchParams.has('promoted')

    return (
        <div className={styles.root}>
            <DateFilter
                from={fromDate}
                highlightedDates={props.highlightedDates}
                onChange={onDateChange}
                to={toDate}
                range
            />
            <TagButton
                data={{ promoted: 'true' }}
                onClick={onFilterClick}
                selected={filterSelected('promoted', 'true')}
            >
                {t('common:promoted')}
            </TagButton>
            {props.categories?.map(category => (
                <TagButton
                    key={category.id}
                    data={{ category: category.name }}
                    onClick={onFilterClick}
                    selected={filterSelected('category', category.name)}
                >
                    {category.name}
                </TagButton>
            ))}
            {props.eventTypes?.map(eventType => (
                <TagButton
                    key={eventType.id}
                    data={{ type: eventType.name }}
                    onClick={onFilterClick}
                    selected={filterSelected('type', eventType.name)}
                >
                    {eventType.name}
                </TagButton>
            ))}
            {filtersSelected && (
                <Button onClick={reset} theme={ButtonTheme.Transparent}>
                    {t('common:reset')}
                </Button>
            )}
        </div>
    )
}

export default SearchFilters
