/* istanbul ignore file */
'use client'

import clsx from 'clsx'
import { Form, Formik } from 'formik'
import { FC } from 'react'
import { Button, ButtonTheme, Card, Checkbox } from '../client'
import styles from './examples.module.css'
import useOnSubmit from './useOnSubmit'

const ButtonExample: FC = () => {
    const { onSubmit } = useOnSubmit()

    return (
        <Formik
            initialValues={{
                disabled: false,
                loading: false,
                darkBackground: false,
            }}
            onSubmit={onSubmit}
        >
            {({ values: { disabled, loading, darkBackground } }) => (
                <Card
                    className={clsx(darkBackground && styles.darkBackground)}
                    contentClassName={styles.flexCol}
                    title="Button"
                >
                    <div className={styles.flexCol}>
                        <Form className={styles.flexWrap} noValidate>
                            <Checkbox name="disabled">Disabled</Checkbox>
                            <Checkbox name="loading">Loading</Checkbox>
                            <Checkbox name="darkBackground">
                                Dark background
                            </Checkbox>
                        </Form>
                        <Button
                            darkBackground={darkBackground}
                            disabled={disabled}
                            iconName="cat"
                            loading={loading}
                        >
                            Primary
                        </Button>
                        <Button
                            darkBackground={darkBackground}
                            disabled={disabled}
                            loading={loading}
                            theme={ButtonTheme.Secondary}
                        >
                            Secondary
                        </Button>
                        <Button
                            darkBackground={darkBackground}
                            disabled={disabled}
                            loading={loading}
                            theme={ButtonTheme.Confirm}
                        >
                            Confirm
                        </Button>
                        <Button
                            darkBackground={darkBackground}
                            disabled={disabled}
                            loading={loading}
                            theme={ButtonTheme.Alert}
                        >
                            Alert
                        </Button>
                        <Button
                            darkBackground={darkBackground}
                            disabled={disabled}
                            loading={loading}
                            theme={ButtonTheme.Cancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            darkBackground={darkBackground}
                            disabled={disabled}
                            href="/"
                            loading={loading}
                            theme={ButtonTheme.Transparent}
                        >
                            Transparent
                        </Button>
                    </div>
                </Card>
            )}
        </Formik>
    )
}

export default ButtonExample
