import { createInstance } from 'i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next/initReactI18next'

export const defaultLocale = 'de'
export const locales = [defaultLocale, 'en', 'fr', 'it']

export const defaultNamespace = 'common'
export const namespaces = [
    defaultNamespace,
    'event',
    'groupBooking',
    'home',
    'invitee',
    'login',
    'preview',
    'purl',
    'registration',
    'user',
    'wizard',
]

const createI18nInstance = () =>
    createInstance().use(
        resourcesToBackend(
            (locale: string, namespace: string) =>
                import(`../locales/${locale}/${namespace}.json`)
        )
    )

const baseOptions = {
    supportedLngs: locales,
    fallbackLng: defaultLocale,
    ns: namespaces,
    defaultNS: defaultNamespace,
    interpolation: {
        escapeValue: false,
    },
}

// TODO: Is there a way to only load required translations?
// https://locize.com/blog/next-app-dir-i18n/
export const createI18nClientInstance = () =>
    createI18nInstance()
        .use(initReactI18next)
        .init({
            ...baseOptions,
            preload: typeof window === 'undefined' ? locales : false,
        })

export const getTranslation = async (locale: string) => {
    const i18n = createI18nInstance()

    await i18n.init({
        ...baseOptions,
        lng: locale,
    })

    return i18n
}

// {
//     pages: {
//         '*': ['common'],
//         '/': ['home'],
//         '/event/[eventKey]': ['event', 'groupBooking'],
//         '/event/[eventKey]/purl': ['purl'],
//         '/event/[eventKey]/register': ['event', 'registration', 'groupBooking'],
//         '/event/[eventKey]/invitee/response': ['invitee'],
//         '/login': ['login'],
//         '/login/create': ['login', 'user'],
//         '/login/reset': ['login'],
//         '/preview/stream': ['preview'],
//         '/profile/[userId]': ['user'],
//         '/profile/edit': ['user'],
//         '/type/[eventTypeKey]': ['home'],
//         '/wizard': ['wizard'],
//     },
// }
