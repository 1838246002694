'use client'

import { ChangeEvent, FC, useCallback, useRef } from 'react'
import { Button, ButtonTheme } from '../../base'
import styles from './profileMastheadImage.module.css'

interface Props {
    alt: string
    onChange?: (src: string | null) => void
    src?: string | null
}

const ProfileMastheadImage: FC<Props> = props => {
    const hiddenFileInput = useRef<HTMLInputElement>(null)

    const onEdit = useCallback(() => {
        hiddenFileInput.current?.click()
    }, [])

    const onDelete = useCallback(() => {
        props.onChange?.call(undefined, null)
    }, [props.onChange])

    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const file = e.target?.files?.length && e.target.files[0]

            if (file) {
                const reader = new FileReader()
                reader.readAsDataURL(file)

                reader.onload = () => {
                    /* istanbul ignore next */
                    if (typeof reader.result === 'string') {
                        props.onChange?.call(undefined, reader.result)
                    }
                }
            }
        },
        [props.onChange]
    )

    return (
        <>
            {props.src && <img alt={props.alt} src={props.src} />}
            {props.onChange && (
                <div className={styles.actions}>
                    <input
                        ref={hiddenFileInput}
                        accept="image/*"
                        className={styles.hiddenFileInput}
                        onChange={onChange}
                        type="file"
                    />
                    <Button
                        iconName="pencil"
                        iconSize={3}
                        onClick={onEdit}
                        theme={ButtonTheme.Transparent}
                    />
                    <Button
                        iconName="x"
                        iconSize={3}
                        onClick={onDelete}
                        theme={ButtonTheme.Transparent}
                    />
                </div>
            )}
        </>
    )
}

export default ProfileMastheadImage
