'use client'

import clsx from 'clsx'
import { FC } from 'react'
import Link from '../link'
import styles from './avatar.module.css'

interface Props {
    className?: string
    firstName: string
    href?: string
    image?: string | null
    lastName: string
    onClick?: () => void
    size?: number
}

const Avatar: FC<Props> = props => {
    const avatar = (
        <div
            className={clsx(
                styles.root,
                styles.shape,
                props.onClick && styles.clickable,
                props.className
            )}
            onClick={!props.href ? props.onClick : undefined}
            style={
                props.size
                    ? {
                          width: props.size,
                          height: props.size,
                      }
                    : undefined
            }
        >
            {props.image ? (
                <img
                    alt={`${props.firstName} ${props.lastName}`}
                    className={clsx(styles.image, styles.shape)}
                    src={props.image}
                />
            ) : (
                <svg className={styles.shape} viewBox="0 0 100 100">
                    <text className={styles.initials} x="50%" y="50%">
                        {props.firstName.slice(0, 1)}
                        {props.lastName.slice(0, 1)}
                    </text>
                </svg>
            )}
        </div>
    )

    return props.href ? <Link href={props.href}>{avatar}</Link> : avatar
}

export default Avatar
