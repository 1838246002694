import clsx from 'clsx'
import { upperFirst } from 'lodash'
import { createElement, FC, ReactNode } from 'react'
import styles from './text.module.css'

type Align = 'left' | 'center' | 'right' | 'justify'

type Element = 'div' | 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

type Type =
    | 'standard'
    | 'small'
    | 'smaller'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'd1'
    | 'd2'
    | 'd3'

type Weight = '300' | '400' | '500' | '600' | '700'

interface Props {
    align?: Align
    children?: ReactNode
    className?: string
    element?: Element
    lgType?: Type
    mdType?: Type
    noMargin?: boolean
    noWrap?: boolean
    notReponsive?: boolean
    smType?: Type
    type?: Type
    ulType?: Type
    weight?: Weight
    xlType?: Type
}

const Text: FC<Props> = props =>
    createElement(
        props.element || 'p',
        {
            className: clsx(
                props.element?.startsWith('h') && styles[props.element],
                props.type && styles[props.type],
                props.smType && styles[`sm${upperFirst(props.smType)}`],
                props.mdType && styles[`md${upperFirst(props.mdType)}`],
                props.lgType && styles[`lg${upperFirst(props.lgType)}`],
                props.xlType && styles[`xl${upperFirst(props.xlType)}`],
                props.ulType && styles[`ul${upperFirst(props.ulType)}`],
                props.notReponsive && styles.notResponsive,
                props.align && styles[props.align],
                props.weight && styles[`w${props.weight}`],
                props.noMargin && styles.noMargin,
                props.noWrap && styles.noWrap,
                props.className
            ),
        },
        props.children
    )

export default Text
