'use client'

import clsx from 'clsx'
import { FC, useCallback } from 'react'
import { Button, ButtonTheme } from '../button'
import Text from '../text'
import styles from './pagination.module.css'

interface Props {
    className?: string
    itemsPerPage: number
    onChange: (page: number) => void
    page: number
    totalItems: number
}

const Pagination: FC<Props> = props => {
    const previousPage = Math.max(props.page - 1, 1)
    const lastPage = Math.ceil(props.totalItems / props.itemsPerPage)
    const nextPage = Math.min(props.page + 1, lastPage)

    const onPreviousPage = useCallback(() => {
        props.onChange(props.page - 1)
    }, [props])

    const onFirstPage = useCallback(() => {
        props.onChange(1)
    }, [props])

    const onLastPage = useCallback(() => {
        props.onChange(lastPage)
    }, [lastPage, props])

    const onNextPage = useCallback(() => {
        props.onChange(props.page + 1)
    }, [props])

    return (
        <div className={clsx(styles.root, props.className)}>
            <Button
                disabled={props.page <= 1}
                iconName="caret-left"
                onClick={onPreviousPage}
                theme={ButtonTheme.Transparent}
            />
            {props.page > 1 && (
                <>
                    <Button
                        onClick={onFirstPage}
                        theme={ButtonTheme.Transparent}
                    >
                        1
                    </Button>
                    {1 < previousPage - 1 && <Text element="span">...</Text>}
                </>
            )}
            {previousPage !== props.page && previousPage !== 1 && (
                <Button
                    onClick={onPreviousPage}
                    theme={ButtonTheme.Transparent}
                >
                    {previousPage.toString()}
                </Button>
            )}
            <Text className={styles.current} element="span">
                {props.page}
            </Text>
            {nextPage !== props.page && nextPage !== lastPage && (
                <Button onClick={onNextPage} theme={ButtonTheme.Transparent}>
                    {nextPage.toString()}
                </Button>
            )}
            {props.page < lastPage && (
                <>
                    {lastPage > nextPage + 1 && <Text element="span">...</Text>}
                    <Button
                        onClick={onLastPage}
                        theme={ButtonTheme.Transparent}
                    >
                        {lastPage.toString()}
                    </Button>
                </>
            )}
            <Button
                disabled={props.page >= lastPage}
                iconName="caret-right"
                onClick={onNextPage}
                theme={ButtonTheme.Transparent}
            />
        </div>
    )
}

export default Pagination
