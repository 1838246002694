'use client'

import clsx from 'clsx'
import dayjs from 'dayjs'
import { FC, Fragment, useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useTranslation } from '../../../../lib/hooks'
import { EventQueryEvent } from '../../../../lib/types'
import { dayjsFrom, venueGoogleMapsUrl } from '../../../../lib/utils'
import {
    DateFormat,
    FormattedCurrency,
    FormattedDate,
    Icon,
    Link,
    Text,
} from '../../../base'
import InformationItem from '../informationItem'
import styles from './eventDetails.module.css'

export interface Props
    extends Pick<
        EventQueryEvent,
        'capacity' | 'lowestPrice' | 'operations' | 'venue'
    > {
    endDate?: dayjs.Dayjs
    online?: boolean
    startDate?: dayjs.Dayjs
}

const EventDetails: FC<Props> = props => {
    const { t, locale } = useTranslation()

    const informationItems = useMemo(() => {
        const items = []

        const online =
            props.online ||
            props.operations?.some(operation => operation.online)

        if (online) {
            const now = dayjs()

            const live =
                (props.online &&
                    now.isBetween(props.startDate, props.endDate)) ||
                props.operations?.some(
                    operation =>
                        operation.online &&
                        operation.operationDates.some(
                            ({ startDate, endDate }) =>
                                now.isBetween(
                                    dayjsFrom(startDate),
                                    dayjsFrom(endDate)
                                )
                        )
                )

            items.push(
                <InformationItem
                    className={clsx(live && styles.live)}
                    media={
                        <Icon name={live ? 'video-camera' : 'globe'} size={4} />
                    }
                >
                    {t(`event:onlineEvent.${live ? 'live' : 'notLive'}`)}
                </InformationItem>
            )
        }

        if (props.startDate) {
            items.push(
                <InformationItem
                    media={<Icon name="calendar-blank" size={4} />}
                >
                    <FormattedDate
                        endDate={props.endDate}
                        format={DateFormat.Medium}
                        startDate={props.startDate}
                        withTime
                    />
                </InformationItem>
            )
        }

        if (props.venue) {
            items.push(
                <InformationItem media={<Icon name="map-pin" size={4} />}>
                    <div>{props.venue.name}</div>
                    <Link
                        href={venueGoogleMapsUrl(props.venue)}
                        iconName="arrow-square-out"
                        iconSide="right"
                        underlineOnHover
                    >
                        {t('event:information.showOnMap')}
                    </Link>
                </InformationItem>
            )
        }

        if (props.capacity?.available) {
            items.push(
                <InformationItem media={<Icon name="users-three" size={4} />}>
                    {t('event:capacity', { count: props.capacity.available })}
                </InformationItem>
            )
        }

        if (typeof props.lowestPrice === 'number') {
            items.push(
                <InformationItem media={<Icon name="ticket" size={4} />}>
                    <Trans
                        components={[
                            <FormattedCurrency
                                key={0}
                                amount={props.lowestPrice}
                                currency="CHF"
                            />,
                        ]}
                        i18nKey="common:priceFrom"
                        lang={locale}
                    />
                </InformationItem>
            )
        }

        return items
    }, [
        locale,
        props.capacity?.available,
        props.endDate,
        props.lowestPrice,
        props.online,
        props.operations,
        props.startDate,
        props.venue,
        t,
    ])

    if (!informationItems.length) {
        return
    }

    return (
        <div className={styles.root}>
            <Text className={styles.title} element="h3" weight="600">
                {t('event:information.details')}
            </Text>
            {informationItems.map((item, i) => (
                <Fragment key={i}>{item}</Fragment>
            ))}
        </div>
    )
}

export default EventDetails
