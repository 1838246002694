import { BookingFragment } from '../../../../../../lib/graphql/generated/types'
import { Values } from '../../selection'

export const bookingToValues = (booking?: BookingFragment | null): Values => {
    if (!booking) {
        return {}
    }

    return booking.bookingOperations.reduce((acc: Values, bookingOperation) => {
        const bookingOperations = acc[bookingOperation.operation.id] ?? []

        const newGroupBooking =
            bookingOperation.groupBooking?.primaryParticipant &&
            bookingOperation.groupBooking.participantCount === 1
                ? {
                      name: bookingOperation.groupBooking.name,
                      description: bookingOperation.groupBooking.description,
                      public: bookingOperation.groupBooking.public,
                  }
                : undefined

        bookingOperations.push({
            inputFieldValues: bookingOperation.inputFieldValues.reduce(
                (_acc, inputFieldValue) => {
                    let value

                    switch (inputFieldValue.__typename) {
                        case 'BooleanInputFieldValue':
                            value = inputFieldValue.selected
                            break
                        case 'DateInputFieldValue':
                            value = inputFieldValue.date ?? ''
                            break
                        case 'ListStringInputFieldValue':
                            value = inputFieldValue.values ?? []
                            break
                        case 'StringInputFieldValue':
                            value = inputFieldValue.value ?? ''
                            break
                    }

                    return {
                        ..._acc,
                        [inputFieldValue.inputField.identifier]: value,
                    }
                },
                {}
            ),
            groupBookingId: newGroupBooking
                ? undefined
                : bookingOperation.groupBooking?.id,
            newGroupBooking,
        })

        return {
            ...acc,
            [bookingOperation.operation.id]: bookingOperations,
        }
    }, {})
}

export default bookingToValues
