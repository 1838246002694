import dayjs from 'dayjs'

const dayjsFrom = (date: any, timezone?: string) => {
    if (!date) {
        return
    }

    if (dayjs.isDayjs(date)) {
        return date
    }

    return Number.isInteger(date) && date.toString().length <= 10
        ? dayjs.unix(date).tz(timezone)
        : dayjs(date).tz(timezone)
}

export default dayjsFrom
