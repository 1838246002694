'use client'

import clsx from 'clsx'
import { Form, Formik } from 'formik'
import { FC, useCallback } from 'react'
import { useTranslation, useYup } from '../../../../lib/hooks'
import {
    Button,
    ButtonGroup,
    DatePickerType,
    Input,
    InputGroup,
    Radio,
} from '../../../base'
import { StepProps } from '../../wizard'
import wizardStyles from '../../wizard.module.css'

export interface Values {
    endDate?: Date
    recurring: string
    registrationDeadline?: Date
    startDate?: Date
}

const DateTimeStep: FC<StepProps> = props => {
    const { t } = useTranslation()
    const yup = useYup()

    const onSubmit = useCallback(
        async (values: Values) => {
            props.mergeValues.call(undefined, values)
            props.nextStep.call(undefined)
        },
        [props.mergeValues, props.nextStep]
    )

    return (
        <Formik<Values>
            initialValues={{
                recurring: props.values.recurring ?? 'once',
                startDate: props.values.startDate,
                endDate: props.values.endDate,
                registrationDeadline: props.values.registrationDeadline,
            }}
            onSubmit={onSubmit}
            validationSchema={yup.object().shape({
                recurring: yup.string().oneOf(['once', 'weekly']).required(),
                startDate: yup.date().required(),
                endDate: yup.date().required(),
                registrationDeadline: yup.date().required(),
            })}
            enableReinitialize
            validateOnMount
        >
            {formik => (
                <Form className={wizardStyles.form} noValidate>
                    <div className={wizardStyles.fields}>
                        <div
                            className={clsx(
                                wizardStyles.radioButtons,
                                wizardStyles.colspan
                            )}
                        >
                            <InputGroup name="recurring">
                                <Radio name="recurring" value="once">
                                    {t('wizard:form.once')}
                                </Radio>
                                <Radio name="recurring" value="weekly">
                                    {t('wizard:form.weekly')}
                                </Radio>
                            </InputGroup>
                        </div>
                        <Input
                            datePickerType={DatePickerType.DateTime}
                            label={
                                formik.values.recurring === 'once'
                                    ? t('wizard:form.startDate')
                                    : t('wizard:form.weeklyStartDate')
                            }
                            name="startDate"
                            type="date"
                            required
                        />
                        <Input
                            datePickerType={DatePickerType.DateTime}
                            label={
                                formik.values.recurring === 'once'
                                    ? t('wizard:form.endDate')
                                    : t('wizard:form.weeklyEndDate')
                            }
                            name="endDate"
                            type="date"
                            required
                        />
                        <Input
                            label={t('wizard:form.registrationDeadline')}
                            name="registrationDeadline"
                            type="date"
                            required
                        />
                    </div>
                    <ButtonGroup className={wizardStyles.actions}>
                        <Button disabled={!formik.isValid} type="submit">
                            {t('common:continue')}
                        </Button>
                    </ButtonGroup>
                </Form>
            )}
        </Formik>
    )
}

export default DateTimeStep
