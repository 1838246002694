/* istanbul ignore file */
'use server'

import { getCookies } from 'next-client-cookies/server'
import { tokenKey } from '../constants'
import {
    CreateEventMutation,
    CreateEventMutationVariables,
} from '../graphql/generated/types'
import getGraphqlClient from '../graphql/getGraphqlClient'
import createEventMutation from '../graphql/mutations/createEventMutation.graphql'
import { getTranslation } from '../i18n'
import { ServerActionResult } from '../types'

interface Args extends CreateEventMutationVariables {
    locale: string
}

const createEvent = async ({
    locale,
    ...variables
}: Args): Promise<ServerActionResult> => {
    try {
        const { data, error } = await getGraphqlClient()
            .mutation<CreateEventMutation, CreateEventMutationVariables>(
                createEventMutation,
                {
                    token: getCookies().get(tokenKey),
                    ...variables,
                }
            )
            .toPromise()

        if (error) {
            throw error
        } else if (data?.viewer?.createEvent?.inputError) {
            throw Error(data.viewer.createEvent.inputError.message)
        }
    } catch (e) {
        console.error(e)
        const { t } = await getTranslation(locale)

        return {
            error: t('common:error.internal'),
        }
    }
}

export default createEvent
