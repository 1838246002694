/* istanbul ignore file */
'use client'

import { Form, Formik } from 'formik'
import { FC } from 'react'
import { useYup } from '../../lib/hooks'
import { Button, ButtonGroup, Card, Select, SelectOption } from '../client'
import styles from './examples.module.css'
import useOnSubmit from './useOnSubmit'

const SelectExample: FC = () => {
    const yup = useYup()
    const { onSubmit } = useOnSubmit()

    return (
        <Card title="Select">
            <Formik
                initialValues={{
                    singleOption: '',
                    multipleOptions: [],
                }}
                onSubmit={onSubmit}
                validationSchema={yup.object().shape({
                    singleOption: yup.string().required(),
                    multipleOptions: yup.array().min(1),
                })}
            >
                {formik => (
                    <Form className={styles.flexCol} noValidate>
                        <Select
                            label="Single option"
                            name="singleOption"
                            placeholder="Select one ..."
                            required
                        >
                            <SelectOption>Option 1</SelectOption>
                            <SelectOption disabled>Option 2</SelectOption>
                            <SelectOption>Option 3</SelectOption>
                        </Select>
                        <Select
                            label="Multiple options"
                            name="multipleOptions"
                            placeholder="Select multiple ..."
                            required
                        >
                            <SelectOption>Option 1</SelectOption>
                            <SelectOption>Option 2</SelectOption>
                            <SelectOption>Option 3</SelectOption>
                        </Select>
                        <ButtonGroup>
                            <Button loading={formik.isSubmitting} type="submit">
                                Submit
                            </Button>
                        </ButtonGroup>
                    </Form>
                )}
            </Formik>
        </Card>
    )
}

export default SelectExample
