import * as cheerio from 'cheerio'
import { FC } from 'react'
import linkStyles from '../link/link.module.css'
import tableStyles from '../table/table.module.css'
import styles from './formattedHtml.module.css'

interface Props {
    className?: string
    html: string
}

const FormattedHtml: FC<Props> = props => {
    const $ = cheerio.load(props.html)

    $('p')
        .filter((_i, element) => !$(element).text().trim())
        .remove()

    $('a')
        .addClass(linkStyles.root)
        .addClass(linkStyles.underlineOnHover)
        .wrapInner(`<span class="${linkStyles.text}" />`)

    $('ul,ol').addClass(styles.list)

    $('figure.table').each((_i, element) => {
        $(element).replaceWith(
            $(`<div class="${tableStyles.root}">
                <div class="${tableStyles.wrapper}">
                    ${$(element).html()}
              </div>
            </div>`)
        )
    })

    return (
        <div
            dangerouslySetInnerHTML={{ __html: $.html() }}
            className={props.className}
        />
    )
}

export default FormattedHtml
