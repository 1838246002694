'use client'

import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Container } from '../../../base'
import styles from './skeletonPage.module.css'

interface Props {
    noMasthead?: boolean
}

const SkeletonPage: FC<Props> = props => (
    <>
        <div className={styles.header} />
        <div className={styles.primaryNavigation} />
        {!props.noMasthead && <Skeleton className={styles.masthead} />}
        <Container className={styles.main}>
            <Skeleton className={styles.title} />
            <Skeleton className={styles.paragraph} count={2} />
            <Skeleton className={styles.paragraph} count={2} width="80%" />
            <Skeleton className={styles.paragraph} width="50%" />
        </Container>
    </>
)

export default SkeletonPage
