'use client'

import { Form, Formik } from 'formik'
import { FC, useCallback } from 'react'
import { authenticateWithUsernamePassword } from '../../../lib/actions'
import { useNotification, useTranslation, useYup } from '../../../lib/hooks'
import { withLocale } from '../../../lib/utils'
import {
    Button,
    ButtonGroup,
    ButtonGroupLayout,
    ButtonTheme,
    Input,
    Link,
} from '../../base'
import loginStyles from '../login.module.css'
import styles from './usernamePasswordForm.module.css'

interface Values {
    password: string
    username: string
}

interface Props {
    from: string
    onCancel?: () => void
    userRegistration?: boolean
}

const UsernamePasswordForm: FC<Props> = props => {
    const { t, locale } = useTranslation()
    const notification = useNotification()
    const yup = useYup()

    const onSubmit = useCallback(
        async (values: Values) => {
            const result = await authenticateWithUsernamePassword({
                locale,
                ...values,
            })

            if (result?.error) {
                notification.alert(result.error)
            } else {
                const url = new URL(window.location.href)
                url.pathname = withLocale(props.from, locale)
                url.search = ''

                window.location.href = url.href
            }
        },
        [locale, notification, props.from]
    )

    return (
        <Formik<Values>
            initialValues={{ username: '', password: '' }}
            onSubmit={onSubmit}
            validationSchema={yup.object().shape({
                username: yup.string().required(),
                password: yup.string().required(),
            })}
        >
            {formik => (
                <Form noValidate>
                    <div className={loginStyles.fields}>
                        <Input
                            label={t('login:usernamePassword.username')}
                            name="username"
                            required
                        />
                        <Input
                            label={t('login:usernamePassword.password')}
                            name="password"
                            type="password"
                            required
                        />
                    </div>
                    <ButtonGroup layout={ButtonGroupLayout.FullWidth}>
                        <Button loading={formik.isSubmitting} type="submit">
                            {t('common:logIn')}
                        </Button>
                        {props.onCancel && (
                            <Button
                                disabled={formik.isSubmitting}
                                onClick={props.onCancel}
                                theme={ButtonTheme.Secondary}
                            >
                                {t('common:cancel')}
                            </Button>
                        )}
                    </ButtonGroup>
                    <div className={styles.links}>
                        <Link
                            className={styles.link}
                            href={`/login/reset?from=${encodeURIComponent(props.from)}`}
                            noUnderline
                        >
                            {t('login:forgotPassword')}
                        </Link>
                        {props.userRegistration && (
                            <Link
                                className={styles.link}
                                href={`/login/create?from=${encodeURIComponent(props.from)}`}
                                noUnderline
                            >
                                {t('login:loginCreation.title')}
                            </Link>
                        )}
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default UsernamePasswordForm
