'use client'

import clsx from 'clsx'
import {
    FC,
    MouseEvent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import { createPortal } from 'react-dom'
import { useSwipeable } from 'react-swipeable'
import styles from './sidePanel.module.css'

export interface Props {
    children?: ReactNode
    className?: string
    onClose?: () => void
    onClosed?: () => void
    open?: boolean
    right?: boolean
}

const SidePanel: FC<Props> = props => {
    const dialog = useRef<HTMLDialogElement>(null)
    const backdrop = useRef<HTMLDivElement>(null)

    const handlers = useSwipeable({
        [props.right ? 'onSwipedRight' : 'onSwipedLeft']: props.onClose,
    })

    const [mounted, setMounted] = useState(false)

    useEffect(() => {
        if (props.open) {
            setMounted(true)

            // Start CSS opening animation after mounting
            setTimeout(() => {
                /* istanbul ignore next */
                if (dialog.current) {
                    dialog.current.inert = true // Prevents autofocus
                    dialog.current.show()
                    dialog.current.inert = false
                }
            }, 50)
        } else if (dialog.current?.open) {
            dialog.current?.close()

            // Unmount after CSS closing animation
            setTimeout(() => {
                setMounted(false)
                props.onClosed?.call(undefined)
            }, 350)
        }
    }, [props.onClosed, props.open])

    const onMouseDown = useCallback(
        (e: MouseEvent) => {
            /* istanbul ignore next */
            if (e.target === backdrop.current) {
                props.onClose?.call(undefined)
            }
        },
        [props.onClose]
    )

    if (!mounted) {
        return
    }

    return createPortal(
        <dialog ref={dialog} className={styles.root}>
            <div
                ref={backdrop}
                className={styles.backdrop}
                onMouseDown={onMouseDown}
            >
                <div
                    className={clsx(
                        styles.container,
                        props.right && styles.right,
                        props.className
                    )}
                    autoFocus
                    {...handlers}
                >
                    {props.children}
                </div>
            </div>
        </dialog>,
        document.body
    )
}

export default SidePanel
