'use client'

import { Formik } from 'formik'
import { FormikErrors } from 'formik/dist/types'
import { set } from 'lodash'
import { useCookies } from 'next-client-cookies'
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react'
import { tokenKey } from '../../../../lib/constants'
import {
    useCreateBookingMutation,
    useParticipantLoginMutation,
    useUpdateBookingMutation,
} from '../../../../lib/graphql/generated/hooks'
import {
    BookingFragment,
    BookingOperationCreateInput,
} from '../../../../lib/graphql/generated/types'
import {
    useNotification,
    useRouter,
    useTranslation,
    useViewerUser,
    useYup,
} from '../../../../lib/hooks'
import { RegisterQueryEvent } from '../../../../lib/types'
import decodeTokenExpiration from '../../../../lib/utils/decodeTokenExpiration'
import { Container, FormattedHtml } from '../../../base'
import Actions from '../actions'
import { getValidator } from '../utils'
import BookingSection from './bookingSection'
import Preselector from './preselector'
import styles from './selection.module.css'
import { bookingToValues, valuesToBookingOperations } from './utils'

export interface Values {
    [operationId: string]: Value[]
}

export interface Value
    extends Pick<
        BookingOperationCreateInput,
        'groupBookingId' | 'newGroupBooking'
    > {
    inputFieldValues: {
        [identifier: string]: string
    }
}

interface Props {
    booking?: BookingFragment | null
    embedded?: boolean
    event: RegisterQueryEvent
    eventKey: string
    executeRegisterQuery: () => void
    setStep: Dispatch<SetStateAction<number | undefined>>
}

const Selection: FC<Props> = props => {
    const { t, locale } = useTranslation()
    const notification = useNotification()
    const { refresh } = useRouter()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)
    const viewerUser = useViewerUser()
    const yup = useYup()
    const [, createBooking] = useCreateBookingMutation()
    const [, participantLogin] = useParticipantLoginMutation()
    const [, updateBooking] = useUpdateBookingMutation()

    const _updateBooking = useCallback(
        async (operations: BookingOperationCreateInput[]) => {
            if (!token || !props.booking) {
                throw Error(t('common:error.internal'))
            }

            const { data, error } = await updateBooking({
                token,
                eventId: props.event.id,
                bookingId: props.booking.id,
                operations,
            })

            if (error) {
                throw error
            }

            if (
                data?.viewer?.event?.viewerParticipant?.updateBooking
                    ?.inputError
            ) {
                throw Error(
                    data.viewer.event.viewerParticipant.updateBooking.inputError
                        .message
                )
            }

            props.setStep.call(undefined, 2)
        },
        [props.booking, props.event.id, props.setStep, t, token, updateBooking]
    )

    const _createBooking = useCallback(
        async (eventId: string, operations: BookingOperationCreateInput[]) => {
            const createBookingResult = await createBooking({
                token,
                locale,
                eventId,
                operations,
            })

            if (createBookingResult.error) {
                throw createBookingResult.error
            }

            if (
                createBookingResult.data?.viewer?.event?.createBooking
                    ?.inputError
            ) {
                throw Error(
                    createBookingResult.data.viewer.event.createBooking
                        .inputError.message
                )
            }

            const accessIdentifier =
                createBookingResult.data?.viewer?.event?.createBooking?.object
                    ?.participant.accessIdentifier

            const booking =
                createBookingResult.data?.viewer?.event?.createBooking?.object

            if (!accessIdentifier || !booking?.bookingNr) {
                throw Error(t('common:error.internal'))
            }

            // Skip token creation when already authenticated
            if (viewerUser) {
                // Mutation doesn't trigger event refetch for some reason
                // (not even with "additionalTypenames: ['Booking']")
                props.executeRegisterQuery.call(undefined)

                return
            }

            const participantLoginResult = await participantLogin({
                accessIdentifier,
            })

            if (participantLoginResult.error) {
                throw participantLoginResult.error
            }

            const _token =
                participantLoginResult.data?.authentication
                    ?.createTokenByParticipant?.token
            const expires = decodeTokenExpiration(_token)

            if (!_token || !expires) {
                throw Error(t('common:error.internal'))
            }

            if (props.embedded) {
                cookies.set(tokenKey, _token, {
                    expires,
                    sameSite: 'None',
                    secure: true,
                })
            } else {
                cookies.set(tokenKey, _token, {
                    expires,
                    sameSite: 'Lax',
                    secure: true,
                })
            }

            refresh()
        },
        [
            cookies,
            createBooking,
            locale,
            participantLogin,
            props.executeRegisterQuery,
            refresh,
            t,
            token,
            viewerUser,
            props.embedded,
        ]
    )

    const onSubmit = useCallback(
        async (values: Values) => {
            const operations = valuesToBookingOperations(values)

            try {
                if (props.booking) {
                    await _updateBooking(operations)
                } else {
                    await _createBooking(props.event.id, operations)
                    props.setStep.call(undefined, 2)
                }
            } catch (e) {
                notification.alert(e.message)
            }
        },
        [
            _createBooking,
            _updateBooking,
            notification,
            props.booking,
            props.event.id,
            props.setStep,
        ]
    )

    const sections = useMemo(
        () => [
            props.event,
            ...(props.event.subEvents ?? []),
            ...(props.event.services ?? []),
        ],
        [props.event]
    )

    const validate = useCallback(
        (values: Values) => {
            const errors: FormikErrors<Values> = {}

            for (const section of sections) {
                if (!Array.isArray(section.operations)) {
                    continue
                }

                for (const operation of section.operations) {
                    const bookingOperations = values[operation.id]

                    if (!Array.isArray(bookingOperations)) {
                        continue
                    }

                    for (let i = 0; i < bookingOperations.length; i++) {
                        if (
                            operation.groupBookingDefinition &&
                            !bookingOperations[i].newGroupBooking &&
                            !bookingOperations[i].groupBookingId
                        ) {
                            set(
                                errors,
                                `${operation.id}[0].groupBookingId`,
                                operation.groupBookings?.itemCount
                                    ? t('common:error.required')
                                    : t('groupBooking:newGroupRequired')
                            )
                        }

                        for (const [identifier, value] of Object.entries(
                            bookingOperations[i].inputFieldValues
                        )) {
                            const inputField = operation.inputFields.find(
                                f => f.identifier === identifier
                            )

                            if (!inputField) {
                                continue
                            }

                            const schema = getValidator(yup, inputField)

                            try {
                                schema.validateSync(value)
                            } catch (e) {
                                set(
                                    errors,
                                    `${operation.id}[${i}].inputFieldValues.${identifier}`,
                                    e.message
                                )
                            }
                        }
                    }
                }
            }

            return errors
        },
        [sections, t, yup]
    )

    const note = props.event.viewerRegistration?.note

    return (
        <Formik<Values>
            initialValues={bookingToValues(props.booking)}
            onSubmit={onSubmit}
            validate={validate}
            enableReinitialize
        >
            {formik => (
                <>
                    <Preselector sections={sections} />
                    <Container className={styles.container}>
                        {note && <FormattedHtml html={note} />}
                        {sections.map(section => (
                            <BookingSection
                                key={section.id}
                                event={props.event}
                                eventKey={props.eventKey}
                                section={section}
                            />
                        ))}
                    </Container>
                    <Actions
                        bookingId={props.booking?.id}
                        disabled={formik.isSubmitting}
                        embedded={props.embedded}
                        eventId={props.event.id}
                        eventKey={props.event.key}
                        onSubmit={formik.submitForm}
                        submitButtonText={t('common:continue')}
                        reset
                    />
                </>
            )}
        </Formik>
    )
}

export default Selection
