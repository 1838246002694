'use client'

import { useCookies } from 'next-client-cookies'
import { FC, useCallback, useMemo } from 'react'
import { tokenKey } from '../../../lib/constants'
import { useBookingsQuery } from '../../../lib/graphql/generated/hooks'
import { useTranslation } from '../../../lib/hooks'
import { selectOperations } from '../../../lib/selectors'
import { Button, ButtonTheme, SidePanel, Text } from '../../base'
import { BookingItem } from './bookingItem'
import styles from './bookings.module.css'

interface Props {
    eventKey: string
    onClose: () => void
    open?: boolean
}

const Bookings: FC<Props> = props => {
    const { t, locale } = useTranslation()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)

    const [bookingsQuery] = useBookingsQuery({
        variables: {
            token,
            locale,
            eventKeyOrId: props.eventKey,
        },
    })

    const event = bookingsQuery.data?.viewer?.event
    const bookings = event?.viewerParticipant?.bookings ?? []

    const canBookMore = useMemo(
        () =>
            selectOperations(event).some(
                operation =>
                    typeof operation.viewerBookable?.maximum === 'number' &&
                    operation.viewerBookable.maximum > 0
            ),
        [event]
    )

    const onDeleteBookingItem = useCallback(() => {
        if (bookings.length - 1 <= 0) {
            props.onClose.call(undefined)
        }
    }, [bookings.length, props.onClose])

    if (!event || !bookings.length) {
        return
    }

    return (
        <SidePanel
            className={styles.root}
            onClose={props.onClose}
            open={props.open}
        >
            <header className={styles.header}>
                <Text
                    className={styles.title}
                    element="h1"
                    type="h4"
                    weight="600"
                    noMargin
                    noWrap
                    notReponsive
                >
                    {t('event:bookings')}
                </Text>
                <Button
                    iconName="x"
                    iconSize={3}
                    onClick={props.onClose}
                    theme={ButtonTheme.Transparent}
                />
            </header>
            <ul className={styles.body}>
                {bookings.map((booking, i) => (
                    <BookingItem
                        key={booking.id}
                        booking={booking}
                        event={event}
                        expanded={i === 0}
                        loading={bookingsQuery.fetching || bookingsQuery.stale}
                        onDelete={onDeleteBookingItem}
                    />
                ))}
            </ul>
            {canBookMore && (
                <footer className={styles.footer}>
                    <Button href={`/event/${event.key}/register`}>
                        {t('event:addBooking')}
                    </Button>
                </footer>
            )}
        </SidePanel>
    )
}

export default Bookings
