'use client'

import clsx from 'clsx'
import { FC, ReactNode } from 'react'
import Link from '../link'
import Text from '../text'
import styles from './card.module.css'

interface Props {
    bodyClassName?: string
    children?: ReactNode
    className?: string
    contentClassName?: string
    header?: ReactNode
    headerClassName?: string
    href?: string
    image?: string
    imageClassName?: string
    imageOverlay?: ReactNode
    imagePlaceholder?: boolean
    onClick?: () => void
    title?: string
    titleClassName?: string
}

const Card: FC<Props> = props => {
    const className = clsx(
        styles.root,
        (props.href || props.onClick) && styles.clickable,
        props.className
    )

    const card = (
        <>
            <div className={styles.card}>
                {Boolean(props.image || props.imagePlaceholder) && (
                    <div
                        className={clsx(styles.image, props.imageClassName)}
                        style={{
                            backgroundImage:
                                props.image && `url("${props.image}")`,
                        }}
                    >
                        {props.imageOverlay}
                    </div>
                )}
                <div className={clsx(styles.body, props.bodyClassName)}>
                    {props.header && (
                        <div
                            className={clsx(
                                styles.header,
                                props.headerClassName
                            )}
                        >
                            {props.header}
                        </div>
                    )}
                    {props.title && (
                        <Text
                            className={clsx(styles.title, props.titleClassName)}
                            element="h3"
                            type="h4"
                        >
                            {props.title}
                        </Text>
                    )}
                    {props.children && (
                        <div
                            className={clsx(
                                styles.content,
                                props.contentClassName
                            )}
                        >
                            {props.children}
                        </div>
                    )}
                </div>
            </div>
        </>
    )

    return props.href ? (
        <Link
            className={className}
            href={props.href}
            onClick={props.onClick}
            noUnderline
        >
            {card}
        </Link>
    ) : (
        <div className={className} onClick={props.onClick}>
            {card}
        </div>
    )
}

export default Card
