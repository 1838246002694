'use client'

import clsx from 'clsx'
import { FC, ReactNode, useCallback } from 'react'
import { AppNotificationType } from '../../../../lib/types'
import { Button, ButtonTheme } from '../../button'
import { Dialog, DialogProps } from '../../dialog'
import { Icon, IconName } from '../../icon'
import Text from '../../text'
import styles from './modalNotification.module.css'

interface Props extends Pick<DialogProps, 'open'> {
    buttonText?: string
    children: ReactNode
    iconName?: IconName
    id: number
    onClose: (id: number) => void
    onClosed: (id: number) => void
    type: AppNotificationType
}

const ModalNotification: FC<Props> = props => {
    const onClose = useCallback(() => props.onClose(props.id), [props])

    const onClosed = useCallback(() => props.onClosed(props.id), [props])

    let iconName: IconName
    let buttonTheme

    switch (props.type) {
        case AppNotificationType.Information:
            iconName = 'info'
            break
        case AppNotificationType.Confirmation:
            iconName = 'check-circle'
            buttonTheme = ButtonTheme.Confirm
            break
        case AppNotificationType.Alert:
            iconName = 'warning-circle'
            buttonTheme = ButtonTheme.Alert
            break
    }

    if (props.iconName) {
        iconName = props.iconName
    }

    return (
        <Dialog
            className={styles.body}
            containerClassName={clsx(
                styles.container,
                props.type === AppNotificationType.Confirmation &&
                    styles.confirmation,
                props.type === AppNotificationType.Alert && styles.alert
            )}
            header={
                <>
                    <svg preserveAspectRatio="none" viewBox="0 0 360 122">
                        <path d="M0,83.9V0h360v84.5C319,107.3,253.9,122,180.5,122C106.5,122,40.9,107,0,83.9z" />
                    </svg>
                    <Icon className={styles.icon} name={iconName} />
                </>
            }
            headerClassName={styles.header}
            onClose={onClose}
            onClosed={onClosed}
            open={props.open}
            darkBackground
            small
        >
            <Text align="center" weight="400">
                {props.children}
            </Text>
            <Button
                className={styles.closeButton}
                onClick={onClose}
                theme={buttonTheme}
            >
                {props.buttonText || 'Ok'}
            </Button>
        </Dialog>
    )
}

export default ModalNotification
