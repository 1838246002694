'use client'

import { Form, Formik } from 'formik'
import { FC, useCallback } from 'react'
import { resetPassword } from '../../../lib/actions'
import { passwordRegex } from '../../../lib/constants'
import {
    useNotification,
    useRouter,
    useTranslation,
    useYup,
} from '../../../lib/hooks'
import {
    Button,
    ButtonGroup,
    ButtonGroupLayout,
    ButtonTheme,
    Input,
    Text,
} from '../../base'
import styles from '../login.module.css'

interface Values {
    password: string
    passwordRepeated: string
}

interface Props {
    from?: string
    resetToken: string
}

const PasswordResetForm: FC<Props> = props => {
    const { t, locale } = useTranslation()
    const notification = useNotification()
    const router = useRouter()
    const yup = useYup()

    const goToLogin = useCallback(
        () =>
            router.replace('/login', {
                searchParams: props.from
                    ? new URLSearchParams({ from: props.from })
                    : undefined,
            }),
        [props.from, router]
    )

    const onSubmit = useCallback(
        async (values: Values) => {
            const result = await resetPassword({
                locale,
                password: values.password,
                token: props.resetToken,
            })

            if (result?.error) {
                notification.alert(result.error)
            } else {
                goToLogin()
                notification.confirm(t('login:passwordReset.confirmation'))
            }
        },
        [goToLogin, locale, notification, props.resetToken, t]
    )

    return (
        <>
            <Text element="h2" type="h2">
                {t('login:passwordReset.title')}
            </Text>
            <Formik<Values>
                initialValues={{
                    password: '',
                    passwordRepeated: '',
                }}
                onSubmit={onSubmit}
                validationSchema={yup.object().shape({
                    password: yup
                        .string()
                        .min(8)
                        .matches(passwordRegex)
                        .required(),
                    passwordRepeated: yup
                        .string()
                        .test(
                            'matchesPassword',
                            t('login:passwordReset.passwordMismatch'),
                            (value, context) => {
                                return context.parent.password === value
                            }
                        ),
                })}
            >
                {formik => (
                    <Form noValidate>
                        <div className={styles.fields}>
                            <Input
                                help={t('common:passwordFormat')}
                                label={t('login:passwordReset.password')}
                                name="password"
                                type="password"
                                required
                            />
                            <Input
                                label={t(
                                    'login:passwordReset.passwordRepeated'
                                )}
                                name="passwordRepeated"
                                type="password"
                                required
                            />
                        </div>
                        <ButtonGroup layout={ButtonGroupLayout.FullWidth}>
                            <Button loading={formik.isSubmitting} type="submit">
                                {t('login:passwordReset.submit')}
                            </Button>
                            <Button
                                disabled={formik.isSubmitting}
                                onClick={goToLogin}
                                theme={ButtonTheme.Secondary}
                            >
                                {t('common:cancel')}
                            </Button>
                        </ButtonGroup>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default PasswordResetForm
