'use client'

import { FC } from 'react'
import { useTranslation } from '../../../../lib/hooks'
import { EventQueryEvent } from '../../../../lib/types'
import { Divider, Text } from '../../../base'
import styles from './inviteeSummary.module.css'

interface Props {
    invitee: NonNullable<EventQueryEvent['viewerInvitee']>
}

const InviteeSummary: FC<Props> = props => {
    const { t } = useTranslation()

    return (
        <>
            <div className={styles.root}>
                <Text element="h3">{t('invitee:personalInformation')}</Text>
                <div>
                    <strong>{t('invitee:firstName')}</strong>
                    <div>{props.invitee.firstName || '-'}</div>
                </div>
                <div>
                    <strong>{t('invitee:lastName')}</strong>
                    <div>{props.invitee.lastName || '-'}</div>
                </div>
                <div>
                    <strong>{t('invitee:email')}</strong>
                    <div>{props.invitee.email || '-'}</div>
                </div>
                {props.invitee.company && (
                    <div>
                        <strong>{t('invitee:company')}</strong>
                        <div>{props.invitee.company}</div>
                    </div>
                )}
                {props.invitee.jobTitle && (
                    <div>
                        <strong>{t('invitee:jobTitle')}</strong>
                        <div>{props.invitee.jobTitle}</div>
                    </div>
                )}
                {props.invitee.companions.length > 0 && (
                    <div>
                        <strong>{t('invitee:companions')}</strong>
                        <ul>
                            {props.invitee.companions.map(
                                ({ firstName, lastName }, i) => (
                                    <li key={i}>
                                        {firstName} {lastName}
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                )}
                <Divider />
            </div>
            <Text element="div">{t('invitee:note')}</Text>
        </>
    )
}

export default InviteeSummary
