'use client'

import { FC, useCallback } from 'react'
import { OnlineEvent } from '../../../../../lib/types'
import { Button, Text } from '../../../../base'
import styles from './onlineEventItem.module.css'

interface Props {
    onClick: (onlineEvent: OnlineEvent) => void
    onlineEvent: OnlineEvent
}

const OnlineEventItem: FC<Props> = props => {
    const onClick = useCallback(
        () => props.onClick.call(undefined, props.onlineEvent),
        [props.onClick, props.onlineEvent]
    )

    return (
        <div className={styles.root}>
            <Button
                iconName={props.onlineEvent.live ? 'video-camera' : 'globe'}
                onClick={onClick}
            >
                {props.onlineEvent.title}
            </Button>
            {props.onlineEvent.description && (
                <Text noMargin>{props.onlineEvent.description}</Text>
            )}
        </div>
    )
}

export default OnlineEventItem
