/* istanbul ignore file */
import { Exchange, makeOperation, Operation } from '@urql/core'
import { map, pipe } from 'wonka'

const fetchOptionsExchange: Exchange =
    ({ forward }) =>
    operations =>
        pipe(
            operations,
            map((operation: Operation) => {
                const fetchOptions = operation.context.fetchOptions || {}

                return makeOperation(operation.kind, operation, {
                    ...operation.context,
                    fetchOptions: {
                        ...operation.context.fetchOptions,
                        ...fetchOptions,
                    },
                })
            }),
            forward
        )

export default fetchOptionsExchange
