import { Operation } from '../../graphql/generated/types'

const selectOperations = (event?: any): Operation[] => {
    const operations = [...(event?.operations ?? [])]

    if (event?.subEvents) {
        event.subEvents.forEach((subEvent: any) => {
            if (subEvent.operations) {
                operations.push(...subEvent.operations)
            }
        })
    }

    if (event?.services) {
        event.services.forEach((service: any) => {
            if (service.operations) {
                operations.push(...service.operations)
            }
        })
    }

    return operations
}

export default selectOperations
