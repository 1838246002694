import { defaultLocale } from '../../i18n'
import extractLocale from '../extractLocale'

const withLocale = (pathname: string, locale: string) => {
    const extracted = extractLocale(pathname)

    return locale === defaultLocale
        ? extracted.pathname
        : `/${locale}${extracted.pathname === '/' ? '' : extracted.pathname}`
}

export default withLocale
