/* istanbul ignore file */
'use client'

import { Form, Formik } from 'formik'
import { FC } from 'react'
import { Button, ButtonGroup, Card, Switch } from '../client'
import styles from './examples.module.css'
import useOnSubmit from './useOnSubmit'

const SwitchExample: FC = () => {
    const { onSubmit } = useOnSubmit()

    return (
        <Card title="Switch">
            <Formik
                initialValues={{
                    switch: false,
                }}
                onSubmit={onSubmit}
            >
                {formik => (
                    <Form className={styles.flexCol} noValidate>
                        <Switch help="Help text" name="switch">
                            Label
                        </Switch>
                        <ButtonGroup>
                            <Button loading={formik.isSubmitting} type="submit">
                                Submit
                            </Button>
                        </ButtonGroup>
                    </Form>
                )}
            </Formik>
        </Card>
    )
}

export default SwitchExample
