import { jwtDecode, JwtPayload } from 'jwt-decode'

const decodeTokenExpiration = (token?: string | null) => {
    if (!token) {
        return
    }

    try {
        const decoded = jwtDecode<JwtPayload>(token)

        if (decoded.exp) {
            // FIXME: In server instead of client time zone
            return new Date(decoded.exp * 1000)
        }
    } catch {
        // Ignore decoding error
    }
}

export default decodeTokenExpiration
