'use client'

import { FC, ReactNode, useCallback, useState } from 'react'
import { useTranslation } from '../../../../lib/hooks'
import { Button, ButtonGroup, ButtonTheme, Dialog, Text } from '../../../base'

interface Props {
    children: ReactNode
    disabled?: boolean
    loading?: boolean
    modalButton: string
    modalText: string
    modalTitle: string
    onSubmit: () => void
    theme: ButtonTheme
}

const ActionsModalButton: FC<Props> = props => {
    const { t } = useTranslation()
    const [modalDialogOpen, setModalDialogOpen] = useState(false)

    const openModalDialog = useCallback(() => setModalDialogOpen(true), [])
    const closeModalDialog = useCallback(() => setModalDialogOpen(false), [])

    const onSubmit = useCallback(() => {
        props.onSubmit()

        closeModalDialog()
    }, [props, closeModalDialog])

    return (
        <>
            <Button
                disabled={props.disabled}
                loading={props.loading}
                onClick={openModalDialog}
                theme={props.theme}
            >
                {props.children}
            </Button>
            <Dialog open={modalDialogOpen} title={props.modalTitle}>
                <Text>{props.modalText}</Text>
                <ButtonGroup>
                    <Button onClick={onSubmit}>{props.modalButton}</Button>
                    <Button
                        onClick={closeModalDialog}
                        theme={ButtonTheme.Cancel}
                    >
                        {t('common:cancel')}
                    </Button>
                </ButtonGroup>
            </Dialog>
        </>
    )
}

export default ActionsModalButton
