'use client'

import { useId as useReactId } from 'react'

/**
 * Returns a React ID without ":" to make it compatible with CSS selectors.
 * https://reactjs.org/docs/hooks-reference.html#useid
 */
const useId = () => useReactId().replaceAll(':', '')

export default useId
