'use client'

import { Dayjs } from 'dayjs'
import { FC, useCallback, useMemo, useState } from 'react'
import { EventQueryEvent } from '../../../../lib/types'
import { dayjsFrom } from '../../../../lib/utils'
import { Badge, TagButton } from '../../../base'
import { AgendaItem } from '../agendaItem'
import styles from './agendaTimeline.module.css'

interface Props {
    agenda: NonNullable<EventQueryEvent['agenda']>
    day?: Dayjs
    eventKey: string
    timezone?: string
}

const AgendaTimeline: FC<Props> = props => {
    const [selectedTags, setSelectedTags] = useState<Set<string>>(new Set())

    const onTagClick = useCallback(
        (tag: string) => {
            const set = new Set(selectedTags)

            if (selectedTags.has(tag)) {
                set.delete(tag)
            } else {
                set.add(tag)
            }

            setSelectedTags(set)
        },
        [selectedTags]
    )

    const filteredItems = useMemo(() => {
        let items = props.agenda

        if (props.day) {
            items = props.agenda.filter(item =>
                dayjsFrom(item.startDate)?.startOf('day').isSame(props.day)
            )
        }

        if (selectedTags.size) {
            items = items.filter(
                item =>
                    !Array.from(selectedTags).some(
                        tag => !item.tags.includes(tag)
                    )
            )
        }

        return items
    }, [props.agenda, props.day, selectedTags])

    const tags = useMemo(() => {
        const map = new Map()

        filteredItems.forEach(item =>
            item.tags.forEach(tag => {
                const count = map.get(tag)

                if (count) {
                    map.set(tag, count + 1)
                } else {
                    map.set(tag, 1)
                }
            })
        )

        return Array.from<[string, number]>(map).sort(([a], [b]) =>
            a.localeCompare(b)
        )
    }, [filteredItems])

    return (
        <>
            {tags.length > 0 && (
                <div className={styles.tags}>
                    {tags.map(([label, count]) => (
                        <TagButton
                            key={label}
                            data={label}
                            onClick={onTagClick}
                            rightNode={
                                <Badge className={styles.badge} small>
                                    {count}
                                </Badge>
                            }
                            selected={selectedTags.has(label)}
                        >
                            {label}
                        </TagButton>
                    ))}
                </div>
            )}
            {filteredItems.length > 0 && (
                <div className={styles.items}>
                    {filteredItems.map(item => (
                        <AgendaItem
                            key={item.id}
                            eventKey={props.eventKey}
                            item={item}
                            timezone={props.timezone}
                        />
                    ))}
                </div>
            )}
        </>
    )
}

export default AgendaTimeline
