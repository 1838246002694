/* istanbul ignore file */
'use client'

import { useCookies } from 'next-client-cookies'
import { FC, useCallback, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { devEnv, tokenKey } from '../../../../lib/constants'
import {
    useMediaQuery,
    useRouter,
    useTranslation,
    useViewerUser,
} from '../../../../lib/hooks'
import { Breakpoint } from '../../../../lib/types'
import { Button, ButtonTheme, Container, Flyout, Link } from '../../../base'
import LanguageSwitcher from '../languageSwitcher'
import styles from './headerNavigation.module.css'

interface Props {
    availableLocales?: string[]
    eventCreation?: boolean
    logoHref?: string
    noLogo?: boolean
    noNavigation?: boolean
}

const HeaderNavigation: FC<Props> = props => {
    const { t, locale } = useTranslation()
    const { pathname, refresh } = useRouter()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)
    const viewerUser = useViewerUser()
    const mdUp = useMediaQuery(Breakpoint.MdUp)
    const lgDown = useMediaQuery(Breakpoint.LgDown)

    const logOut = useCallback(() => {
        cookies.remove(tokenKey)
        refresh()
    }, [cookies, refresh])

    const leftItems = useMemo(() => {
        if (typeof viewerUser === 'undefined') {
            return
        }

        const items = []

        if (props.eventCreation && pathname !== '/wizard' && viewerUser) {
            items.push(
                <Link
                    className={styles.link}
                    hideText={lgDown}
                    href="/wizard"
                    iconName="file-plus"
                    iconSize={2}
                    noUnderline
                >
                    {t('common:createEvent')}
                </Link>
            )
        }

        if (token) {
            items.push(
                <Flyout
                    toggle={
                        <Button
                            iconName="user"
                            iconSize={2}
                            theme={ButtonTheme.Transparent}
                        >
                            {viewerUser &&
                                mdUp &&
                                `${viewerUser.firstName} ${viewerUser.lastName}`}
                        </Button>
                    }
                    list
                >
                    {viewerUser && (
                        <>
                            <Link
                                href={`/admin/${locale}`}
                                locale={false}
                                noUnderline
                            >
                                {t('common:admin')}
                            </Link>
                            <Link
                                href={`/profile/${viewerUser.id}`}
                                noUnderline
                            >
                                {t('common:viewProfile')}
                            </Link>
                            <Link href={`/profile/edit`} noUnderline>
                                {t('common:settings')}
                            </Link>
                        </>
                    )}
                    <div onClick={logOut}>{t('common:logOut')}</div>
                </Flyout>
            )
        }

        if (devEnv) {
            items.push(
                <Link
                    className={styles.link}
                    hideText={lgDown}
                    href="/components"
                    iconName="shapes"
                    iconSize={2}
                    noUnderline
                >
                    {t('common:components')}
                </Link>
            )
        }

        return items
    }, [
        lgDown,
        locale,
        logOut,
        mdUp,
        pathname,
        props.eventCreation,
        t,
        token,
        viewerUser,
    ])

    let logo

    if (!props.noLogo) {
        logo = props.logoHref ? (
            <Link className={styles.logo} href={props.logoHref} noUnderline />
        ) : (
            <div className={styles.logo} />
        )
    }

    return (
        <nav className={styles.root}>
            <Container className={styles.container}>
                {logo}
                {!props.noNavigation && (
                    <ul className={styles.items}>
                        {leftItems ? (
                            leftItems.map((item, i) => <li key={i}>{item}</li>)
                        ) : (
                            <Skeleton
                                borderRadius={5}
                                className={styles.skeleton}
                                height={26}
                            />
                        )}
                        {Boolean(!leftItems || leftItems.length > 0) && (
                            <li className={styles.separator}>|</li>
                        )}
                        <li>
                            <Link
                                className={styles.link}
                                href={`https://www.oniva.events/${locale}/privacy`}
                                iconName="shield"
                                iconSize={2}
                                target="_blank"
                                hideText
                                noUnderline
                            >
                                {t('common:privacy')}
                            </Link>
                        </li>
                        <li className={styles.languageSwitcher}>
                            <LanguageSwitcher
                                availableLocales={props.availableLocales}
                            />
                        </li>
                    </ul>
                )}
            </Container>
        </nav>
    )
}

export default HeaderNavigation
