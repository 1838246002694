'use client'

import { FC } from 'react'
import { InviteeStatus } from '../../../../lib/graphql/generated/types'
import { useTranslation } from '../../../../lib/hooks'
import { Card, Icon, Link, Text } from '../../../base'
import styles from './inviteeStatusMessage.module.css'

interface Props {
    complianceFormDownloadUrl?: string | null
    complianceFormUploadUrl?: string | null
    complianceProcess: boolean
    inviteeStatus: string
    respondable: boolean
}

const InviteeStatusMessage: FC<Props> = props => {
    const { t } = useTranslation()

    let message
    let complianceCard
    switch (props.inviteeStatus) {
        case InviteeStatus.Accepted:
        case InviteeStatus.Confirmed:
            message = <strong>{t('invitee:statusMessage.accepted')}</strong>
            complianceCard = props.complianceProcess &&
                props.complianceFormDownloadUrl &&
                props.complianceFormUploadUrl && (
                    <Card
                        className={styles.card}
                        header={
                            <>
                                <Icon
                                    className={styles.icon}
                                    name="warning-circle"
                                />
                                <span>{t('invitee:compliance.header')}</span>
                            </>
                        }
                    >
                        <Text>{t('invitee:compliance.info')}</Text>
                        <ol className={styles.compliance}>
                            <li>
                                <Link href={props.complianceFormDownloadUrl}>
                                    {t('invitee:compliance.download')}
                                </Link>
                            </li>
                            <li>
                                <Link href={props.complianceFormUploadUrl}>
                                    {t('invitee:compliance.upload')}
                                </Link>
                            </li>
                        </ol>
                    </Card>
                )
            break
        case InviteeStatus.Rejected:
            message = <strong>{t('invitee:statusMessage.rejected')}</strong>
            break
        case InviteeStatus.Invited:
            message = props.respondable ? (
                t('invitee:statusMessage.invited')
            ) : (
                <strong>{t('invitee:statusMessage.expired')}</strong>
            )
            break
    }
    return (
        <>
            <Text className={styles.info} element="div">
                {message}
            </Text>
            {complianceCard}
        </>
    )
}

export default InviteeStatusMessage
