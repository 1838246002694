'use client'

import clsx from 'clsx'
import { useCookies } from 'next-client-cookies'
import { FC, useCallback, useMemo, useState } from 'react'
import { tokenKey } from '../../../../lib/constants'
import {
    useDeleteBookingMutation,
    useResendBookingConfirmationMutation,
} from '../../../../lib/graphql/generated/hooks'
import {
    BookingFragment,
    BookingOperationStatus,
    BookingStatus,
} from '../../../../lib/graphql/generated/types'
import { useNotification, useTranslation } from '../../../../lib/hooks'
import { BookingsQueryEvent } from '../../../../lib/types'
import {
    Badge,
    Button,
    ButtonGroup,
    ButtonTheme,
    Collapsible,
    Dialog,
    Text,
} from '../../../base'
import { BookingSummary, ParticipantSummary } from '../../../common'
import styles from './bookingItem.module.css'

export interface Props {
    booking: BookingFragment
    event: BookingsQueryEvent
    expanded?: boolean
    loading?: boolean
    onDelete?: () => void
}

const BookingItem: FC<Props> = props => {
    const { t } = useTranslation()
    const notification = useNotification()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)
    const [, resendBookingConfirmation] = useResendBookingConfirmationMutation()
    const [, deleteBooking] = useDeleteBookingMutation()
    const [collapsed, setCollapsed] = useState(!props.expanded)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [submitting, setSubmitting] = useState(false)

    const invited = useMemo(
        () =>
            props.booking.groupedBookingOperations.some(
                operation =>
                    operation.bookingOperationStatus ===
                    BookingOperationStatus.Invited
            ),
        [props.booking.groupedBookingOperations]
    )

    const toggle = useCallback(() => setCollapsed(prev => !prev), [])

    const openDialog = useCallback(() => setDialogOpen(true), [])

    const closeDialog = useCallback(() => {
        if (!submitting) {
            setDialogOpen(false)
        }
    }, [submitting])

    const onDeleteBooking = useCallback(async () => {
        if (!token) {
            notification.alert(t('common:error.internal'), { modal: true })
            return
        }

        setSubmitting(true)

        try {
            const { data: deleteBookingData, error: deleteBookingError } =
                await deleteBooking(
                    {
                        token,
                        eventId: props.event.id,
                        bookingId: props.booking.id,
                    },
                    { additionalTypenames: ['Event', 'Booking'] }
                )

            if (deleteBookingError) {
                throw deleteBookingError
            }

            if (
                deleteBookingData?.viewer?.event?.viewerParticipant
                    ?.deleteBooking?.inputError
            ) {
                throw Error(
                    deleteBookingData.viewer.event.viewerParticipant
                        ?.deleteBooking?.inputError.message
                )
            }

            setDialogOpen(false)
            props.onDelete?.call(undefined)

            notification.confirm(t('event:deleteBooking.confirmation'), {
                modal: true,
            })
        } catch (e) {
            setDialogOpen(false)
            notification.alert(e.message, { modal: true })
        }

        setSubmitting(false)
    }, [
        deleteBooking,
        notification,
        props.booking.id,
        props.event.id,
        props.onDelete,
        t,
        token,
    ])

    /* istanbul ignore next */
    const downloadTicket = useCallback(() => {
        if (!props.booking.eTicketUrl) {
            return
        }

        const downloadLink = document.createElement('a')
        downloadLink.href = props.booking.eTicketUrl
        downloadLink.target = '_blank'
        downloadLink.click()
    }, [props.booking.eTicketUrl])

    const resendConfirmation = useCallback(async () => {
        if (!token) {
            notification.alert(t('common:error.internal'), { modal: true })
            return
        }

        notification.confirm(t('event:confirmationSent'), {
            modal: true,
            modalIconName: 'envelope-simple',
        })

        try {
            const { data, error } = await resendBookingConfirmation({
                token,
                eventId: props.event.id,
                bookingId: props.booking.id,
            })

            if (error) {
                throw error
            }

            if (
                data?.viewer?.event?.viewerParticipant
                    ?.resendBookingConfirmation?.inputError
            ) {
                throw Error(
                    data.viewer.event.viewerParticipant
                        .resendBookingConfirmation.inputError.message
                )
            }
        } catch (e) {
            console.error(e)
        }
    }, [
        notification,
        props.booking.id,
        props.event.id,
        resendBookingConfirmation,
        t,
        token,
    ])

    return (
        <>
            <Collapsible
                className={clsx(styles.body, collapsed && styles.collapsed)}
                collapsed={collapsed}
                containerClassName={styles.container}
                toggle={
                    <div className={styles.header} onClick={toggle}>
                        <div className={styles.left}>
                            <Text element="h2" type="h5" noMargin noWrap>
                                {props.booking.bookingNr}
                            </Text>
                            {invited && (
                                <Badge className={styles.badge}>
                                    {t('common:invitation')}
                                </Badge>
                            )}
                        </div>
                        <span
                            className={clsx(
                                styles.arrow,
                                !collapsed && styles.expanded
                            )}
                        />
                    </div>
                }
            >
                <BookingSummary
                    booking={props.booking}
                    event={props.event}
                    noTitle
                />
                <div className={styles.participant}>
                    <Text element="h3" type="h5" noMargin>
                        {t('event:personalInformation')}
                    </Text>
                    <ParticipantSummary
                        bookingInputFieldValues={props.booking.inputFieldValues}
                        participant={props.booking.participant}
                    />
                </div>
                <div className={styles.actions}>
                    {props.booking.bookingStatus ===
                        BookingStatus.Confirmed && (
                        <Button
                            disabled={props.loading}
                            iconName="envelope-simple"
                            onClick={resendConfirmation}
                            theme={ButtonTheme.Secondary}
                        >
                            {t('event:resendConfirmation')}
                        </Button>
                    )}
                    {Boolean(props.booking.eTicketUrl) && (
                        <Button
                            disabled={props.loading}
                            iconName="cloud-arrow-down"
                            onClick={downloadTicket}
                            theme={ButtonTheme.Secondary}
                        >
                            {t('event:downloadTicket')}
                        </Button>
                    )}
                    {props.booking.deletable && (
                        <Button
                            disabled={props.loading}
                            iconName="trash"
                            onClick={openDialog}
                            theme={ButtonTheme.Secondary}
                        >
                            {t('event:deleteBooking.title')}
                        </Button>
                    )}
                </div>
            </Collapsible>
            {props.booking.deletable && (
                <Dialog
                    onClose={closeDialog}
                    open={dialogOpen}
                    title={t('event:deleteBooking.title')}
                    small
                >
                    <Text>{t('event:deleteBooking.prompt')}</Text>
                    <ButtonGroup>
                        <Button loading={submitting} onClick={onDeleteBooking}>
                            {t('event:deleteBooking.title')}
                        </Button>
                        <Button
                            disabled={submitting}
                            onClick={closeDialog}
                            theme={ButtonTheme.Cancel}
                        >
                            {t('common:cancel')}
                        </Button>
                    </ButtonGroup>
                </Dialog>
            )}
        </>
    )
}

export default BookingItem
