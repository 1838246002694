import { EventQueryEvent } from '../../types'

const selectEventLogoHref = (event: EventQueryEvent) =>
    event.eventType.standaloneLibrary
        ? `/type/${event.eventType.key}`
        : event.eventType.appLibrary
          ? '/'
          : `/event/${event?.key}`

export default selectEventLogoHref
