'use client'

import { useCookies } from 'next-client-cookies'
import { FC, useCallback, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import Skeleton from 'react-loading-skeleton'
import { tokenKey } from '../../../lib/constants'
import { useParticipantsQuery } from '../../../lib/graphql/generated/hooks'
import { useTranslation } from '../../../lib/hooks'
import { Text } from '../../base'
import { LoadMoreButton, PageSection } from '../../common'
import MockParticipants from './mockParticipants'
import Participant from './participant'
import styles from './participants.module.css'

const batchSize = 8

interface Props {
    eventId: string
    viewable?: boolean
}

const Participants: FC<Props> = props => {
    const { t, locale } = useTranslation()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)
    const { ref, inView } = useInView()
    const [length, setLength] = useState(batchSize)

    const [participantsQuery] = useParticipantsQuery({
        variables: {
            token,
            locale,
            eventKeyOrId: props.eventId,
            length,
            offset: 0,
        },
        pause: !props.viewable || !inView,
    })

    const total =
        participantsQuery.data?.viewer?.event?.participants?.totalCount ?? 0
    const participants =
        participantsQuery.data?.viewer?.event?.participants?.items

    const loadMore = useCallback(() => {
        setLength(prev => prev + batchSize)
    }, [])

    return (
        <PageSection
            ref={ref}
            id="participants"
            overlay={
                !props.viewable && (
                    <Text align="center" element="div" type="h4" weight="600">
                        {t('event:participants.overlay')}
                    </Text>
                )
            }
            overlayClassName={styles.overlay}
            title={t('event:participants.title')}
        >
            <div className={styles.participants}>
                {props.viewable ? (
                    participants ? (
                        participants?.map(participant => (
                            <Participant
                                key={participant.id}
                                participant={participant}
                            />
                        ))
                    ) : (
                        Array(batchSize)
                            .fill(true)
                            .map((_, i) => (
                                <Skeleton
                                    key={i}
                                    height={100}
                                    width={100}
                                    circle
                                />
                            ))
                    )
                ) : (
                    <MockParticipants />
                )}
            </div>
            {!!participants?.length && (
                <LoadMoreButton
                    className={styles.loadMoreButton}
                    from={1}
                    loading={participantsQuery.fetching}
                    onClick={loadMore}
                    to={participants.length}
                    total={total}
                />
            )}
        </PageSection>
    )
}

export default Participants
