'use client'

import { useCookies } from 'next-client-cookies'
import { FC, useEffect } from 'react'
import { tokenKey } from '../../../../lib/constants'
import { AuthenticationMethod } from '../../../../lib/graphql/generated/types'
import { useTranslation } from '../../../../lib/hooks'
import { extractLocale, withLocale } from '../../../../lib/utils'
import { Metadata } from '../../../base'
import ErrorPage from '../errorPage'

interface Props {
    authenticationMethods?: AuthenticationMethod[]
}

const ForbiddenPage: FC<Props> = props => {
    const { t, locale } = useTranslation()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)

    const loginMethods =
        props.authenticationMethods?.filter(method =>
            [
                AuthenticationMethod.AccessCode,
                AuthenticationMethod.UsernamePassword,
                AuthenticationMethod.Sso,
            ].includes(method)
        ) ?? []

    const redirectToLogin = Boolean(!token && loginMethods.length)

    useEffect(() => {
        if (redirectToLogin) {
            const url = new URL(window.location.href)
            const { pathname } = extractLocale(url.pathname)

            url.pathname = withLocale('/login', locale)
            url.searchParams.set('from', pathname)

            window.location.href = url.href
        }
    }, [locale, redirectToLogin])

    if (redirectToLogin) {
        return <Metadata title={t('common:errorPage.401')} />
    }

    const loginAvailable = loginMethods.length > 0

    const purlAvailable = props.authenticationMethods?.includes(
        AuthenticationMethod.PersonalUrl
    )

    return (
        <>
            <Metadata title={t('common:errorPage.401')} />
            <ErrorPage
                homepageButton={loginAvailable}
                loginButton={loginAvailable}
                text={t(
                    purlAvailable
                        ? 'common:errorPage.purl'
                        : 'common:errorPage.forbidden'
                )}
                title={t('common:errorPage.401')}
            />
        </>
    )
}

export default ForbiddenPage
