'use client'

import clsx from 'clsx'
import { FC, ReactNode, useCallback } from 'react'
import Text from '../text'
import styles from './tagButton.module.css'

interface Props {
    children: ReactNode
    className?: string
    data?: any
    leftNode?: ReactNode
    onClick: (data?: any) => void
    rightNode?: ReactNode
    selected?: boolean
}

const TagButton: FC<Props> = props => {
    const onClick = useCallback(() => {
        props.onClick(props.data)
    }, [props])

    return (
        <button
            className={clsx(
                styles.root,
                props.selected && styles.selected,
                props.className
            )}
            onClick={onClick}
            type="button"
        >
            {props.leftNode}
            <Text className={styles.text} element="span" type="small" noWrap>
                {props.children}
            </Text>
            {props.rightNode}
        </button>
    )
}

export default TagButton
