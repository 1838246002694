'use client'

import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { CategoriesAndEventTypesQuery } from '../../lib/graphql/generated/types'
import { useRouter, useTranslation } from '../../lib/hooks'
import {
    Button,
    ButtonTheme,
    Container,
    Link,
    ProgressBar,
    Text,
} from '../base'
import {
    CommentStep,
    CommentStepValues,
    DateTimeStep,
    DateTimeStepValues,
    DescriptionStep,
    DescriptionStepValues,
    ImageStep,
    ImageStepValues,
    ParticipantsStep,
    ParticipantsStepValues,
    TagsStep,
    TagsStepValues,
    TitleStep,
    TitleStepValues,
    VenueStep,
    VenueStepValues,
} from './steps'
import styles from './wizard.module.css'

type Values = TitleStepValues &
    ImageStepValues &
    VenueStepValues &
    CommentStepValues &
    ParticipantsStepValues &
    TagsStepValues &
    DateTimeStepValues &
    DescriptionStepValues

export interface StepProps {
    categoriesAndEventTypes: CategoriesAndEventTypesQuery['viewer']
    mergeValues: (values: Partial<Values>) => void
    nextStep: () => void
    values: Partial<Values>
}

interface Props {
    categoriesAndEventTypes: CategoriesAndEventTypesQuery['viewer']
}

const Wizard: FC<Props> = props => {
    const { t } = useTranslation()
    const router = useRouter()
    const [values, setValues] = useState<Partial<Values>>({})
    const [step, setStep] = useState(0)
    const [hasChanges, setHasChanges] = useState<boolean>(false)

    const steps = useMemo(
        () => [
            { Step: TitleStep, hint: t('wizard:hint.title') },
            { Step: ImageStep, hint: t('wizard:hint.image') },
            { Step: TagsStep, hint: t('wizard:hint.tags') },
            { Step: DescriptionStep, hint: t('wizard:hint.description') },
            { Step: VenueStep, hint: t('wizard:hint.venue') },
            { Step: DateTimeStep, hint: t('wizard:hint.dateTime') },
            { Step: ParticipantsStep, hint: t('wizard:hint.participants') },
            { Step: CommentStep, hint: t('wizard:hint.comment') },
        ],
        [t]
    )

    const progressSteps = useMemo(
        () =>
            t('wizard:progressSteps', {
                returnObjects: true,
            }),
        [t]
    ) as string[]

    const progressStep = useMemo(() => {
        if (step <= 1) {
            return 1
        } else if (step <= 3) {
            return 2
        } else if (step <= 5) {
            return 3
        } else {
            return 4
        }
    }, [step])

    useEffect(() => {
        // Warn user when leaving after changes
        window.onbeforeunload = () => (hasChanges ? '' : null)

        return () => {
            window.onbeforeunload = () => null
        }
    }, [hasChanges])

    const mergeValues = useCallback(
        (_values: Partial<Values>) => {
            setValues(prev => ({
                ...prev,
                ..._values,
            }))

            if (!hasChanges) {
                setHasChanges(true)
            }
        },
        [hasChanges]
    )

    const nextStep = useCallback(() => {
        if (step < steps.length - 1) {
            setStep(step + 1)
        }
    }, [step, steps.length])

    const previousStep = useCallback(() => {
        if (step > 0) {
            setStep(step - 1)
        } else {
            router.back()
        }
    }, [step, router])

    const onProgressStepClick = useCallback((_step: number) => {
        switch (_step) {
            case 1:
                setStep(0)
                break
            case 2:
                setStep(2)
                break
            case 3:
                setStep(4)
                break
        }
    }, [])

    const { Step, hint } = steps[step]

    return (
        <Container>
            <div className={styles.wrapper}>
                <div className={styles.leftColumn}>
                    <div className={styles.logoWrapper}>
                        <Link href="/" noUnderline>
                            <img
                                alt="Zoon"
                                className={styles.logo}
                                src="/logo-white.svg"
                            />
                        </Link>
                    </div>
                    <div className={styles.hint}>
                        <Text
                            className={styles.headline}
                            element="h2"
                            mdType="h2"
                            type="h4"
                        >
                            {hint}
                        </Text>
                    </div>
                    <div className={styles.navigation}>
                        <Button
                            iconName="arrow-left"
                            onClick={previousStep}
                            theme={ButtonTheme.Transparent}
                            darkBackground
                        >
                            {t('common:back')}
                        </Button>
                    </div>
                </div>
                <div className={styles.rightColumn}>
                    <ProgressBar
                        onClick={onProgressStepClick}
                        step={progressStep}
                        steps={progressSteps}
                    />
                    <Step
                        categoriesAndEventTypes={props.categoriesAndEventTypes}
                        mergeValues={mergeValues}
                        nextStep={nextStep}
                        values={values}
                    />
                </div>
            </div>
        </Container>
    )
}

export default Wizard
