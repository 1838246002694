'use client'

import clsx from 'clsx'
import { FC, JSX } from 'react'
import styles from './select.module.css'

export interface Props
    extends Pick<JSX.IntrinsicElements['option'], 'className' | 'disabled'> {
    children: string
    value?: string
}

const SelectOption: FC<Props> = ({ className, ...props }) => (
    <option {...props} className={clsx(styles.option, className)}></option>
)

export default SelectOption
