import clsx from 'clsx'
import { FC } from 'react'
import { EventQueryEvent } from '../../../lib/types'
import { FormattedHtml } from '../../base'
import { AdditionalField } from '../../common'
import styles from './formattedDescription.module.css'

export interface Props {
    additionalFields: EventQueryEvent['additionalFields']
    className?: string
    description: EventQueryEvent['description']
}

const FormattedDescription: FC<Props> = props =>
    props.description || props.additionalFields ? (
        <div className={clsx(styles.root, props.className)}>
            {props.description && <FormattedHtml html={props.description} />}
            {props.additionalFields?.map((field, i) => (
                <AdditionalField key={i} field={field} />
            ))}
        </div>
    ) : null

export default FormattedDescription
