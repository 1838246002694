import { FC, ReactElement } from 'react'
import { ButtonGroup, Container } from '../../base'
import { Props as ButtonProps } from '../../base/button/button'
import styles from './bottomActionGroup.module.css'

interface Props {
    children:
        | ReactElement<ButtonProps>
        | Array<ReactElement<ButtonProps> | false | null | undefined>
}

const BottomActionGroup: FC<Props> = props => (
    <div className={styles.root}>
        <Container className={styles.container}>
            <ButtonGroup className={styles.buttons}>
                {props.children}
            </ButtonGroup>
        </Container>
    </div>
)

export default BottomActionGroup
