'use client'

import { FC } from 'react'
import { AuthenticationMethod } from '../../../lib/graphql/generated/types'
import AccessCodeForm from '../accessCodeForm'
import UsernamePasswordForm from '../usernamePasswordForm'

interface Props {
    authenticationMethod: string
    eventId?: string
    from: string
    onCancel: () => void
    userRegistration?: boolean
    visibleMethods: string[]
}

const LoginForm: FC<Props> = props => {
    const onCancel =
        props.visibleMethods.length > 1 ? props.onCancel : undefined

    switch (props.authenticationMethod) {
        case AuthenticationMethod.AccessCode:
            if (!props.eventId) {
                return null
            }

            return (
                <AccessCodeForm
                    eventId={props.eventId}
                    from={props.from}
                    onCancel={onCancel}
                />
            )
        case AuthenticationMethod.UsernamePassword:
            return (
                <UsernamePasswordForm
                    from={props.from}
                    onCancel={onCancel}
                    userRegistration={props.userRegistration}
                />
            )
        default:
            return null
    }
}

export default LoginForm
