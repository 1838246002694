'use client'

import clsx from 'clsx'
import { throttle } from 'lodash'
import {
    Children,
    FC,
    MouseEvent,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { Icon } from '../icon'
import { Props as TabsItemProps } from './tab'
import styles from './tabs.module.css'

interface Props {
    children?:
        | ReactElement<TabsItemProps>
        | Array<
              | ReactElement<TabsItemProps>
              | Array<ReactElement<TabsItemProps>>
              | false
              | null
              | undefined
          >
    className?: string
}

const Tabs: FC<Props> = props => {
    const header = useRef<HTMLDivElement>(null)
    const scrollLeftButton = useRef<HTMLDivElement>(null)
    const scrollRightButton = useRef<HTMLDivElement>(null)
    const scrollable = useRef<HTMLDivElement>(null)
    const indicator = useRef<HTMLDivElement>(null)
    const nav = useRef<HTMLUListElement>(null)
    const [scrollBehavior, setScrollBehavior] =
        useState<ScrollBehavior>('smooth')

    const tabs = useMemo(
        () =>
            Children.toArray(props.children).filter(
                Boolean
            ) as ReactElement<TabsItemProps>[],
        [props.children]
    )

    const [activeTab, setActiveTab] = useState(
        tabs.length ? tabs[0] : undefined
    )

    const toggleScrollButtons = useMemo(
        () =>
            throttle(() => {
                /* istanbul ignore next */
                if (
                    !header.current ||
                    !nav.current ||
                    !scrollLeftButton.current ||
                    !scrollRightButton.current
                ) {
                    return
                }

                const headerRect = header.current.getBoundingClientRect()
                const navRect = nav.current.getBoundingClientRect()

                if (Math.floor(headerRect.left - navRect.left) > 0) {
                    scrollLeftButton.current.classList.add(styles.visible)
                } else {
                    scrollLeftButton.current.classList.remove(styles.visible)
                }

                if (Math.ceil(headerRect.right - navRect.right) < 0) {
                    scrollRightButton.current.classList.add(styles.visible)
                } else {
                    scrollRightButton.current.classList.remove(styles.visible)
                }
            }, 200),
        []
    )

    useEffect(() => {
        if (!tabs.some(tab => tab.props.label === activeTab?.props.label)) {
            setActiveTab(tabs.length ? tabs[0] : undefined)
        }
    }, [activeTab?.props.label, tabs])

    useEffect(() => {
        // TODO: Remove this workaround once smooth scrolling on iOS Safari is
        // fixed: https://developer.apple.com/forums/thread/703294
        const iOS = /iP(ad|od|hone)/i.test(window.navigator.userAgent)
        const safari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/)

        /* istanbul ignore next */
        if (iOS && safari) {
            setScrollBehavior('auto')
        }
    }, [])

    useEffect(toggleScrollButtons, [tabs, toggleScrollButtons])

    useEffect(() => {
        const currentScrollable = scrollable.current

        /* istanbul ignore next */
        if (!currentScrollable) {
            return
        }

        currentScrollable.addEventListener('scroll', toggleScrollButtons)

        return () => {
            currentScrollable.removeEventListener('scroll', toggleScrollButtons)
        }
    }, [toggleScrollButtons])

    useEffect(() => {
        window.addEventListener('resize', toggleScrollButtons)
        return () => window.removeEventListener('resize', toggleScrollButtons)
    }, [toggleScrollButtons])

    /* istanbul ignore next */
    const animateToTab = useCallback(
        (tab: ReactElement<TabsItemProps>) => {
            if (
                !header.current ||
                !scrollable.current ||
                !indicator.current ||
                !nav.current
            ) {
                return
            }

            const navItem = Array.from(nav.current.children).find(
                item => item.textContent === tab.props.label
            ) as HTMLLIElement

            if (!navItem) {
                return
            }

            indicator.current.style.transform = `translateX(${navItem.offsetLeft}px)`
            indicator.current.style.width = `${navItem.offsetWidth}px`

            const headerRect = header.current.getBoundingClientRect()
            const navItemRect = navItem.getBoundingClientRect()

            let scrollLeft = scrollable.current.scrollLeft

            if (navItemRect.left < headerRect.left) {
                scrollLeft += -(headerRect.left - navItemRect.left)
            } else if (navItemRect.right > headerRect.right) {
                scrollLeft += -(headerRect.right - navItemRect.right)
            }

            scrollable.current.scroll({
                left: scrollLeft,
                behavior: scrollBehavior,
            })
        },
        [scrollBehavior]
    )

    useEffect(() => {
        if (activeTab) {
            animateToTab(activeTab)
        }
    }, [activeTab, animateToTab])

    const scrollLeft = useCallback(() => {
        /* istanbul ignore next */
        if (!scrollable.current) {
            return
        }

        scrollable.current.scroll({
            left: scrollable.current.scrollLeft - 160,
            behavior: scrollBehavior,
        })
    }, [scrollBehavior])

    const scrollRight = useCallback(() => {
        /* istanbul ignore next */
        if (!scrollable.current) {
            return
        }

        scrollable.current.scroll({
            left: scrollable.current.scrollLeft + 160,
            behavior: scrollBehavior,
        })
    }, [scrollBehavior])

    const onTabClick = useCallback(
        (e: MouseEvent<HTMLLIElement>) => {
            const label = e.currentTarget.textContent ?? undefined
            const tab = tabs.find(t => t.props.label === label)

            setActiveTab(tab)
        },
        [tabs]
    )

    if (!activeTab) {
        return null
    }

    return (
        <div className={clsx(styles.root, props.className)}>
            <div ref={header} className={styles.header}>
                <div
                    ref={scrollLeftButton}
                    className={clsx(styles.scrollButton, styles.left)}
                    onClick={scrollLeft}
                >
                    <Icon className={styles.arrow} name="caret-left" size={2} />
                </div>
                <div
                    ref={scrollRightButton}
                    className={clsx(styles.scrollButton, styles.right)}
                    onClick={scrollRight}
                >
                    <Icon
                        className={styles.arrow}
                        name="caret-right"
                        size={2}
                    />
                </div>
                <div ref={scrollable} className={styles.scrollable}>
                    <div ref={indicator} className={styles.indicator} />
                    <ul ref={nav} className={styles.nav}>
                        {tabs.map(item => (
                            <li
                                key={item.props.label}
                                className={clsx(
                                    styles.navItem,
                                    item.props.label ===
                                        activeTab?.props.label && styles.active
                                )}
                                onClick={onTabClick}
                            >
                                {item.props.label}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className={styles.body}>{activeTab.props.children}</div>
        </div>
    )
}

export default Tabs
