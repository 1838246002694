'use client'

import { Form, Formik } from 'formik'
import { useCookies } from 'next-client-cookies'
import { FC, useCallback } from 'react'
import { tokenKey } from '../../../../../lib/constants'
import { useUpdateGroupBookingMutation } from '../../../../../lib/graphql/generated/hooks'
import { useNotification, useTranslation } from '../../../../../lib/hooks'
import {
    Button,
    ButtonGroup,
    ButtonTheme,
    Input,
    Switch,
    Textarea,
} from '../../../../base'
import styles from '../groupBookingUpdateDialog.module.css'

interface Values {
    description: string
    name: string
    public: boolean
}

export interface Props {
    closeDialog: () => void
    description: string
    eventId: string
    groupBookingId: string
    loading?: boolean
    name: string
    public: boolean
}

const GroupForm: FC<Props> = props => {
    const { t } = useTranslation()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)
    const notification = useNotification()
    const [, updateGroupBooking] = useUpdateGroupBookingMutation()

    const onSubmit = useCallback(
        async (values: Values) => {
            if (!token) {
                notification.alert(t('common:error.internal'), { modal: true })
                return
            }

            try {
                const { data, error } = await updateGroupBooking(
                    {
                        token,
                        eventId: props.eventId,
                        groupBookingId: props.groupBookingId,
                        description: values.description,
                        public: values.public,
                    },
                    { additionalTypenames: ['GroupBooking'] }
                )

                if (error) {
                    throw error
                }

                if (
                    data?.viewer?.event?.viewerParticipant?.updateGroupBooking
                        ?.inputError
                ) {
                    throw Error(
                        data.viewer.event.viewerParticipant.updateGroupBooking
                            .inputError.message
                    )
                }
            } catch (e) {
                console.error(e)
                notification.alert(t('common:error.internal'), { modal: true })
            }
        },
        [
            notification,
            props.eventId,
            props.groupBookingId,
            t,
            token,
            updateGroupBooking,
        ]
    )

    return (
        <Formik<Values>
            initialValues={{
                name: props.name,
                description: props.description,
                public: props.public,
            }}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {formik => (
                <Form className={styles.form} noValidate>
                    <div className={styles.fields}>
                        <Input
                            label={t('groupBooking:name')}
                            name="name"
                            disabled
                        />
                        <Textarea
                            disabled={props.loading}
                            label={t('groupBooking:description')}
                            name="description"
                        />
                        <Switch
                            disabled={props.loading}
                            help={t('groupBooking:public.help')}
                            name="public"
                        >
                            {t('groupBooking:public.label')}
                        </Switch>
                    </div>
                    <ButtonGroup>
                        <Button
                            loading={props.loading || formik.isSubmitting}
                            type="submit"
                        >
                            {t('common:save')}
                        </Button>
                        <Button
                            disabled={props.loading || formik.isSubmitting}
                            onClick={props.closeDialog}
                            theme={ButtonTheme.Cancel}
                        >
                            {t('common:cancel')}
                        </Button>
                    </ButtonGroup>
                </Form>
            )}
        </Formik>
    )
}

export default GroupForm
