'use client'

import clsx from 'clsx'
import { useField, useFormikContext } from 'formik'
import { ChangeEvent, FC, JSX, useCallback, useRef } from 'react'
import { maxUploadSize } from '../../../../lib/constants'
import { useNotification, useTranslation } from '../../../../lib/hooks'
import { Icon } from '../../icon'
import InputError from '../inputError'
import InputHelp from '../inputHelp'
import InputLabel from '../inputLabel'
import styles from './fileUpload.module.css'

interface Props
    extends Pick<
        JSX.IntrinsicElements['input'],
        'className' | 'disabled' | 'required'
    > {
    label?: string
    name: string
}

const FileUpload: FC<Props> = props => {
    const { t } = useTranslation()
    const notification = useNotification()
    const [field, meta, helpers] = useField({ name: props.name })
    const { isSubmitting } = useFormikContext()
    const input = useRef<HTMLInputElement>(null)

    const disabled = props.disabled || isSubmitting
    const invalid = meta.touched && meta.error

    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (!e.currentTarget.files?.length) {
                return
            }

            const [file] = e.currentTarget.files

            if (file.type !== 'application/pdf') {
                notification.alert(t('common:error.fileType'))
                return
            } else if (file.size > maxUploadSize) {
                notification.alert(t('common:error.fileSize'))
                return
            }

            const reader = new FileReader()
            reader.readAsDataURL(file)

            reader.onload = () => {
                /* istanbul ignore next */
                if (typeof reader.result === 'string') {
                    helpers.setValue({
                        name: file.name,
                        url: reader.result,
                    })

                    helpers.setTouched(true, false)
                }
            }
        },
        [helpers, notification, t]
    )

    return (
        <div className={clsx(styles.root, props.className)}>
            <label className={styles.label}>
                {props.label && (
                    <InputLabel>
                        {props.label}
                        {props.required && <span> *</span>}
                    </InputLabel>
                )}
                <input
                    key={field.value}
                    ref={input}
                    accept="application/pdf"
                    className={styles.input}
                    disabled={disabled}
                    name={field.name}
                    onChange={onChange}
                    required={props.required}
                    type="file"
                />
                <div
                    className={clsx(styles.wrapper, invalid && styles.invalid)}
                >
                    <Icon className={styles.icon} name="file-pdf" size={2} />
                    {field.value ? (
                        <span>{field.value.name}</span>
                    ) : (
                        <span className={styles.placeholder}>
                            {t('common:selectPlaceholder')}
                        </span>
                    )}
                </div>
            </label>
            <InputHelp>
                {`.pdf / max. ${Math.floor(maxUploadSize / 1024 / 1024)} MB`}
            </InputHelp>
            <InputError name={field.name} />
        </div>
    )
}

export default FileUpload
