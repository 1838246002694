'use client'

import { FC } from 'react'
import { useTranslation } from '../../../../lib/hooks'
import { ButtonTheme } from '../../../base'
import BottomActionGroup from '../../../common/bottomActionGroup'
import ActionsModalButton from '../actionsModalButton'

export interface Props {
    disabled?: boolean
    loading?: boolean
    onAccept: () => void
    onReject: () => void
}

const Actions: FC<Props> = props => {
    const { t } = useTranslation()

    return (
        <BottomActionGroup>
            <ActionsModalButton
                disabled={props.disabled}
                loading={props.loading}
                modalButton={t('invitee:response.accept.modal.button')}
                modalText={t('invitee:response.accept.modal.text')}
                modalTitle={t('invitee:response.accept.modal.title')}
                onSubmit={props.onAccept}
                theme={ButtonTheme.Confirm}
            >
                {t('invitee:response.accept.button')}
            </ActionsModalButton>
            <ActionsModalButton
                disabled={props.disabled}
                modalButton={t('invitee:response.reject.modal.button')}
                modalText={t('invitee:response.reject.modal.text')}
                modalTitle={t('invitee:response.reject.modal.title')}
                onSubmit={props.onReject}
                theme={ButtonTheme.Alert}
            >
                {t('invitee:response.reject.button')}
            </ActionsModalButton>
        </BottomActionGroup>
    )
}

export default Actions
