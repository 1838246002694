import clsx from 'clsx'
import { FC, ReactNode } from 'react'
import styles from './table.module.css'

interface Props {
    children?: ReactNode
    className?: string
}

const Table: FC<Props> = props => (
    <div className={clsx(styles.root, props.className)}>
        <div className={styles.wrapper}>
            <table>{props.children}</table>
        </div>
    </div>
)

export default Table
