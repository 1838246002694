'use client'

import clsx from 'clsx'
import {
    FC,
    MouseEvent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import { createPortal } from 'react-dom'
import { Button, ButtonTheme } from '../button'
import Text from '../text'
import styles from './dialog.module.css'

export interface Props {
    children?: ReactNode
    className?: string
    containerClassName?: string
    darkBackground?: boolean
    header?: ReactNode
    headerClassName?: string
    image?: string
    onClose?: () => void
    onClosed?: () => void
    open?: boolean
    small?: boolean
    title?: string
}

// TODO: Refactor to handle opening and closing and provide opening callback
// to toggle render prop
const Dialog: FC<Props> = props => {
    const dialog = useRef<HTMLDialogElement>(null)
    const backdrop = useRef<HTMLDivElement>(null)
    const [mounted, setMounted] = useState(false)

    useEffect(() => {
        if (props.open) {
            setMounted(true)

            // Start CSS opening animation after mounting
            setTimeout(() => {
                /* istanbul ignore next */
                if (dialog.current) {
                    dialog.current.show()
                }
            }, 50)
        } else if (dialog.current?.open) {
            dialog.current?.close()

            // Unmount after CSS closing animation
            setTimeout(() => {
                setMounted(false)
                props.onClosed?.call(undefined)
            }, 350)
        }
    }, [props.onClosed, props.open])

    const onMouseDown = useCallback(
        (e: MouseEvent) => {
            /* istanbul ignore next */
            if (e.target === backdrop.current) {
                props.onClose?.call(undefined)
            }
        },
        [props.onClose]
    )

    if (!mounted) {
        return
    }

    return createPortal(
        <dialog ref={dialog} className={styles.root}>
            <div
                ref={backdrop}
                className={styles.backdrop}
                onMouseDown={onMouseDown}
            >
                <div
                    className={clsx(
                        styles.container,
                        props.small && styles.small,
                        props.containerClassName
                    )}
                >
                    <header
                        className={clsx(styles.header, props.headerClassName)}
                    >
                        {props.header ?? (
                            <>
                                {props.image && (
                                    <img
                                        alt={props.title}
                                        className={styles.image}
                                        src={props.image}
                                    />
                                )}
                                {props.title && (
                                    <Text
                                        className={styles.title}
                                        element="h4"
                                        noMargin
                                    >
                                        {props.title}
                                    </Text>
                                )}
                            </>
                        )}
                        <Button
                            className={styles.closeButton}
                            darkBackground={props.darkBackground}
                            iconName="x"
                            iconSize={2}
                            onClick={props.onClose}
                            theme={ButtonTheme.Transparent}
                        />
                    </header>
                    <div className={clsx(styles.body, props.className)}>
                        {props.children}
                    </div>
                </div>
            </div>
        </dialog>,
        document.body
    )
}

export default Dialog
