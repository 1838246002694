'use client'

import clsx from 'clsx'
import dayjs from 'dayjs'
import flatpickr from 'flatpickr'
import { useField, useFormikContext } from 'formik'
import {
    ChangeEvent,
    FC,
    JSX,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { flatpickrLocales } from '../../../../lib/constants'
import { useTranslation } from '../../../../lib/hooks'
import { Button, ButtonTheme } from '../../button'
import InputError from '../inputError'
import InputHelp from '../inputHelp'
import InputLabel from '../inputLabel'
import styles from './input.module.css'

export enum DatePickerType {
    Date,
    Time,
    DateTime,
}

interface Props
    extends Pick<
        JSX.IntrinsicElements['input'],
        | 'accept'
        | 'className'
        | 'disabled'
        | 'onChange'
        | 'placeholder'
        | 'required'
    > {
    datePickerType?: DatePickerType
    fileName?: string
    help?: ReactNode
    label?: string
    maxDate?: Date
    minDate?: Date
    name: string
    type?:
        | 'date'
        | 'email'
        | 'hidden'
        | 'number'
        | 'password'
        | 'search'
        | 'text'
}

const Input: FC<Props> = props => {
    const { locale } = useTranslation()
    const [field, meta, helpers] = useField({
        name: props.name,
        type: props.type,
    })
    const { isSubmitting, submitForm } = useFormikContext()
    const input = useRef<HTMLInputElement>(null)
    const datePicker = useRef<flatpickr.Instance>()
    const [passwordVisible, setPasswordVisible] = useState(false)

    const disabled = props.disabled || isSubmitting
    const invalid = meta.touched && meta.error

    const formattedValue = useMemo(() => {
        if (field.value instanceof Date) {
            return dayjs(field.value).format('D.M.YYYY')
        }

        return field.value ?? ''
    }, [field.value])

    useEffect(() => {
        if (!input.current || props.type !== 'date') {
            return
        }

        const options: flatpickr.Options.Options = {
            altFormat:
                props.datePickerType === DatePickerType.Time
                    ? 'H:i'
                    : props.datePickerType === DatePickerType.DateTime
                      ? 'd.m.Y H:i'
                      : 'd.m.Y',
            altInput: true,
            dateFormat: 'U',
            defaultDate: field.value,
            enableTime:
                props.datePickerType === DatePickerType.Time ||
                props.datePickerType === DatePickerType.DateTime,
            locale: flatpickrLocales[locale as flatpickr.Options.LocaleKey],
            maxDate: props.maxDate,
            minDate: props.minDate,
            monthSelectorType: 'static',
            noCalendar: props.datePickerType === DatePickerType.Time,
            time_24hr: true,
            disableMobile: true,
        }

        if (datePicker.current) {
            datePicker.current.set(options)
        } else {
            datePicker.current = flatpickr(input.current, options)
        }
    }, [
        field.value,
        locale,
        props.datePickerType,
        props.maxDate,
        props.minDate,
        props.type,
    ])

    useEffect(() => {
        if (!datePicker.current?._input) {
            return
        }

        if (invalid) {
            datePicker.current._input.classList.add(styles.invalid)
        } else {
            datePicker.current._input.classList.remove(styles.invalid)
        }

        datePicker.current._input.disabled = disabled
    }, [disabled, invalid])

    const onDatePickerChange = useCallback(
        (dates: Date[]) => {
            const date = dates.length ? dates[0] : undefined

            if (date !== field.value) {
                helpers.setValue(dates[0]).catch(console.error)
                helpers.setTouched(true, false).catch(console.error)
            }
        },
        [field.value, helpers]
    )

    useEffect(() => {
        datePicker.current?.set('onChange', onDatePickerChange)
    }, [onDatePickerChange])

    const togglePassword = useCallback(
        () => setPasswordVisible(prev => !prev),
        []
    )

    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (props.onChange) {
                props.onChange.call(undefined, e)
                return
            }

            let value: string | number | undefined = e.currentTarget.value

            if (props.type === 'number') {
                value = value ? Number(value) : undefined
            }

            helpers.setValue(value).catch(console.error)
        },
        [helpers, props.onChange, props.type]
    )

    return (
        <div className={clsx(styles.root, props.className)}>
            <label className={styles.label}>
                {props.label && (
                    <InputLabel>
                        {props.label}
                        {props.required && <span> *</span>}
                    </InputLabel>
                )}
                <div className={styles.wrapper}>
                    <input
                        {...field}
                        ref={input}
                        className={clsx(
                            styles.input,
                            invalid && styles.invalid
                        )}
                        disabled={disabled}
                        onChange={onChange}
                        placeholder={props.placeholder}
                        required={props.required}
                        type={
                            input.current?.type === 'hidden'
                                ? 'hidden'
                                : passwordVisible || props.type === 'date'
                                  ? 'text'
                                  : props.type
                        }
                        value={formattedValue}
                    />
                    {props.type === 'date' && (
                        <Button
                            className={clsx(
                                styles.button,
                                invalid && styles.invalid
                            )}
                            disabled={disabled}
                            iconName={
                                props.datePickerType === DatePickerType.Time
                                    ? 'clock'
                                    : 'calendar-blank'
                            }
                            iconSize={2}
                            onClick={datePicker.current?.toggle}
                            theme={ButtonTheme.Transparent}
                        />
                    )}
                    {props.type === 'password' && (
                        <Button
                            className={clsx(
                                styles.button,
                                invalid && styles.invalid
                            )}
                            disabled={disabled}
                            iconName={passwordVisible ? 'eye' : 'eye-slash'}
                            iconSize={2}
                            onClick={togglePassword}
                            theme={ButtonTheme.Transparent}
                        />
                    )}
                    {props.type === 'search' && (
                        <Button
                            className={clsx(
                                styles.button,
                                invalid && styles.invalid
                            )}
                            disabled={disabled}
                            iconName="magnifying-glass"
                            iconSize={2}
                            onClick={submitForm}
                            theme={ButtonTheme.Transparent}
                        />
                    )}
                </div>
            </label>
            {props.help && <InputHelp>{props.help}</InputHelp>}
            <InputError name={field.name} />
        </div>
    )
}

export default Input
