'use client'

import clsx from 'clsx'
import { FC, ReactNode } from 'react'
import styles from './informationItem.module.css'

interface Props {
    children: ReactNode
    className?: string
    media: ReactNode
}

const InformationItem: FC<Props> = props => (
    <div className={clsx(styles.root, props.className)}>
        <div className={styles.media}>{props.media}</div>
        <div>{props.children}</div>
    </div>
)

export default InformationItem
