'use client'

import clsx from 'clsx'
import { FC, useCallback } from 'react'
import styles from './progressBar.module.css'

interface Props {
    active?: boolean
    completed?: boolean
    label: string
    onClick: (step: number) => void
    step: number
}

const ProgressBarStep: FC<Props> = props => {
    const onClick = useCallback(() => {
        if (props.completed) {
            props.onClick.call(undefined, props.step)
        }
    }, [props.completed, props.onClick, props.step])

    return (
        <div
            className={clsx(
                styles.step,
                props.active && styles.active,
                props.completed && styles.completed
            )}
        >
            <div className={clsx(styles.line, styles.before)} />
            <button className={styles.button} onClick={onClick}>
                {props.step}
            </button>
            <div className={clsx(styles.line, styles.after)} />
            <div className={styles.label} onClick={onClick}>
                {props.label}
            </div>
        </div>
    )
}

export default ProgressBarStep
