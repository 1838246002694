/* istanbul ignore file */
import { FC } from 'react'
import { Card, Table } from '../base'

const TableExample: FC = () => (
    <Card title="Table">
        <Table>
            <thead>
                <tr>
                    <th>Column 1</th>
                    <th>Column 2</th>
                    <th>Column 3</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Lorem ipsum ...</td>
                    <td>Lorem ipsum ...</td>
                    <td>Lorem ipsum ...</td>
                </tr>
            </tbody>
        </Table>
    </Card>
)

export default TableExample
