'use client'

import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { BookingOperationStatus } from '../../../lib/graphql/generated/types'
import { useTranslation } from '../../../lib/hooks'
import { BookingsQueryEvent } from '../../../lib/types'
import { Badge, Button } from '../../base'
import styles from './bookingsButton.module.css'

interface Props {
    bookings?: NonNullable<BookingsQueryEvent['viewerParticipant']>['bookings']
    openBookings: () => void
}

const BookingsButton: FC<Props> = props => {
    const { t } = useTranslation()

    const invited = useMemo(
        () =>
            props.bookings?.some(booking =>
                booking.groupedBookingOperations.some(
                    operation =>
                        operation.bookingOperationStatus ===
                        BookingOperationStatus.Invited
                )
            ),
        [props.bookings]
    )

    if (!props.bookings?.length) {
        return
    }

    return (
        <div className={styles.root}>
            <Button onClick={props.openBookings}>{t('event:bookings')}</Button>
            <Badge className={clsx(styles.badge, invited && styles.invited)}>
                {invited ? t('common:invitation') : props.bookings.length}
            </Badge>
        </div>
    )
}

export default BookingsButton
