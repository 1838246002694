/* istanbul ignore file */
'use client'

import { Form, Formik } from 'formik'
import { FC } from 'react'
import { useYup } from '../../lib/hooks'
import { Button, ButtonGroup, Card, Checkbox, InputGroup } from '../client'
import styles from './examples.module.css'
import useOnSubmit from './useOnSubmit'

const CheckboxExample: FC = () => {
    const yup = useYup()
    const { onSubmit } = useOnSubmit()

    return (
        <Card title="Checkbox">
            <Formik
                initialValues={{
                    standaloneCheckbox: false,
                    multipleOptions: [],
                }}
                onSubmit={onSubmit}
                validationSchema={yup.object().shape({
                    standaloneCheckbox: yup.boolean().oneOf([true]),
                    multipleOptions: yup.array().min(1),
                })}
            >
                {formik => (
                    <Form className={styles.flexCol} noValidate>
                        <Checkbox
                            help="Help text"
                            name="standaloneCheckbox"
                            required
                        >
                            Standalone checkbox
                        </Checkbox>
                        <InputGroup
                            label="Multiple options"
                            name="multipleOptions"
                            required
                        >
                            <Checkbox
                                help="Help text"
                                name="multipleOptions"
                                value="Option 1"
                            >
                                Option 1
                            </Checkbox>
                            <Checkbox
                                help="Help text"
                                name="multipleOptions"
                                value="Option 2"
                            >
                                Option 2
                            </Checkbox>
                            <Checkbox
                                help="Help text"
                                name="multipleOptions"
                                value="Option 3"
                                disabled
                            >
                                Option 3
                            </Checkbox>
                        </InputGroup>
                        <ButtonGroup>
                            <Button loading={formik.isSubmitting} type="submit">
                                Submit
                            </Button>
                        </ButtonGroup>
                    </Form>
                )}
            </Formik>
        </Card>
    )
}

export default CheckboxExample
