'use client'

import dayjs from 'dayjs'
import { Form, Formik } from 'formik'
import { useCookies } from 'next-client-cookies'
import { FC, ReactNode, useCallback, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { tokenKey } from '../../lib/constants'
import { useEventTeasersQuery } from '../../lib/graphql/generated/hooks'
import { CategoriesAndEventTypesQuery } from '../../lib/graphql/generated/types'
import { useRouter, useTranslation } from '../../lib/hooks'
import useEventDatesSearch from '../../lib/hooks/useEventDatesSearch'
import { Container, DateFormat, Input } from '../base'
import styles from './search.module.css'
import SearchFilters from './searchFilters'
import SearchResults from './searchResults'

interface Values {
    q?: string
}

export interface SearchState {
    categories?: string[]
    eventTypes?: string[]
    from?: Date
    hasActiveFilters: boolean
    page?: number
    promoted?: boolean
    q?: string
    to?: Date
}

interface Props {
    categories?: NonNullable<
        CategoriesAndEventTypesQuery['viewer']
    >['categories']
    eventTypes?: NonNullable<
        CategoriesAndEventTypesQuery['viewer']
    >['eventTypes']
    footer?: ReactNode
    header?: ReactNode
    itemsPerPage?: number
}

const Search: FC<Props> = props => {
    const { t, locale } = useTranslation()
    const router = useRouter()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)
    const itemsPerPage = props.itemsPerPage ?? 8

    const state: SearchState = useMemo(() => {
        const categoryNames = router.searchParams.getAll('category') ?? []

        const categoryIds = props.categories
            ?.filter(category => categoryNames.includes(category.name))
            .map(category => category.id)

        const eventTypeNames = router.searchParams.getAll('type') ?? []

        const eventTypeIds = props.eventTypes
            ?.filter(eventType => eventTypeNames.includes(eventType.name))
            .map(eventType => eventType.id)

        const filters = {
            q: router.searchParams.get('q') ?? undefined,
            from: router.searchParams.has('from')
                ? dayjs(
                      router.searchParams.get('from'),
                      DateFormat.Short
                  ).toDate()
                : undefined,
            to: router.searchParams.has('to')
                ? dayjs(
                      router.searchParams.get('to'),
                      DateFormat.Short
                  ).toDate()
                : undefined,
            categories: categoryIds?.length ? categoryIds : undefined,
            eventTypes: eventTypeIds?.length ? eventTypeIds : undefined,
            promoted: router.searchParams.get('promoted') === 'true',
        }

        return {
            ...filters,
            page: router.searchParams.has('page')
                ? Number(router.searchParams.get('page'))
                : undefined,
            hasActiveFilters: Object.values(filters).some(
                value => typeof value !== 'undefined' && value !== false
            ),
        }
    }, [props.categories, props.eventTypes, router.searchParams])

    const [eventTeasersQuery] = useEventTeasersQuery({
        variables: {
            token,
            locale,
            length: itemsPerPage,
            offset: state.page && (state.page - 1) * itemsPerPage,
            search: state.q,
            filterFromDate: state.from
                ? dayjs(state.from).startOf('day').unix()
                : dayjs().startOf('day').unix(),
            filterToDate: state.to && dayjs(state.to).endOf('day').unix(),
            filterCategoryIds: state.categories,
            filterEventTypeIds: state.eventTypes,
            filterAppLibraryOnly: true,
            filterPromotedOnly: state.promoted,
        },
    })

    const highlightedDates = useEventDatesSearch({
        search: state.q,
        categoryIds: state.categories,
        eventTypeIds: state.eventTypes,
    })

    const onSubmit = useCallback(
        async (values: Values) => {
            const params = new URLSearchParams(router.searchParams)
            params.delete('page')

            if (values.q) {
                params.set('q', values.q)
            } else {
                params.delete('q')
            }

            router.pushSearchParams(params)
        },
        [router]
    )

    const controls = (
        <Container className={styles.container}>
            <Formik<Values> initialValues={{ q: state.q }} onSubmit={onSubmit}>
                <Form noValidate>
                    <Input
                        name="q"
                        placeholder={t('common:searchPlaceholder')}
                        type="search"
                    />
                </Form>
            </Formik>
            <div>
                {props.categories && props.eventTypes ? (
                    <SearchFilters
                        categories={props.categories}
                        eventTypes={props.eventTypes}
                        highlightedDates={highlightedDates}
                    />
                ) : (
                    <div className={styles.searchFilters}>
                        <Skeleton borderRadius={19} height={34.67} width={95} />
                        <Skeleton
                            borderRadius={19}
                            height={34.67}
                            width={135}
                        />
                        <Skeleton borderRadius={19} height={34.67} width={95} />
                        <Skeleton borderRadius={19} height={34.67} width={50} />
                        <Skeleton borderRadius={19} height={34.67} width={80} />
                    </div>
                )}
            </div>
        </Container>
    )

    const results = state.hasActiveFilters && (
        <SearchResults
            items={eventTeasersQuery.data?.viewer?.eventTeasers?.items}
            itemsPerPage={itemsPerPage}
            page={state.page}
            totalItems={
                eventTeasersQuery.data?.viewer?.eventTeasers?.totalCount ?? 0
            }
        />
    )

    return (
        <>
            {!results ? props.header : null}
            {controls}
            {results || props.footer}
        </>
    )
}

export default Search
