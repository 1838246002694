'use client'

import clsx from 'clsx'
import { forwardRef, ReactNode } from 'react'
import { Container, Text } from '../../../base'
import styles from './pageSection.module.css'

interface Props {
    children?: ReactNode
    contentClassName?: string
    id?: string
    overlay?: ReactNode
    overlayClassName?: string
    title: string
}

const PageSection = forwardRef<HTMLDivElement, Props>((props, ref) => (
    <section ref={ref}>
        <header className={styles.header}>
            <Container className={styles.container}>
                {props.id && <div className={styles.anchor} id={props.id} />}
                <Text align="center" element="h2" weight="600" noMargin noWrap>
                    {props.title}
                </Text>
            </Container>
        </header>
        <Container className={clsx(styles.content, props.contentClassName)}>
            {props.children}
            {props.overlay && (
                <div className={clsx(styles.overlay, props.overlayClassName)}>
                    {props.overlay}
                </div>
            )}
        </Container>
    </section>
))

export default PageSection
