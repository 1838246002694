'use client'

import clsx from 'clsx'
import {
    ChangeEvent,
    FC,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
} from 'react'
import { Button, ButtonTheme } from '../../button'
import inputStyles from '../input/input.module.css'
import InputHelp from '../inputHelp'
import InputLabel from '../inputLabel'
import styles from './numericStepper.module.css'

interface Props {
    className?: string
    disabled?: boolean
    help?: ReactNode
    label?: string
    max?: number
    min?: number
    onChange: (value: number) => void
    value: number
}

const NumericStepper: FC<Props> = props => {
    const min = useMemo(
        () =>
            typeof props.min !== 'undefined'
                ? Math.max(Number(props.min), 0)
                : 0,
        [props.min]
    )

    const max = useMemo(
        () =>
            typeof props.max !== 'undefined'
                ? Math.min(Number(props.max), 999)
                : 999,
        [props.max]
    )

    const sanitize = useCallback(
        (value: number) => {
            let num = value

            if (!Number.isInteger(num)) {
                num = Math.round(num)
            }

            if (num < min) {
                num = min
            } else if (num > max) {
                num = max
            }

            return num
        },
        [max, min]
    )

    const value = useMemo(() => sanitize(props.value), [props.value, sanitize])

    useEffect(() => {
        if (value !== props.value) {
            props.onChange.call(undefined, value)
        }
    }, [props.onChange, props.value, value])

    const decrement = useCallback(() => {
        props.onChange.call(undefined, sanitize(value - 1))
    }, [props.onChange, sanitize, value])

    const increment = useCallback(() => {
        props.onChange.call(undefined, sanitize(value + 1))
    }, [props.onChange, sanitize, value])

    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const num = Number(e.currentTarget.value)

            props.onChange.call(
                undefined,
                Number.isNaN(num) ? min : sanitize(num)
            )
        },
        [min, props.onChange, sanitize]
    )

    return (
        <div className={clsx(inputStyles.root, props.className)}>
            <label className={inputStyles.label}>
                {props.label && <InputLabel>{props.label}</InputLabel>}
                <div className={styles.wrapper}>
                    <Button
                        className={clsx(styles.button, styles.left)}
                        disabled={props.disabled || value <= min}
                        iconName="minus"
                        iconSize={3}
                        onClick={decrement}
                        theme={ButtonTheme.Transparent}
                    />
                    <input
                        className={clsx(inputStyles.input, styles.input)}
                        disabled={props.disabled}
                        max={max}
                        maxLength={3}
                        min={min}
                        onChange={onChange}
                        type="number"
                        value={value}
                    />
                    <Button
                        className={clsx(styles.button, styles.right)}
                        disabled={props.disabled || value >= max}
                        iconName="plus"
                        iconSize={3}
                        onClick={increment}
                        theme={ButtonTheme.Transparent}
                    />
                </div>
            </label>
            {props.help && <InputHelp>{props.help}</InputHelp>}
        </div>
    )
}

export default NumericStepper
