/* istanbul ignore file */
'use client'

import '@phosphor-icons/web/regular'
import 'flatpickr/dist/flatpickr.min.css'
import 'react-loading-skeleton/dist/skeleton.css'
import '../../lib/css/main.css'
import '../../lib/dayjsPlugins'
import dayjs from 'dayjs'
import { FC, ReactNode } from 'react'
import {
    cacheExchange,
    Client,
    fetchExchange,
    Provider as GraphqlClientProvider,
} from 'urql'
import fetchOptionsExchange from '../../lib/graphql/fetchOptionsExchange'
import { AuthenticationMethod } from '../../lib/graphql/generated/types'
import { AppProvider } from '../../lib/providers'
import Notifications from './notifications'

const graphqlClient = new Client({
    url: `${process.env.NEXT_PUBLIC_GRAPHQL_ORIGIN ?? ''}/api/graphql`,
    fetchOptions: {
        credentials: 'omit',
    },
    exchanges: [cacheExchange, fetchOptionsExchange, fetchExchange],
    requestPolicy: 'network-only',
})

interface Props {
    authenticationMethods?: AuthenticationMethod[]
    children: ReactNode
    eventCreation?: boolean
    locale: string
    userRegistration?: boolean
}

const App: FC<Props> = props => {
    dayjs.locale(
        ['de', 'fr', 'it'].includes(props.locale) ? `${props.locale}-ch` : 'en'
    )

    return (
        <>
            <GraphqlClientProvider value={graphqlClient}>
                <AppProvider
                    authenticationMethods={props.authenticationMethods}
                    eventCreation={props.eventCreation}
                    userRegistration={props.userRegistration}
                >
                    <Notifications>{props.children}</Notifications>
                </AppProvider>
            </GraphqlClientProvider>
        </>
    )
}

export default App
