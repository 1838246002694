/* istanbul ignore file */
'use client'

import {
    NavigateOptions,
    PrefetchOptions,
} from 'next/dist/shared/lib/app-router-context.shared-runtime'
import {
    useParams,
    usePathname,
    useRouter as useNextRouter,
    useSearchParams,
} from 'next/navigation'
import { useCallback, useMemo } from 'react'
import { extractLocale, withLocale } from '../../utils'

interface RouterOptions extends NavigateOptions {
    locale?: string
    searchParams?: URLSearchParams
}

export interface Router extends ReturnType<typeof useNextRouter> {
    params: Record<string, string>
    pathname: string
    prefetch: (href: string, options?: RouterOptions & PrefetchOptions) => void
    push: (href: string, options?: RouterOptions & NavigateOptions) => void
    pushSearchParams: (params?: URLSearchParams) => void
    reload: () => void
    replace: (href: string, options?: RouterOptions & NavigateOptions) => void
    replaceSearchParams: (params?: URLSearchParams) => void
    searchParams: NonNullable<ReturnType<typeof useSearchParams>>
}

/**
 * Drop-in replacement for the Next.js useRouter hook that provides i18n
 * routing and various helpers.
 */
const useRouter = (): Router => {
    const router = useNextRouter()
    const pathname = usePathname()
    const params = useParams() as Router['params']
    const searchParams = useSearchParams()

    const prefetch: Router['prefetch'] = useCallback(
        (href, options) =>
            router.prefetch(
                withLocale(href, options?.locale ?? params.locale),
                options
            ),
        [params.locale, router]
    )

    const push: Router['push'] = useCallback(
        (href, options) =>
            router.push(
                withLocale(
                    options?.searchParams
                        ? `${href}?${options.searchParams.toString()}`
                        : href,
                    options?.locale ?? params.locale
                ),
                options
            ),
        [params.locale, router]
    )

    const replace: Router['replace'] = useCallback(
        (href, options) =>
            router.replace(
                withLocale(
                    options?.searchParams
                        ? `${href}?${options.searchParams.toString()}`
                        : href,
                    options?.locale ?? params.locale
                ),
                options
            ),
        [params.locale, router]
    )

    const pushSearchParams: Router['pushSearchParams'] = useCallback(
        _params => {
            window.history.pushState(
                null,
                '',
                _params ? `${pathname}?${_params.toString()}` : pathname
            )
        },
        [pathname]
    )

    const replaceSearchParams: Router['pushSearchParams'] = useCallback(
        _params => {
            window.history.replaceState(
                null,
                '',
                _params ? `${pathname}?${_params.toString()}` : pathname
            )
        },
        [pathname]
    )

    const reload: Router['reload'] = useCallback(() => {
        window.location.reload()
    }, [])

    return useMemo(
        () => ({
            back: router.back,
            forward: router.forward,
            params,
            pathname: extractLocale(pathname).pathname,
            prefetch,
            push,
            pushSearchParams,
            refresh: router.refresh,
            reload,
            replace,
            replaceSearchParams,
            searchParams,
        }),
        [
            params,
            pathname,
            prefetch,
            push,
            pushSearchParams,
            reload,
            replace,
            replaceSearchParams,
            router.back,
            router.forward,
            router.refresh,
            searchParams,
        ]
    )
}

export default useRouter
