/* istanbul ignore file */
'use client'

import { FC, useCallback, useState } from 'react'
import { Badge, Card, Icon, TagButton } from '../client'
import styles from './examples.module.css'

const TagButtonExample: FC = () => {
    const [selectedTags, setSelectedTags] = useState<Record<string, boolean>>({
        textOnly: false,
        leftNode: false,
        rightNode: false,
        leftAndRightNode: false,
    })

    const onClick = useCallback((data: any) => {
        setSelectedTags(prevSelectedTags => ({
            ...prevSelectedTags,
            [data]: !prevSelectedTags[data],
        }))
    }, [])

    return (
        <Card contentClassName={styles.flexWrap} title="TagButton">
            <TagButton
                data="textOnly"
                onClick={onClick}
                selected={selectedTags.textOnly}
            >
                Text only
            </TagButton>
            <TagButton
                data="leftNode"
                leftNode={<Icon name="cat" size={1} />}
                onClick={onClick}
                selected={selectedTags.leftNode}
            >
                With left node
            </TagButton>
            <TagButton
                data="rightNode"
                onClick={onClick}
                rightNode={<Badge small>12345</Badge>}
                selected={selectedTags.rightNode}
            >
                With right node
            </TagButton>
            <TagButton
                data="leftAndRightNode"
                leftNode={<Icon name="cat" size={1} />}
                onClick={onClick}
                rightNode={<Badge small>12345</Badge>}
                selected={selectedTags.leftAndRightNode}
            >
                With left and right node
            </TagButton>
        </Card>
    )
}

export default TagButtonExample
