import clsx from 'clsx'
import { FC } from 'react'
import styles from './loadingSpinner.module.css'

interface Props {
    centered?: boolean
    className?: string
    inherit?: boolean
    large?: boolean
}

const LoadingSpinner: FC<Props> = props => (
    <div
        className={clsx(
            styles.root,
            props.centered && styles.centered,
            props.large && styles.large,
            props.className
        )}
    >
        <svg
            className={styles.svg}
            height="100%"
            viewBox="0 0 50 50"
            width="100%"
        >
            <circle
                className={clsx(styles.circle, props.inherit && styles.inherit)}
                cx="25"
                cy="25"
                fill="none"
                r="20"
                strokeLinecap="round"
            />
        </svg>
    </div>
)

export default LoadingSpinner
