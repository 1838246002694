'use client'

import clsx from 'clsx'
import { FC, Fragment } from 'react'
import { BookingFragment } from '../../../../lib/graphql/generated/types'
import { useTranslation } from '../../../../lib/hooks'
import { FormattedCurrency, Price, Table } from '../../../base'
import styles from './bookingSummaryPricingTable.module.css'

interface Props {
    booking: BookingFragment
}

const BookingSummaryPricingTable: FC<Props> = props => {
    const { t } = useTranslation()

    if (!props.booking.payment) {
        return
    }

    const {
        payment,
        groupedBookingOperations,
        includingVat,
        discount,
        discountVatPrice,
        registrationPrice,
        registrationVatPrice,
    } = props.booking

    const {
        vatRate,
        commissionRate,
        commissionPrice,
        commissionVatPrice,
        totalVatPrice,
        totalPriceInclVatRounded,
    } = payment

    const currency = payment.currency.name

    return (
        <Table className={clsx('table', styles.root)}>
            <thead>
                <tr>
                    <th>{t('common:pricingTable.selection')}</th>
                    <th className={styles.rightTopAligned}>
                        {t('common:pricingTable.count')}
                    </th>
                    <th className={styles.rightTopAligned}>
                        {t('common:pricingTable.price')}
                    </th>
                </tr>
            </thead>
            <tbody>
                {groupedBookingOperations
                    .filter(bo => bo.totalPrice > 0)
                    .map(bo => {
                        const discounted = !!bo.discount

                        return (
                            <Fragment key={bo.operation.id}>
                                <tr
                                    className={clsx(
                                        discounted && styles.mergeTop
                                    )}
                                >
                                    <td className={'no-truncate'}>
                                        {bo.operation.title}
                                        {includingVat && (
                                            <div className={styles.smallText}>
                                                <Price
                                                    amount={bo.totalVatPrice}
                                                    currency={currency}
                                                    vatRate={bo.vatRate}
                                                    vatIncluded
                                                />
                                            </div>
                                        )}
                                    </td>
                                    <td className={styles.rightTopAligned}>
                                        {bo.count}
                                    </td>
                                    <td className={styles.rightTopAligned}>
                                        <FormattedCurrency
                                            amount={bo.operationPrice}
                                            currency={currency}
                                        />
                                    </td>
                                </tr>
                                {discounted && (
                                    <tr
                                        className={clsx(
                                            discounted && styles.mergeBottom
                                        )}
                                    >
                                        <td>
                                            {t('common:pricingTable.discount')}{' '}
                                            {!!bo.discountPercentage &&
                                                `${bo.discountPercentage}%`}
                                            {includingVat && (
                                                <div
                                                    className={styles.smallText}
                                                >
                                                    <Price
                                                        amount={
                                                            bo.discountVatPrice
                                                        }
                                                        currency={currency}
                                                        vatRate={bo.vatRate}
                                                        vatIncluded
                                                    />
                                                </div>
                                            )}
                                        </td>
                                        <td />
                                        <td className={styles.rightTopAligned}>
                                            -{' '}
                                            <FormattedCurrency
                                                amount={bo.discount}
                                                currency={currency}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </Fragment>
                        )
                    })}
                {!!registrationPrice && (
                    <tr>
                        <td>
                            {t('common:pricingTable.fee')}
                            {includingVat && (
                                <div className={styles.smallText}>
                                    <Price
                                        amount={registrationVatPrice}
                                        currency={currency}
                                        vatRate={vatRate}
                                        vatIncluded
                                    />
                                </div>
                            )}
                        </td>
                        <td />
                        <td className={styles.rightTopAligned}>
                            <FormattedCurrency
                                amount={registrationPrice}
                                currency={currency}
                            />
                        </td>
                    </tr>
                )}
                {!!discount && (
                    <tr>
                        <td>
                            {t('common:pricingTable.discount')}
                            {includingVat && (
                                <div className={styles.smallText}>
                                    <Price
                                        amount={discountVatPrice}
                                        currency={currency}
                                        vatRate={vatRate}
                                        vatIncluded
                                    />
                                </div>
                            )}
                        </td>
                        <td />
                        <td className={styles.rightTopAligned}>
                            -{' '}
                            <FormattedCurrency
                                amount={discount}
                                currency={currency}
                            />
                        </td>
                    </tr>
                )}
                {!!commissionRate && (
                    <tr>
                        <td>
                            {t('common:pricingTable.commission')}{' '}
                            {commissionRate}%
                            {includingVat && (
                                <div className={styles.smallText}>
                                    <Price
                                        amount={commissionVatPrice}
                                        currency={currency}
                                        vatRate={vatRate}
                                        vatIncluded
                                    />
                                </div>
                            )}
                        </td>
                        <td />
                        <td className={styles.rightTopAligned}>
                            <FormattedCurrency
                                amount={commissionPrice}
                                currency={currency}
                            />
                        </td>
                    </tr>
                )}
                <tr>
                    <td>
                        <strong>Total</strong>
                        <div className={styles.smallText}>
                            {t(
                                `common:priceTotal.${
                                    includingVat ? 'with' : 'without'
                                }Vat`
                            )}
                        </div>
                    </td>
                    <td />
                    <td className={styles.rightAligned}>
                        <strong>
                            <FormattedCurrency
                                amount={totalPriceInclVatRounded}
                                currency={currency}
                            />
                        </strong>
                        <div className={styles.smallText}>
                            <FormattedCurrency
                                amount={totalVatPrice}
                                currency={currency}
                            />
                        </div>
                    </td>
                </tr>
            </tbody>
        </Table>
    )
}

export default BookingSummaryPricingTable
