'use client'

import { FC } from 'react'
import { RegisterQueryEvent } from '../../../../../lib/types'
import { Checkbox, Link, Text } from '../../../../base'

interface Props {
    requirements: NonNullable<
        RegisterQueryEvent['viewerRegistration']
    >['participantRequirements']
}

const Requirements: FC<Props> = props => (
    <>
        {props.requirements.map((requirement, i) => {
            const name = i.toString()

            return (
                <Checkbox
                    key={name}
                    help={
                        requirement.file ? (
                            <>
                                {requirement.text && (
                                    <Text element="div" type="small">
                                        {requirement.text}
                                    </Text>
                                )}
                                {requirement.file.caption && (
                                    <Text element="div" type="small">
                                        {requirement.file.caption}
                                    </Text>
                                )}
                                <Link
                                    href={requirement.file.resource}
                                    iconName="file"
                                    target="_blank"
                                >
                                    {requirement.file.title}
                                </Link>
                            </>
                        ) : undefined
                    }
                    name={name}
                    required
                >
                    {requirement.name}
                </Checkbox>
            )
        })}
    </>
)

export default Requirements
