'use client'

import { Form, Formik } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { useCookies } from 'next-client-cookies'
import { FC, useCallback } from 'react'
import { tokenKey } from '../../../../lib/constants'
import { useCreateCommentMutation } from '../../../../lib/graphql/generated/hooks'
import { useNotification, useTranslation, useYup } from '../../../../lib/hooks'
import { Button, Textarea } from '../../../base'
import styles from './commentForm.module.css'

interface Props {
    eventId: string
}

interface Values {
    comment: string
}

const CommentForm: FC<Props> = props => {
    const { t } = useTranslation()
    const [, createComment] = useCreateCommentMutation()
    const yup = useYup()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)
    const notification = useNotification()

    const onSubmit = useCallback(
        async (values: Values, { resetForm }: FormikHelpers<Values>) => {
            if (!token) {
                notification.alert(t('common:error.internal'))
                return
            }

            try {
                const { data, error } = await createComment(
                    {
                        token,
                        eventId: props.eventId,
                        text: values.comment,
                    },
                    { additionalTypenames: ['CommentCollection'] }
                )

                if (error) {
                    throw error
                }

                if (data?.viewer?.event?.createComment?.inputError) {
                    notification.alert(
                        data.viewer.event.createComment.inputError.message
                    )
                }

                resetForm()
            } catch (e) {
                console.error(e)
                notification.alert(t('common:error.internal'))
            }
        },
        [createComment, notification, props.eventId, t, token]
    )

    return (
        <Formik
            initialValues={{ comment: '' }}
            onSubmit={onSubmit}
            validationSchema={yup.object().shape({
                comment: yup.string().required(),
            })}
        >
            {formik => (
                <Form className={styles.form} noValidate>
                    <Textarea
                        name="comment"
                        placeholder={t('event:comments.placeholder')}
                        required
                    />
                    <Button loading={formik.isSubmitting} type="submit">
                        {t('common:send')}
                    </Button>
                </Form>
            )}
        </Formik>
    )
}

export default CommentForm
