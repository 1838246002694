'use client'

import { useCookies } from 'next-client-cookies'
import React, { FC, useCallback, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import Skeleton from 'react-loading-skeleton'
import { tokenKey } from '../../../lib/constants'
import { useSpeakersQuery } from '../../../lib/graphql/generated/hooks'
import { useTranslation } from '../../../lib/hooks'
import { LoadMoreButton, PageSection } from '../../common'
import { Speaker } from './speaker'
import styles from './speakers.module.css'

const batchSize = 8

interface Props {
    eventId: string
}

const Speakers: FC<Props> = (props: Props) => {
    const { t, locale } = useTranslation()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)
    const { ref, inView } = useInView()
    const [length, setLength] = useState(batchSize)

    const [speakersQuery] = useSpeakersQuery({
        variables: {
            token,
            locale,
            eventKeyOrId: props.eventId,
            length,
            offset: 0,
        },
        pause: !inView,
    })

    const total =
        speakersQuery.data?.viewer?.event?.speakerCollection?.totalCount ?? 0
    const speakers = speakersQuery.data?.viewer?.event?.speakerCollection?.items

    const loadMore = useCallback(() => {
        setLength(prev => prev + batchSize)
    }, [])

    return (
        <PageSection
            ref={ref}
            contentClassName={styles.root}
            id="speakers"
            title={t('event:speakers')}
        >
            <div className={styles.speakers}>
                {speakers
                    ? speakers.map(speaker => (
                          <Speaker
                              key={speaker.id}
                              speaker={speaker}
                              width={200}
                          />
                      ))
                    : Array(batchSize)
                          .fill(true)
                          .map((_, i) => (
                              <Skeleton
                                  key={i}
                                  height={200}
                                  width={200}
                                  circle
                              />
                          ))}
            </div>
            {!!speakers?.length && (
                <LoadMoreButton
                    className={styles.loadMoreButton}
                    from={1}
                    loading={speakersQuery.fetching}
                    onClick={loadMore}
                    to={speakers.length}
                    total={total}
                />
            )}
        </PageSection>
    )
}

export default Speakers
