'use client'

import { useCookies } from 'next-client-cookies'
import { FC, useEffect, useState } from 'react'
import { tokenKey } from '../../../../lib/constants'
import { useCheckInParticipantMutation } from '../../../../lib/graphql/generated/hooks'
import { OnlineEventTiming } from '../../../../lib/graphql/generated/types'
import { useRouter } from '../../../../lib/hooks'
import { OnlineEvent } from '../../../../lib/types'
import { VideoPlayer } from '../../../common'
import styles from './onlineEventOverlay.module.css'

interface Props {
    eventId: string
    onlineEvents: OnlineEvent[]
}

const OnlineEventOverlay: FC<Props> = props => {
    const router = useRouter()
    const cookies = useCookies()
    const token = cookies.get(tokenKey)
    const [, checkInParticipant] = useCheckInParticipantMutation()
    const [onlineEvent, setOnlineEvent] = useState<OnlineEvent>()

    useEffect(() => {
        const onlineEventId = router.searchParams.get('onlineEventId')

        if (onlineEventId) {
            setOnlineEvent(
                props.onlineEvents.find(
                    _onlineEvent => _onlineEvent.id === onlineEventId
                )
            )
        } else if (props.onlineEvents.length) {
            setOnlineEvent(
                props.onlineEvents.find(_onlineEvent => !_onlineEvent.external)
            )
        } else {
            setOnlineEvent(undefined)
        }
    }, [props.onlineEvents, router.searchParams])

    useEffect(() => {
        if (!onlineEvent) {
            return
        }

        // Periodically check if 1 hour before live stream to check user in
        const intervalId = setInterval(() => {
            if (
                !token ||
                onlineEvent.startDate.diff(undefined, 'minutes') > 60 ||
                onlineEvent.timing !== OnlineEventTiming.Live
            ) {
                return
            }

            clearInterval(intervalId)

            checkInParticipant({
                token,
                eventId: props.eventId,
                onlineEventSettingId: onlineEvent.id,
            })
                .then(({ data, error }) => {
                    if (error) {
                        throw error
                    }

                    if (
                        data?.viewer?.event?.viewerParticipant
                            ?.checkInByOnlineEventSetting?.inputError
                    ) {
                        throw Error(
                            data.viewer.event.viewerParticipant
                                .checkInByOnlineEventSetting.inputError.message
                        )
                    }
                })
                .catch(console.error)
        }, 60000)

        return () => clearInterval(intervalId)
    }, [checkInParticipant, onlineEvent, props.eventId, token])

    if (!onlineEvent) {
        return
    }

    return (
        <div className={styles.root}>
            <VideoPlayer onlineEvent={onlineEvent} />
        </div>
    )
}

export default OnlineEventOverlay
