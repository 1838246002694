'use client'

import { ChangeEvent, FC, useCallback, useRef } from 'react'
import { Avatar, Button, ButtonTheme } from '../../base'
import styles from './profileAvatar.module.css'

interface Props {
    firstName: string
    image?: string | null
    lastName: string
    onChange?: (image: string | null) => void
}

const ProfileAvatar: FC<Props> = props => {
    const hiddenFileInput = useRef<HTMLInputElement>(null)

    const onEdit = useCallback(() => {
        hiddenFileInput.current?.click()
    }, [])

    const onDelete = useCallback(() => {
        props.onChange?.call(undefined, null)
    }, [props.onChange])

    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const file = e.target?.files?.length && e.target.files[0]

            if (file) {
                const reader = new FileReader()
                reader.readAsDataURL(file)

                reader.onload = () => {
                    /* istanbul ignore next */
                    if (typeof reader.result === 'string') {
                        props.onChange?.call(undefined, reader.result)
                    }
                }
            }
        },
        [props.onChange]
    )

    return (
        <div className={styles.root}>
            <Avatar
                firstName={props.firstName}
                image={props.image}
                lastName={props.lastName}
                size={150}
            />
            {props.onChange && (
                <div className={styles.actions}>
                    <input
                        ref={hiddenFileInput}
                        accept="image/*"
                        className={styles.hiddenFileInput}
                        onChange={onChange}
                        type="file"
                    />
                    <Button
                        iconName="pencil"
                        iconSize={3}
                        onClick={onEdit}
                        theme={ButtonTheme.Transparent}
                    />
                    <Button
                        iconName="x"
                        iconSize={3}
                        onClick={onDelete}
                        theme={ButtonTheme.Transparent}
                    />
                </div>
            )}
        </div>
    )
}

export default ProfileAvatar
