/**
 * Can be used inside any <Formik> to show a generic form error header
 * notification with optional scrolling to the first error.
 */
'use client'

import { useFormikContext } from 'formik'
import { FC, useEffect, useRef } from 'react'
import { useNotification, useTranslation } from '../../../../lib/hooks'

interface Props {
    scrollToError?: boolean
}

const FormErrorNotification: FC<Props> = props => {
    const { t } = useTranslation()
    const notification = useNotification()
    const { errors, isValid, isValidating, isSubmitting, submitCount } =
        useFormikContext()
    const prevSubmitCount = useRef(0)

    useEffect(() => {
        if (
            !isValid &&
            !isValidating &&
            !isSubmitting &&
            submitCount > prevSubmitCount.current
        ) {
            prevSubmitCount.current = submitCount

            notification.alert(t('common:formErrors'))

            if (props.scrollToError) {
                const keys = Object.keys(errors)

                if (keys.length) {
                    const [name] = keys
                    const element = document.querySelector(`[name="${name}"]`)
                    element?.scrollIntoView({ behavior: 'smooth' })
                }
            }
        }
    }, [
        errors,
        isSubmitting,
        isValid,
        isValidating,
        notification,
        props.scrollToError,
        submitCount,
        t,
    ])

    return null
}

export default FormErrorNotification
