import { BookingOperationCreateInput } from '../../../../../../lib/graphql/generated/types'
import { encodeInputFieldValue } from '../../../utils'
import { Values } from '../../selection'

export const valuesToBookingOperations = (
    values: Values
): BookingOperationCreateInput[] =>
    Object.entries(values).reduce(
        (
            acc: BookingOperationCreateInput[],
            [operationId, bookingOperations]
        ) =>
            acc.concat(
                bookingOperations.map(bookingOperation => ({
                    operationId,
                    inputFieldValues:
                        bookingOperation.inputFieldValues &&
                        Object.entries(bookingOperation.inputFieldValues).map(
                            ([identifier, value]) => ({
                                identifier,
                                value: encodeInputFieldValue(value),
                            })
                        ),
                    groupBookingId: bookingOperation.groupBookingId,
                    newGroupBooking: bookingOperation.newGroupBooking,
                }))
            ),
        []
    )

export default valuesToBookingOperations
