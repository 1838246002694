import clsx from 'clsx'
import { FC, ReactNode } from 'react'
import styles from './badge.module.css'

interface Props {
    children: ReactNode
    className?: string
    small?: boolean
}

const Badge: FC<Props> = props => (
    <div
        className={clsx(
            styles.root,
            props.small && styles.small,
            props.className
        )}
    >
        <span>{props.children}</span>
    </div>
)

export default Badge
