import clsx from 'clsx'
import { FC, ReactNode } from 'react'
import styles from './collapsible.module.css'

interface Props {
    children?: ReactNode
    className?: string
    collapsed?: boolean
    containerClassName?: string
    reversed?: boolean
    toggle?: ReactNode
}

const Collapsible: FC<Props> = props => (
    <div
        className={clsx(
            styles.root,
            props.collapsed && styles.collapsed,
            props.reversed && styles.reversed,
            props.containerClassName
        )}
    >
        {props.toggle}
        <div className={styles.wrapper}>
            <div className={clsx(styles.body, props.className)}>
                {props.children}
            </div>
        </div>
    </div>
)

export default Collapsible
