import dayjs from 'dayjs'
import {
    BookingFragment,
    InputFieldValidator,
    InputFragment,
} from '../../../../lib/graphql/generated/types'
import { useYup } from '../../../../lib/hooks'
import { dayjsFrom } from '../../../../lib/utils'

export const decodeValueFromInputField = (inputField: InputFragment) => {
    switch (inputField.__typename) {
        case 'SingleLineTextField':
        case 'MultiLineTextField':
            return inputField.defaultValue
        case 'SingleSelectRadiobuttonsField':
            return (
                inputField.options.find(option => option.selected)?.value ?? ''
            )
        case 'CheckboxField':
            return inputField.defaultCheckedValue
        case 'MultiSelectCheckboxesField':
            return inputField.options
                .filter(option => option.selected)
                .map(option => option.value)
        case 'SingleSelectDropdownField':
            return inputField.options.find(option => option.selected)?.value
        case 'DatePickerField':
            return inputField.defaultDateValue ?? ''
    }
}

export const decodeValueFromInputFieldValue = (
    inputFieldValue: BookingFragment['participant']['inputFieldValues'][number]
) => {
    switch (inputFieldValue.__typename) {
        case 'StringInputFieldValue':
            return inputFieldValue.value
        case 'ListStringInputFieldValue':
            return inputFieldValue.values
        case 'BooleanInputFieldValue':
            return inputFieldValue.selected
        case 'DateInputFieldValue':
            return dayjsFrom(inputFieldValue.date)?.toDate()
        case 'FileInputFieldValue':
            return (
                inputFieldValue.file && {
                    name: inputFieldValue.file.title,
                    url: inputFieldValue.file.resource,
                }
            )
        case 'ImageInputFieldValue':
            return (
                inputFieldValue.image?.thumbnail && {
                    name: inputFieldValue.image.title,
                    url: inputFieldValue.image.thumbnail.resource,
                }
            )
    }
}

export const encodeInputFieldValue = (value: any): string | null => {
    if (typeof value === 'string') {
        return value
    } else if (typeof value === 'boolean') {
        return value ? '1' : '0'
    } else if (value instanceof Date) {
        return dayjs(value).unix().toString()
    } else if (value?.url) {
        return value.url.startsWith('data:')
            ? value.url.split(',')[1]
            : undefined
    }

    return JSON.stringify(value)
}

export const getValidator = (
    yup: ReturnType<typeof useYup>,
    inputField: InputFragment
) => {
    const validator =
        'validator' in inputField ? inputField.validator : undefined
    const required = 'required' in inputField ? inputField.required : false

    switch (validator) {
        case InputFieldValidator.Integer:
            return required
                ? yup.number().integer().required()
                : yup.number().integer().notRequired()
        case InputFieldValidator.Float:
            return required
                ? yup.number().required()
                : yup.number().notRequired()
        case InputFieldValidator.EmailAddress:
            return required
                ? yup.string().email().required()
                : yup.string().email().notRequired()
        default:
            switch (inputField.__typename) {
                case 'SingleLineTextField':
                case 'MultiLineTextField':
                case 'SingleSelectRadiobuttonsField':
                case 'SingleSelectDropdownField':
                case 'DatePickerField':
                    return required
                        ? yup.string().required()
                        : yup.string().notRequired()
                case 'CheckboxField':
                    return required
                        ? yup.boolean().required().oneOf([true])
                        : yup.boolean().notRequired()
                case 'MultiSelectCheckboxesField':
                    return required
                        ? yup.array().required().min(1)
                        : yup.array().notRequired()
                default:
                    return required
                        ? yup.mixed().required()
                        : yup.mixed().notRequired()
            }
    }
}
